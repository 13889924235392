import { useState, useEffect } from "react";

const BASE_URL = "https://jf8k6mp2m8.eu-west-1.awsapprunner.com";

export const useReservationData = (
  page = 1,
  customerId = null,
  reservationId = null,
) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      let apiEndpoint = `${BASE_URL}/reservations?page=${page}`;
      if (customerId) {
        apiEndpoint += `&customerId=${customerId}`;
      }
      if (reservationId) {
        apiEndpoint = `${BASE_URL}/reservations/${reservationId}`;
      }

      const token = localStorage.getItem("token");
      if (!token) {
        setError("No token found");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(apiEndpoint, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          localStorage.removeItem("token");
          window.location.href = "/";
          return;
        }

        if (!response.ok) {
          throw new Error(
            `Network response was not ok: ${response.statusText}`,
          );
        }

        const result = await response.json();

        const count = result.total_count || 0;
        setTotalCount(count);

        let dataArray;

        if (reservationId) {
          dataArray = result[0];
        } else {
          dataArray = result.records;
        }

        let combinedData;

        if (reservationId) {
          combinedData = [
            {
              custID: Number(dataArray.customer_id),
              reservationID:
                Number(dataArray.reservation_id) || dataArray["Reservation ID"],
              PricingModel: dataArray["Pricing Model"],
              ReservedTime: dataArray["Reserved Time"],
              UsageTime: dataArray["Usage Time"],
              starttime: dataArray.start_time,
              Route: dataArray.Route,
              Rating: dataArray.Rating || [],
              Device: dataArray.Device || [],
              Customer: dataArray.Customer || [],
              Vehicle: dataArray.Vehicle || [],
              TripStart: dataArray.TripStart || [],
              TripEnd: dataArray.TripEnd || [],
              endtime: dataArray.end_time,
              distance: dataArray.distance || dataArray.Distance,
              location: dataArray.location,
              isdev: dataArray.is_dev,
              reservationState: dataArray.reservationState,
              plate: dataArray.plate,
            },
          ];
        } else {
          combinedData = dataArray.map((item) => ({
            custID: Number(item.customer_id),
            reservationID:
              Number(item.reservation_id) || item["Reservation ID"],
            PricingModel: item["Pricing Model"],
            UsageTime: item["Usage Time"],
            starttime: item.start_time,
            Route: item.Route,
            Rating: item.Rating || [],
            Device: item.Device || [],
            Customer: item.Customer || [],
            Vehicle: item.Vehicle || [],
            TripStart: item.TripStart || [],
            TripEnd: item.TripEnd || [],
            endtime: item.end_time,
            distance: item.distance || item.Distance,
            location: item.location,
            isdev: item.is_dev,
            reservationState: item.reservationState,
            plate: item.plate,
          }));
        }

        setData(combinedData);
        setTotalPages(result.total_pages ?? 1);
      } catch (error) {
        console.error("Fetch error:", error);
        setError(error.message || "An unknown error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [page, customerId, reservationId]);

  return { data, loading, error, totalPages, totalCount };
};
