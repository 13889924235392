import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useVehicleData } from "./data/vehicledata";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Pagination,
  CircularProgress,
  Typography,
  TextField,
  Snackbar,
  Alert,
  IconButton,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Menu,
  MenuItem,
  ListItemText,
  Button,
  Tabs,
  Tab,
  FormControlLabel,
  Switch,
  LinearProgress,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ViewWeekOutlinedIcon from "@mui/icons-material/ViewWeekOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CloseIcon from "@mui/icons-material/Close";

const getStatusStyles = (state) => {
  switch (state) {
    case "active":
      return { backgroundColor: "#009900", color: "#FFFFFF" };
    case "In Garage":
      return { backgroundColor: "#cc0000", color: "#FFFFFF" };
    case "Cleaning In Progress":
      return { backgroundColor: "#347aed", color: "#FFFFFF" };
    case "Cleaning Required":
      return { backgroundColor: "#ffb700", color: "#000000" };
    case "Garage Inspection":
      return { backgroundColor: "#964B00", color: "#FFFFFF" };
    case "Total Loss":
      return { backgroundColor: "#000000", color: "#FFFFFF" };
    case "Testing":
      return { backgroundColor: "#c27ba0", color: "#000000" };
    case "Inspection":
      return { backgroundColor: "#00ffff", color: "#000000" };
    case "Special Fleet":
      return { backgroundColor: "#ff00ff", color: "#000000" };
    case "Stolen":
      return { backgroundColor: "#a61c00", color: "#FFFFFF" };
    case "Private Parking":
      return { backgroundColor: "#cccccc", color: "#000000" };
    case "On-boarding Process":
      return { backgroundColor: "#d9d9d9", color: "#000000" };
    case "Impounded":
      return { backgroundColor: "#ff4500", color: "#FFFFFF" };
    case "_Defleeted_":
      return { backgroundColor: "#666600", color: "#FFFFFF" };
    case "Automated IoT Issue":
      return { backgroundColor: "#ff00ff", color: "#000000" };
    case "Failed Payment":
      return { backgroundColor: "#ffff00", color: "#000000" };
    case "Service Required":
      return { backgroundColor: "#ff9900", color: "#000000" };
    case "Defleeting Process":
      return { backgroundColor: "#4a86e8", color: "#FFFFFF" };
    case "Floating Reservation":
      return { backgroundColor: "#674ea7", color: "#FFFFFF" };
    case "Lost & Found":
      return { backgroundColor: "#c27ba0", color: "#000000" };
    case "Vehicle on Hold":
      return { backgroundColor: "#1c4587", color: "#FFFFFF" };
    case "Emergency":
      return { backgroundColor: "#ffd966", color: "#000000" };
    case "Court/Police Case":
      return { backgroundColor: "#FF0000", color: "#FFFFFF" };
    case "Cleaning Done(Wet Seats)":
      return { backgroundColor: "#11d218", color: "#000000" };
    case "Deployment":
      return { backgroundColor: "#27f1d2", color: "#000000" };
    case "Battery Voltage Low":
      return { backgroundColor: "#ff9900", color: "#000000" };
    case "In Service":
      return { backgroundColor: "#37976e", color: "#FFFFFF" };
    case "Subscribe NOW":
      return { backgroundColor: "#cc0000", color: "#FFFFFF" };
    case "Vehicle Lockdown":
      return { backgroundColor: "#1c4587", color: "#FFFFFF" };
    case "DEFLEETED":
      return { backgroundColor: "#000000", color: "#FFFFFF" };
    case "Passing":
      return { backgroundColor: "#a61c00", color: "#FFFFFF" };
    default:
      return {};
  }
};

const getStatusStyles1 = (status) => {
  switch (status) {
    case "Reserved":
      return { backgroundColor: "#2980B9", color: "#FFFFFF" };
    case "Open":
      return { backgroundColor: "#F1C40F", color: "#FFFFFF" };
    case "Free":
      return { backgroundColor: "#009900", color: "#FFFFFF" };
    default:
      return {};
  }
};

const getStatusStyles2 = (rental_type) => {
  switch (rental_type) {
    case "PPM":
      return { borderColor: "#0056b3", color: "#000000" };
    case "Daily":
      return { borderColor: "#e63946", color: "#000000" };
    case "Monthly":
      return { borderColor: "#2a9d8f", color: "#000000" };
    default:
      return { borderColor: "#b0b0b0", color: "#000000" };
  }
};

const VehicleTable = ({
  vehicles,
  visibleColumns,
  sortColumn,
  sortDirection,
  handleSort,
}) => (
  <Table>
    <TableHead>
      <TableRow>
        {Object.keys(visibleColumns).map(
          (column) =>
            visibleColumns[column] && (
              <TableCell
                key={column}
                onClick={() => handleSort(column)}
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                {column.replace(/_/g, " ").toUpperCase()}{" "}
                {sortColumn === column
                  ? sortDirection === "asc"
                    ? "↑"
                    : "↓"
                  : ""}
              </TableCell>
            ),
        )}
        <TableCell sx={{ fontWeight: "bold", textTransform: "uppercase" }}>
          Action
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {vehicles.length > 0 ? (
        vehicles.map((vehicle) => (
          <TableRow key={vehicle.vehicle_id}>
            {Object.keys(visibleColumns).map(
              (column) =>
                visibleColumns[column] && (
                  <TableCell key={column}>
                    {column === "state" ? (
                      <Box
                        sx={{
                          display: "inline-block",
                          borderRadius: "4px",
                          padding: "4px",
                          ...getStatusStyles(vehicle.state),
                          textAlign: "center",
                        }}
                      >
                        {vehicle.state}
                      </Box>
                    ) : column === "status" ? (
                      <Box
                        sx={{
                          display: "inline-block",
                          borderRadius: "4px",
                          padding: "4px",
                          ...getStatusStyles1(vehicle.status),
                          textAlign: "center",
                        }}
                      >
                        {vehicle.status}
                      </Box>
                    ) : column === "rental_type" ? (
                      <Box
                        sx={{
                          border: "2px solid",
                          borderRadius: "4px",
                          display: "inline-block",
                          padding: "4px",
                          ...getStatusStyles2(vehicle.rental_type),
                          fontWeight: "bold",
                        }}
                      >
                        {vehicle.rental_type}
                      </Box>
                    ) : (
                      vehicle[column]
                    )}
                  </TableCell>
                ),
            )}
            <TableCell>
              <IconButton
                component={Link}
                to={`/vehicle/${vehicle.vehicle_id}`}
                state={{ vehicleData: vehicle }}
                style={{ color: "blue" }}
              >
                <ArrowForwardIosOutlinedIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={12} align="center">
            <Typography variant="body2">No data available</Typography>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  </Table>
);

const VehiclePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const initialPage = parseInt(searchParams.get("page")) || 1;

  const [page, setPage] = useState(initialPage);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortColumn, setSortColumn] = useState("vehicle_id");
  const [anchorEl, setAnchorEl] = useState(null);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [noResults, setNoResults] = useState(false);

  const [tabIndex, setTabIndex] = useState(0);
  const totalTabs = 7;

  const [visibleColumns, setVisibleColumns] = useState({
    vehicle_id: true,
    licence_plate: true,
    title: true,
    hardware_id: true,
    mileage: true,
    fuel_level: true,
    state: true,
    location: true,
    rental_type: true,
    time_last_moved: true,
    status: true,
  });

  const [hardwareId, setHardwareId] = useState("");
  const [vehicleId, setVehicleId] = useState("");
  const [licencePlate, setLicencePlate] = useState("");
  const [title, setTitle] = useState("");
  const [locationId, setLocationId] = useState("");
  const [rentalType, setRentalType] = useState("");
  const [vehicleState, setVehicleState] = useState("");
  const [vehicleStatus, setVehicleStatus] = useState("");

  const handlePageChange = (event, value) => {
    setPage(value);
    // Update the URL with the new page number
    navigate(`?page=${value}`);
  };

  // Fetching data
  const {
    data: vehicles,
    loading,
    error,
    totalPages,
    totalCount,
  } = useVehicleData(page, debouncedSearchQuery);

  // Sort data
  const sortedVehicles = [...(vehicles || [])].sort((a, b) => {
    if (sortDirection === "asc") {
      return a[sortColumn] < b[sortColumn] ? -1 : 1;
    } else {
      return a[sortColumn] > b[sortColumn] ? -1 : 1;
    }
  });

  useEffect(() => {
    if (vehicles && vehicles.length > 0) {
      setNoResults(false);
    } else if (!loading && debouncedSearchQuery) {
      setNoResults(true);
    }
  }, [vehicles, loading, debouncedSearchQuery]);

  useEffect(() => {
    if (debouncedSearchQuery) {
      setNoResults(false);
    }
  }, [debouncedSearchQuery]);

  useEffect(() => {
    if (triggerSearch) {
      setTriggerSearch(false);
    }
  }, [triggerSearch]);

  const handleSort = (column) => {
    const isAsc = sortColumn === column && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortColumn(column);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleColumnToggle = (column) => {
    setVisibleColumns((prev) => ({ ...prev, [column]: !prev[column] }));
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleNext = () => {
    if (tabIndex < totalTabs - 1) {
      setTabIndex(tabIndex + 1);
    }
  };

  const handleBack = () => {
    if (tabIndex > 0) {
      setTabIndex(tabIndex - 1);
    }
  };
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const [newVehicleFormOpen, setNewVehicleFormOpen] = useState(false);
  const [vehicleData, setVehicleData] = useState({
    plate_code: "",
    licence_plate: "",
    location_id: "",
    hardware_id: "",
    vin: "",
    brand: "",
    model: "",
    business_type: "",
    ppm_max_duration: "",
    car_logo: "",
    registration_city: "",
    registration_year: "",
    body_type: "",
    color: "",
    ownership: "",
    leased_by: "",
    insured_by: "",
    mortgaged_by: "",
    parking_zones_info: "",
    feature_seats: "",
    feature_carplay: false,
    feature_cruise_control: false,
    feature_parking_sensor: false,
    feature_fuel_consumption: "",
    intrip_parking_coverage: false,
    start_later: false,
  });

  const handleNewVehicleForm = () => {
    if (newVehicleFormOpen) {
      // Reset form only when closing
      setVehicleData({
        plate_code: "",
        licence_plate: "",
        location_id: "",
        hardware_id: "",
        mileage: "",
        vin: "",
        business_type: "",
        car_logo: "",
        registration_city: "",
        registration_year: "",
        brand: "",
        model: "",
        body_type: "",
        color: "",
        ownership: "",
        leased_by: "",
        insured_by: "",
        mortgaged_by: "",
        leasing_cost: "",
        car_icon: "",
        model_description: "",
        car_reserved_icon: "",
        parking_zones_info: "",
        feature_seats: "",
        feature_automatic: "",
        feature_carplay: "",
        feature_cruise_control: "",
        feature_parking_sensor: "",
        feature_fuel_consumption: "",
        plate_source_id: "",
        plate_color_id: "",
        state_change_id: "",
        start_later: "",
        intrip_parking_coverage: "",
        ppm_max_duration: "",
      });

      setTabIndex(0); // Resets to the first tab
    }

    // Toggle form open/close
    setNewVehicleFormOpen(!newVehicleFormOpen);
  };

  const handleClearSearch = () => {
    setHardwareId("");
    setVehicleId("");
    setLicencePlate("");
    setTitle("");
    setLocationId("");
    setRentalType("");
    setVehicleState("");
    setVehicleStatus("");
    setDebouncedSearchQuery("");
    setPage(1); // Reset to the first page
    setNoResults(false); // Reset the no results flag
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("New vehicle data submitted:", vehicleData);

    setVehicleData({
      plate_code: "",
      licence_plate: "",
      location_id: "",
      hardware_id: "",
      mileage: "",
      vin: "",
      business_type: "",
      car_logo: "",
      registration_city: "",
      registration_year: "",
      brand: "",
      model: "",
      body_type: "",
      color: "",
      ownership: "",
      leased_by: "",
      insured_by: "",
      mortgaged_by: "",
      leasing_cost: "",
      car_icon: "",
      model_description: "",
      car_reserved_icon: "",
      parking_zones_info: "",
      feature_seats: "",
      feature_automatic: "",
      feature_carplay: "",
      feature_cruise_control: "",
      feature_parking_sensor: "",
      feature_fuel_consumption: "",
      plate_source_id: "",
      plate_color_id: "",
      state_change_id: "",
      start_later: "",
      intrip_parking_coverage: "",
      ppm_max_duration: "",
    });

    setNewVehicleFormOpen(false);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setVehicleData((prevData) => ({
          ...prevData,
          car_logo: reader.result, // Stores the image as a base64 string
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVehicleData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [showSearchFields, setShowSearchFields] = useState(false);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const query = new URLSearchParams();
      if (hardwareId) query.append("hardware_id", hardwareId);
      if (vehicleId) query.append("vehicle_id", vehicleId);
      if (licencePlate) query.append("licence_plate", licencePlate);
      if (title) query.append("title", title);
      if (locationId) query.append("location", locationId);
      if (rentalType) query.append("rental_type", rentalType);
      if (vehicleState) query.append("state", vehicleState);
      if (vehicleStatus) query.append("status", vehicleStatus);

      setDebouncedSearchQuery(query.toString());
      setPage(1);
      setTriggerSearch(true);
    }
  };

  const handleSearchClick = () => {
    const query = new URLSearchParams();
    if (hardwareId) query.append("hardware_id", hardwareId);
    if (vehicleId) query.append("vehicle_id", vehicleId);
    if (licencePlate) query.append("licence_plate", licencePlate);
    if (title) query.append("title", title);
    if (locationId) query.append("location", locationId);
    if (rentalType) query.append("rental_type", rentalType);
    if (vehicleState) query.append("state", vehicleState);
    if (vehicleStatus) query.append("status", vehicleStatus);

    setDebouncedSearchQuery(query.toString());
    setPage(1);
    setTriggerSearch(true);
  };

  // Show loading indicator if loading data after triggering a search
  if (loading && triggerSearch) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2, mt: 1 }}>
      <Grid container spacing={1} mb={1}>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          {/* Add New Vehicle Button */}
          <Grid item>
            <Button
              onClick={handleNewVehicleForm}
              sx={{
                color: "black",
                padding: "4px 8px",
                minWidth: "40px",
                "& .MuiSvgIcon-root": { fontSize: "1.2rem" }, // Smaller icon size
                marginBottom: 2,
              }}
              aria-label="Add New Vehicle"
            >
              <AddCircleOutlineOutlinedIcon />
            </Button>
          </Grid>

          {/* Dialog for Adding a New Vehicle */}
          <Dialog
            open={newVehicleFormOpen}
            onClose={handleNewVehicleForm}
            maxWidth="lg"
            fullWidth
            PaperProps={{
              sx: {
                width: "900px", // Fixed width
                height: "80vh", // Fixed height (adjustable)
                borderRadius: "12px", // Rounded corners
                overflow: "hidden", // Ensures clean UI
              },
            }}
          >
            <DialogTitle
              sx={{
                fontWeight: "bold",
                fontSize: "1.3rem",
                textAlign: "center",
                padding: "16px 24px",
              }}
            >
              Add New Vehicle
              {/* Cancel Button (Always at Top Right) */}
              <Button
                onClick={handleNewVehicleForm}
                color="error" // Makes the button red
                variant="contained" // Makes it solid red
                sx={{
                  position: "absolute",
                  top: "16px",
                  right: "16px",
                  fontWeight: "bold",
                }}
              >
                Cancel
              </Button>
            </DialogTitle>

            {/* Progress Bar */}
            <Box sx={{ width: "100%", p: 2 }}>
              <LinearProgress
                variant="determinate"
                value={(tabIndex / (totalTabs - 1)) * 100}
              />
            </Box>

            {/* Tabs for Form Sections */}
            <Tabs value={tabIndex} onChange={handleTabChange} centered>
              <Tab label="Vehicle Details" />
              <Tab label="Hardware" />
              <Tab label="Features" />
              <Tab label="Parking" />
              <Tab label="Specifications" />
              <Tab label="Pricing Model" />
              <Tab label="Functionalities" />
            </Tabs>

            {/* Form Content */}
            <DialogContent
              sx={{ padding: "24px", maxHeight: "70vh", overflow: "auto" }}
            >
              <form id="add-vehicle-form" onSubmit={handleSubmit}>
                {/* Vehicle Details Tab */}
                {tabIndex === 0 && (
                  <Grid container spacing={2}>
                    {[
                      { label: "Brand", name: "brand" },
                      { label: "Model", name: "model" },
                      { label: "Plate code", name: "plate_code" },
                      { label: "License plate", name: "licence_plate" },
                      { label: "VIN", name: "vin" },
                    ].map((field, index) => (
                      <Grid item xs={12} sm={4} key={index}>
                        <TextField
                          label={field.label}
                          fullWidth
                          name={field.name}
                          value={vehicleData[field.name]}
                          onChange={handleInputChange}
                          required
                        />
                      </Grid>
                    ))}

                    {/* Location Drop-down */}
                    <Grid item xs={12} sm={4}>
                      <TextField
                        select
                        label="Location"
                        fullWidth
                        name="location_id"
                        value={vehicleData.location_id}
                        onChange={handleInputChange}
                        required
                      >
                        {[
                          "Dubai",
                          "Abu Dhabi",
                          "Sharjah",
                          "Ajman",
                          "Drivers",
                        ].map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    {/* Upload Car Logo */}
                    <Grid item xs={12} sm={4}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          height: "54px",
                          padding: "8px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          cursor: "pointer",
                          textAlign: "center",
                          transition: "border-color 0.3s ease",
                          "&:hover": { borderColor: "#1976d2" },
                        }}
                        onClick={() =>
                          document.getElementById("car_logo_upload").click()
                        }
                      >
                        {vehicleData.car_logo ? (
                          <img
                            src={vehicleData.car_logo}
                            alt="Car Logo"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              borderRadius: "4px",
                            }}
                          />
                        ) : (
                          <Typography variant="body2" color="textSecondary">
                            Upload car logo
                          </Typography>
                        )}
                        <input
                          id="car_logo_upload"
                          type="file"
                          accept="image/*"
                          onChange={handleImageUpload}
                          hidden
                        />
                      </Box>
                    </Grid>
                  </Grid>
                )}

                {/* Hardware Tab */}
                {tabIndex === 1 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Hardware ID"
                        fullWidth
                        name="hardware_id"
                        value={vehicleData.hardware_id}
                        onChange={handleInputChange}
                        required
                      />
                    </Grid>
                  </Grid>
                )}

                {/* Features Tab */}
                {tabIndex === 2 && (
                  <Grid container spacing={2}>
                    {/* Seats as Integer Input */}
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Seats"
                        type="number"
                        fullWidth
                        name="feature_seats"
                        value={vehicleData.feature_seats}
                        onChange={handleInputChange}
                        required
                      />
                    </Grid>

                    {/* CarPlay Toggle */}
                    <Grid item xs={12} sm={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={vehicleData.feature_carplay}
                            onChange={(e) =>
                              setVehicleData({
                                ...vehicleData,
                                feature_carplay: e.target.checked,
                              })
                            }
                          />
                        }
                        label="CarPlay"
                      />
                    </Grid>

                    {/* Cruise Control Toggle */}
                    <Grid item xs={12} sm={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={vehicleData.feature_cruise_control}
                            onChange={(e) =>
                              setVehicleData({
                                ...vehicleData,
                                feature_cruise_control: e.target.checked,
                              })
                            }
                          />
                        }
                        label="Cruise control"
                      />
                    </Grid>

                    {/* Parking Sensor Toggle */}
                    <Grid item xs={12} sm={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={vehicleData.feature_parking_sensor}
                            onChange={(e) =>
                              setVehicleData({
                                ...vehicleData,
                                feature_parking_sensor: e.target.checked,
                              })
                            }
                          />
                        }
                        label="Parking sensor"
                      />
                    </Grid>

                    {/* Fuel Consumption Dropdown */}
                    <Grid item xs={12} sm={4}>
                      <TextField
                        select
                        label="Fuel consumption"
                        fullWidth
                        name="feature_fuel_consumption"
                        value={vehicleData.feature_fuel_consumption}
                        onChange={handleInputChange}
                        required
                      >
                        <MenuItem value="Low">Low</MenuItem>
                        <MenuItem value="Medium">Medium</MenuItem>
                        <MenuItem value="High">High</MenuItem>
                      </TextField>
                    </Grid>
                  </Grid>
                )}

                {/* Parking Tab */}
                {tabIndex === 3 && (
                  <Grid container spacing={2}>
                    {/* Parking Zones Input */}
                    <Grid item xs={12} sm={12}>
                      <TextField
                        label="Parking zones & RTA parking"
                        fullWidth
                        name="parking_zones_info"
                        value={vehicleData.parking_zones_info}
                        onChange={handleInputChange}
                        required
                      />
                    </Grid>

                    {/* Coverage Toggle */}
                    <Grid item xs={12} sm={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={vehicleData.intrip_parking_coverage}
                            onChange={(e) =>
                              setVehicleData({
                                ...vehicleData,
                                intrip_parking_coverage: e.target.checked,
                              })
                            }
                          />
                        }
                        label="Coverage"
                      />
                    </Grid>
                  </Grid>
                )}

                {/* Specifications Tab */}
                {tabIndex === 4 && (
                  <Grid container spacing={2}>
                    {[
                      { label: "Body type", name: "body_type" },
                      { label: "Color", name: "color" },
                      { label: "Registration city", name: "registration_city" },
                      { label: "Ownership", name: "ownership" },
                      { label: "Leased by", name: "leased_by" },
                      { label: "Insured by", name: "insured_by" },
                      { label: "Mortgaged by", name: "mortgaged_by" },
                    ].map((field, index) => (
                      <Grid item xs={12} sm={4} key={index}>
                        <TextField
                          label={field.label}
                          fullWidth
                          name={field.name}
                          value={vehicleData[field.name]}
                          onChange={handleInputChange}
                          required
                        />
                      </Grid>
                    ))}

                    {/* Registration Year as Number */}
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Registration year"
                        type="number"
                        fullWidth
                        name="registration_year"
                        value={vehicleData.registration_year}
                        onChange={handleInputChange}
                        required
                      />
                    </Grid>
                  </Grid>
                )}

                {/* Pricing Model Tab */}
                {tabIndex === 5 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        select
                        label="Business type"
                        fullWidth
                        name="business_type"
                        value={vehicleData.business_type}
                        onChange={handleInputChange}
                        required
                      >
                        {["PPM", "Daily", "Monthly"].map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    {vehicleData.business_type === "PPM" && (
                      <Grid item xs={12} sm={4}>
                        <TextField
                          label="PPM max duration"
                          fullWidth
                          name="ppm_max_duration"
                          value={vehicleData.ppm_max_duration}
                          onChange={handleInputChange}
                          required
                        />
                      </Grid>
                    )}
                  </Grid>
                )}

                {/* Functionalities Tab */}
                {tabIndex === 6 && (
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={vehicleData.start_later === "true"}
                          onChange={(e) =>
                            setVehicleData({
                              ...vehicleData,
                              start_later: e.target.checked.toString(),
                            })
                          }
                        />
                      }
                      label="Start later"
                    />
                  </Grid>
                )}
              </form>
            </DialogContent>

            {/* Navigation Buttons (Bottom Right) */}
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "16px",
                gap: 2,
              }}
            >
              <Button
                onClick={handleBack}
                disabled={tabIndex === 0}
                variant="outlined"
              >
                Back
              </Button>

              {tabIndex < totalTabs - 1 ? (
                <Button onClick={handleNext} variant="contained">
                  Next
                </Button>
              ) : (
                <>
                  <Button
                    type="submit"
                    form="add-vehicle-form"
                    color="primary"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </>
              )}
            </DialogActions>
          </Dialog>

          {/* Toggle Search Filters */}
          <Grid item>
            <IconButton
              onClick={() => setShowSearchFields((prev) => !prev)}
              sx={{
                color: "black",
                padding: "4px",
                "& .MuiSvgIcon-root": { fontSize: "1.2rem" }, // Smaller icon size
                marginBottom: 2,
              }}
              aria-label="Toggle Search Filters"
            >
              {showSearchFields ? <CloseIcon /> : <FilterAltOutlinedIcon />}
            </IconButton>
          </Grid>

          {/* View Columns Button */}
          <Grid item>
            <Button
              onClick={handleMenuClick}
              sx={{
                color: "black",
                padding: "4px 8px",
                minWidth: "40px",
                "& .MuiSvgIcon-root": { fontSize: "1.2rem" }, // Smaller icon size
                marginBottom: 2,
              }}
              aria-label="View Columns"
            >
              <ViewWeekOutlinedIcon />
            </Button>
          </Grid>

          {/* Clear Button */}
          <Grid item xs={12} sm={1}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClearSearch}
              fullWidth
              sx={{
                padding: "4px 8px", // Smaller padding for a more compact button
                fontWeight: "bold",
                fontSize: "0.75rem", // Smaller font size
                textTransform: "none",
                transition: "background-color 0.3s, box-shadow 0.3s",
                minHeight: "32px", // Ensures a consistent smaller height
                marginBottom: 2,
              }}
            >
              CLEAR
            </Button>
          </Grid>

          {/* Search Button */}
          <Grid item xs={12} sm={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearchClick}
              fullWidth
              sx={{
                padding: "4px 8px", // Smaller padding for a more compact button
                fontWeight: "bold",
                fontSize: "0.75rem", // Smaller font size
                textTransform: "none",
                marginBottom: 2,
                transition: "background-color 0.3s, box-shadow 0.3s",
                "&:hover": {
                  backgroundColor: "#0069d9",
                  boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)", // Subtle hover effect
                },
                minHeight: "32px", // Ensures a consistent smaller height
              }}
            >
              SEARCH
            </Button>
          </Grid>

          {/* Columns Selection Menu */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              sx: {
                maxHeight: "400px",
                overflowY: "auto",
              },
            }}
          >
            {Object.keys(visibleColumns).map((column) => (
              <MenuItem key={column}>
                <Checkbox
                  checked={visibleColumns[column]}
                  onChange={(e) => {
                    e.stopPropagation();
                    handleColumnToggle(column);
                  }}
                  sx={{ color: "black" }}
                />
                <ListItemText
                  primary={column.replace(/_/g, " ").toUpperCase()}
                  sx={{ fontSize: "0.8rem", color: "black" }}
                />
              </MenuItem>
            ))}
          </Menu>
        </Grid>
        <Grid
          container
          spacing={1}
          alignItems="center"
          marginRight={1}
          marginLeft={1}
          marginBottom={1}
        >
          <Grid item xs={12} sm={4}>
            <TextField
              label="Vehicle ID"
              variant="outlined"
              size="small"
              value={vehicleId}
              onChange={(e) => setVehicleId(e.target.value.trim())}
              onKeyDown={handleKeyDown}
              placeholder="Search by Vehicle ID"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Licence Plate"
              variant="outlined"
              size="small"
              fullWidth
              value={licencePlate}
              onChange={(e) => setLicencePlate(e.target.value.trim())}
              onKeyDown={handleKeyDown}
              placeholder="Search by Licence Plate"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Brand & Model"
              variant="outlined"
              size="small"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value.trim())}
              onKeyDown={handleKeyDown}
              placeholder="Search by Brand & Model"
            />
          </Grid>
        </Grid>
        {showSearchFields && (
          <Grid
            container
            spacing={1}
            marginTop={1}
            marginBottom={1}
            marginLeft={1}
            marginRight={1}
            padding={2}
            alignItems="center"
            style={{
              backgroundColor: "#f0f0f0",
              transition: "all 0.3s ease", // Smooth transition for hover effect
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Initial shadow for uplift effect
              borderRadius: "8px", // Optional: Rounded corners for a smoother look
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.boxShadow =
                "0 8px 16px rgba(0, 0, 0, 0.2)")
            } // On hover: stronger shadow
            onMouseLeave={(e) =>
              (e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)")
            } // On hover out: return to initial shadow
          >
            <Grid item xs={10} sm={3} marginTop={1} marginBottom={2}>
              <TextField
                label="Hardware ID"
                variant="outlined"
                size="small"
                value={hardwareId}
                onChange={(e) => setHardwareId(e.target.value.trim())}
                onKeyDown={handleKeyDown}
                placeholder="Search by Hardware ID"
                fullWidth
              />
            </Grid>
            <Grid item xs={10} sm={3} marginTop={1} marginBottom={2}>
              <TextField
                select
                label="Location"
                variant="outlined"
                size="small"
                fullWidth
                value={locationId}
                onChange={(e) => setLocationId(e.target.value.trim())}
                onKeyDown={handleKeyDown}
              >
                <MenuItem value="">...</MenuItem>
                <MenuItem value="Dubai">Dubai</MenuItem>
                <MenuItem value="Sharjah">Sharjah</MenuItem>
                <MenuItem value="Abu Dhabi">Abu Dhabi</MenuItem>
                <MenuItem value="Ajman">Ajman</MenuItem>
                <MenuItem value="Drivers">Drivers</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={10} sm={3} marginTop={1} marginBottom={2}>
              <TextField
                select
                label="Rental Type"
                variant="outlined"
                size="small"
                fullWidth
                value={rentalType}
                onChange={(e) => setRentalType(e.target.value.trim())}
                onKeyDown={handleKeyDown}
              >
                <MenuItem value="">...</MenuItem>
                <MenuItem value="PPM">PPM</MenuItem>
                <MenuItem value="Daily">Daily</MenuItem>
                <MenuItem value="Monthly">Monthly</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={10} sm={3} marginTop={1} marginBottom={2}>
              <TextField
                select
                label="Vehicle State"
                variant="outlined"
                size="small"
                fullWidth
                value={vehicleState}
                onChange={(e) => setVehicleState(e.target.value.trim())}
                onKeyDown={handleKeyDown}
              >
                <MenuItem value="">...</MenuItem>
                <MenuItem value="active">active</MenuItem>
                <MenuItem value="In Garage">In Garage</MenuItem>
                <MenuItem value="Cleaning In Progress">
                  Cleaning In Progress
                </MenuItem>
                <MenuItem value="Cleaning Required">Cleaning Required</MenuItem>
                <MenuItem value="Garage Inspection">Garage Inspection</MenuItem>
                <MenuItem value="Total Loss">Total Loss</MenuItem>
                <MenuItem value="Testing">Testing</MenuItem>
                <MenuItem value="Inspection">Inspection</MenuItem>
                <MenuItem value="Special Fleet">Special Fleet</MenuItem>
                <MenuItem value="Stolen">Stolen</MenuItem>
                <MenuItem value="Private Parking">Private Parking</MenuItem>
                <MenuItem value="On-boarding Process">
                  On-boarding Process
                </MenuItem>
                <MenuItem value="Impounded">Impounded</MenuItem>
                <MenuItem value="_Defleeted_">_Defleeted_</MenuItem>
                <MenuItem value="Automated IoT Issue">
                  Automated IoT Issue
                </MenuItem>
                <MenuItem value="Failed Payment">Failed Payment</MenuItem>
                <MenuItem value="Service Required">Service Required</MenuItem>
                <MenuItem value="Defleeting Process">
                  Defleeting Process
                </MenuItem>
                <MenuItem value="Floating Reservation">
                  Floating Reservation
                </MenuItem>
                <MenuItem value="Lost & Found">Lost & Found</MenuItem>
                <MenuItem value="Vehicle on Hold">Vehicle on Hold</MenuItem>
                <MenuItem value="Emergency">Emergency</MenuItem>
                <MenuItem value="Court/Police Case">Court/Police Case</MenuItem>
                <MenuItem value="Cleaning Done(Wet Seats)">
                  Cleaning Done(Wet Seats)
                </MenuItem>
                <MenuItem value="Deployment">Deployment</MenuItem>
                <MenuItem value="Battery Voltage Low">
                  Battery Voltage Low
                </MenuItem>
                <MenuItem value="In Service">In Service</MenuItem>
                <MenuItem value="Subscribe NOW">Subscribe NOW</MenuItem>
                <MenuItem value="Vehicle Lockdown">Vehicle Lockdown</MenuItem>
                <MenuItem value="DEFLEETED">DEFLEETED</MenuItem>
                <MenuItem value="Passing">Passing</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={10} sm={3} marginTop={1} marginBottom={2}>
              <TextField
                select
                label="Vehicle Status"
                variant="outlined"
                size="small"
                fullWidth
                value={vehicleStatus}
                onChange={(e) => setVehicleStatus(e.target.value.trim())}
                onKeyDown={handleKeyDown}
              >
                <MenuItem value="">...</MenuItem>
                <MenuItem value="Reserved">Reserved</MenuItem>
                <MenuItem value="Open">Open</MenuItem>
                <MenuItem value="Free">Free</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        )}
      </Grid>

      {noResults && (
        <Box marginBottom={2} display="flex" justifyContent="center">
          <Typography variant="body2" color="error">
            {error
              ? "Error: Failed to fetch vehicle data"
              : "No matching vehicle found"}
          </Typography>
        </Box>
      )}

      {sortedVehicles.length === 0 && !loading && !noResults && (
        <Box marginBottom={2} display="flex" justifyContent="center">
          <Typography variant="body2" color="error">
            No vehicles found
          </Typography>
        </Box>
      )}

      <TableContainer component={Paper}>
        <VehicleTable
          vehicles={sortedVehicles}
          visibleColumns={visibleColumns}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          handleSort={handleSort}
        />
      </TableContainer>

      {/* Pagination Section */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: 3, mb: 3 }}
      >
        {/* Element aligned to flex-start */}
        <Box>
          <Typography variant="body3" color="textSecondary" fontWeight="bold">
            Total Records: {totalCount}
          </Typography>
        </Box>
        {/* Element aligned to flex-end */}
        <Box display="flex" alignItems="center">
          <Stack spacing={2}>
            <Pagination
              count={totalPages > 0 ? totalPages : 1}
              page={page}
              onChange={handlePageChange}
              variant="outlined"
              color="primary"
              boundaryCount={2}
              siblingCount={1}
            />
          </Stack>
        </Box>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default VehiclePage;
