import React, { useState, useEffect, useCallback } from "react";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useCustomerData } from "./data/customerList";

import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  TextField,
  IconButton,
  Stack,
  Pagination,
  MenuItem,
  Button,
} from "@mui/material";
import debounce from "lodash/debounce";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CloseIcon from "@mui/icons-material/Close";

const CustomerTable = () => {
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [noResults, setNoResults] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const location = useLocation(); // To access the current URL

  const handleClearSearch = () => {
    setCustomerId("");
    setCustomerEmail("");
    setCustomerPhone("");
    setCustomerName("");
    setVerificationState("");
    setCustomerType("");
    setEmiratesId("");
    setLicenseId("");
    setDebouncedSearchQuery("");
    setPage(1);
  };

  const [customerId, setCustomerId] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [verificationState, setVerificationState] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [emiratesId, setEmiratesId] = useState("");
  const [licenseId, setLicenseId] = useState("");

  // Read the page number from the URL query parameters
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const pageFromUrl = parseInt(urlParams.get("page"), 10);
    if (pageFromUrl && pageFromUrl !== page) {
      setPage(pageFromUrl);
    }
  }, [location.search, page]); // Add `page` to the dependencies

  // eslint-disable-next-line
  const {
    data: transformedData,
    loading,
    error,
    totalPages,
    totalCount,
  } = useCustomerData(page, debouncedSearchQuery.trim());

  // Debounce search query
  // eslint-disable-next-line
  const debouncedSetDebouncedSearchQuery = useCallback(
    debounce((query) => {
      setDebouncedSearchQuery(query);
    }, 300),
    [], // No dependencies because the callback function does not depend on any values
  );

  useEffect(() => {
    if (debouncedSearchQuery) {
      setNoResults(false);
    }
  }, [debouncedSearchQuery]);

  useEffect(() => {
    if (triggerSearch) {
      setTriggerSearch(false);
    }
  }, [triggerSearch]);

  useEffect(() => {
    if (transformedData && transformedData.length === 0) {
      setNoResults(true);
    } else {
      setNoResults(false);
    }
  }, [transformedData]);

  useEffect(() => {
    if (error) {
      console.error("Error fetching customer data:", error);
      setNoResults(true);
    }
  }, [error]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const query = new URLSearchParams();
      if (customerId) query.append("customerId", customerId);
      if (customerEmail) query.append("customer_email", customerEmail);
      if (customerPhone) query.append("phone", customerPhone);
      if (customerName) query.append("name", customerName);
      if (verificationState)
        query.append("verification_state", verificationState);
      if (customerType) query.append("customer_type", customerType);
      if (emiratesId) query.append("emiratesId", emiratesId);
      if (licenseId) query.append("licenseId", licenseId);

      setDebouncedSearchQuery(query.toString());
      setPage(1);
      setTriggerSearch(true);
    }
  };

  const handleSearchClick = () => {
    const query = new URLSearchParams();
    if (customerId) query.append("customerId", customerId);
    if (customerEmail) query.append("customer_email", customerEmail);
    if (customerPhone) query.append("phone", customerPhone);
    if (customerName) query.append("name", customerName);
    if (verificationState)
      query.append("verification_state", verificationState);
    if (customerType) query.append("customer_type", customerType);
    if (emiratesId) query.append("emiratesId", emiratesId);
    if (licenseId) query.append("licenseId", licenseId);

    setDebouncedSearchQuery(query.toString());
    setPage(1);
    setTriggerSearch(true);
  };

  const [showSearchFields, setShowSearchFields] = useState(false);

  const handlePageChange = (event, value) => {
    setPage(value);
    navigate(`?page=${value}`); // Update the URL with the new page number
  };

  const getStatusStyles = (status) => {
    switch (status) {
      case "Unverified":
        return { backgroundColor: "#9e9e9e", color: "#fff" };
      case "Approved":
        return { backgroundColor: "#4caf50", color: "#fff" };
      case "Pending":
        return { backgroundColor: "#ff9800", color: "#fff" };
      case "Rejected":
        return { backgroundColor: "#f44336", color: "#fff" };
      default:
        return {};
    }
  };

  if (loading && !debouncedSearchQuery)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );

  const customers =
    transformedData && Array.isArray(transformedData)
      ? transformedData.flatMap((item) => item.records || [])
      : [];

  return (
    <Box sx={{ p: 2 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap={1}
          sx={{
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          {/* Filter/Close Icon */}
          <IconButton
            onClick={() => setShowSearchFields((prev) => !prev)}
            sx={{
              color: "primary.main",
              transition: "transform 0.3s",
              "&:hover": {
                transform: "scale(1.1)",
              },
            }}
          >
            {showSearchFields ? <CloseIcon /> : <FilterAltOutlinedIcon />}
          </IconButton>

          {/* Clear Button */}
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClearSearch}
            sx={{
              padding: "6px 12px",
              fontWeight: "bold",
              textTransform: "none",
              transition: "background-color 0.3s, box-shadow 0.3s",
              whiteSpace: "nowrap",
            }}
          >
            CLEAR
          </Button>

          {/* Search Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearchClick}
            sx={{
              padding: "6px 12px",
              fontWeight: "bold",
              textTransform: "none",
              transition: "background-color 0.3s, box-shadow 0.3s",
              whiteSpace: "nowrap",
              "&:hover": {
                backgroundColor: "#0069d9",
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            SEARCH
          </Button>
        </Box>
      </Box>

      <Grid container spacing={1} marginBottom={2} alignItems="center">
        <Grid item xs={10} sm={4}>
          <TextField
            label="Customer ID"
            variant="outlined"
            size="small"
            value={customerId}
            onChange={(e) => setCustomerId(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Search by Customer ID"
            fullWidth
          />
        </Grid>
        <Grid item xs={10} sm={4}>
          <TextField
            label="Customer Email"
            variant="outlined"
            size="small"
            value={customerEmail}
            onChange={(e) => setCustomerEmail(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Search by Email"
            fullWidth
          />
        </Grid>
        <Grid item xs={10} sm={4}>
          <TextField
            label="Customer Phone"
            variant="outlined"
            size="small"
            value={customerPhone}
            onChange={(e) => setCustomerPhone(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Search by Phone Number"
            fullWidth
          />
        </Grid>
      </Grid>
      {showSearchFields && (
        <Grid
          container
          spacing={1}
          marginBottom={2}
          alignItems="center"
          style={{
            backgroundColor: "#f0f0f0",
            transition: "all 0.3s ease",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.2)")
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)")
          }
        >
          <Grid item xs={10} sm={3} marginTop={1} marginBottom={2}>
            <TextField
              label="Customer Name"
              variant="outlined"
              size="small"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Search by Name"
              fullWidth
            />
          </Grid>
          {/* Second line starts here */}
          <Grid item xs={10} sm={3} marginTop={1} marginBottom={2}>
            <TextField
              select
              label="Verification Status"
              variant="outlined"
              size="small"
              value={verificationState}
              onChange={(e) => setVerificationState(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Search by Verification Status"
              fullWidth
            >
              {/* Empty option to represent null */}
              <MenuItem value="">...</MenuItem>
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Approved">Approved</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
              <MenuItem value="Unverified">Unverified</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={10} sm={3} marginTop={1} marginBottom={2}>
            <TextField
              select
              label="Customer Type"
              variant="outlined"
              size="small"
              value={customerType}
              onChange={(e) => setCustomerType(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Search by Customer Type"
              fullWidth
            >
              {/* Empty option to represent null */}
              <MenuItem value="">...</MenuItem>
              <MenuItem value="Tourist">Tourist</MenuItem>
              <MenuItem value="Resident">Resident</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={10} sm={3} marginTop={1} marginBottom={2}>
            <TextField
              label="Emirates ID"
              variant="outlined"
              size="small"
              value={emiratesId}
              onChange={(e) => setEmiratesId(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Search by Emirates ID"
              fullWidth
            />
          </Grid>
          <Grid item xs={10} sm={3} marginTop={1} marginBottom={2}>
            <TextField
              label="License ID"
              variant="outlined"
              size="small"
              value={licenseId}
              onChange={(e) => setLicenseId(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Search by License ID"
              fullWidth
            />
          </Grid>
        </Grid>
      )}

      {noResults && !loading && (
        <Box marginBottom={2} display="flex" justifyContent="center">
          <Typography variant="body2" color="error">
            {error
              ? "Error: Failed to fetch customer data"
              : "No matching customer found with this ID"}
          </Typography>
        </Box>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                ID
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                Email
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                Phone
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                Verification Status
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                View
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.length > 0
              ? customers.map((customer) => (
                  <TableRow key={customer.customer_id} hover>
                    <TableCell>{customer.customer_id}</TableCell>
                    <TableCell>
                      {customer.first_name && customer.last_name
                        ? `${customer.first_name} ${customer.last_name}`
                        : customer.name || "N/A"}
                    </TableCell>
                    <TableCell>{customer.email || "N/A"}</TableCell>
                    <TableCell>{customer.phone || "N/A"}</TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "inline-block",
                          borderRadius: "4px",
                          padding: "4px 8px",
                          ...getStatusStyles(customer.verification_state),
                          textAlign: "center",
                          width: "auto",
                        }}
                      >
                        {customer.verification_state}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        component={Link}
                        to={`/customer/${customer.customer_id}`}
                        style={{ color: "blue" }}
                        aria-label={`View details for customer ${customer.customer_id}`}
                      >
                        <ArrowForwardIosOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              : !loading &&
                !noResults && (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <Typography variant="body2">No Data Available</Typography>
                    </TableCell>
                  </TableRow>
                )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination Section */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: 3, mb: 3 }}
      >
        {/* Element aligned to flex-start */}
        <Box>
          <Typography variant="body3" color="textSecondary" fontWeight="bold">
            Total Records: {totalCount}
          </Typography>
        </Box>
        {/* Element aligned to flex-end */}
        <Box display="flex" alignItems="center">
          <Stack spacing={2}>
            <Pagination
              count={totalPages > 0 ? totalPages : 1}
              page={page}
              onChange={handlePageChange}
              variant="outlined"
              color="primary"
              boundaryCount={2}
              siblingCount={1}
            />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerTable;
