// Import required modules and components
import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Sidebar from "./scenes/global/Sidebar";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";

// Import pages
import CustomerDetails from "./scenes/pages/customer/customerdetails";
import CustomerTable from "./scenes/pages/customer/customertable";
import ReservationDetails from "./scenes/pages/reservation/reservationdetails";
import Login from "./scenes/pages/login/login";
import { apiFetch } from "./utils/auth";
import ReservationPage from "./scenes/pages/reservation/reservationpage";
import InvoicePage from "./scenes/pages/invoices/invoicepage";
import InvoiceDetails from "./scenes/pages/invoices/invoicedetails";
import VehiclePage from "./scenes/pages/Vehicle/vehiclepage";
import VehicleDetails from "./scenes/pages/Vehicle/vehicledetails";
import RolePage from "./scenes/pages/RolePage/rolepage";
import Map from "./scenes/pages/map/Rebalance Map/map";
import ProfilePage from "./scenes/pages/profile/profilepage";
import VehiclesMap from "./scenes/pages/map/Vehicles Map/vehiclesmap";
import ReleaseNotes from "./scenes/pages/realase/releasenotes";

function App() {
  const [theme, colorMode] = useMode();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const checkAuth = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        await apiFetch("/users/me");
        setIsAuthenticated(true);
      } catch {
        setIsAuthenticated(false);
        localStorage.removeItem("token");
      }
    } else {
      setIsAuthenticated(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    checkAuth();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <div className="app">
            <Routes>
              <Route
                path="/"
                element={<Login setIsAuthenticated={setIsAuthenticated} />}
              />
              <Route
                path="*"
                element={<Navigate to={isAuthenticated ? "/home" : "/"} />}
              />

              {isAuthenticated && (
                <>
                  {/* Vehicles Map */}
                  <Route
                    path="/home"
                    element={
                      <>
                        <Sidebar />
                        <main className="content">
                          <VehiclesMap />
                        </main>
                      </>
                    }
                  />
                  {/* Rebalances Map */}
                  <Route
                    path="/rebalances"
                    element={
                      <>
                        <Sidebar />
                        <main className="content">
                          <Map />
                        </main>
                      </>
                    }
                  />
                  {/* Customer */}
                  <Route
                    path="/customer"
                    element={
                      <>
                        <Sidebar />
                        <main className="content">
                          <CustomerTable />
                        </main>
                      </>
                    }
                  />
                  {/* Damage Reports */}
                  <Route
                    path="/Damage-Reports"
                    element={
                      <>
                        <Sidebar />
                        <main className="content"></main>
                      </>
                    }
                  />
                  {/* Release */}
                  <Route
                    path="/release"
                    element={
                      <>
                        <Sidebar />
                        <main className="content">
                          <ReleaseNotes />
                        </main>
                      </>
                    }
                  />
                  {/* Role */}
                  <Route
                    path="/role"
                    element={
                      <>
                        <Sidebar />
                        <main className="content">
                          <RolePage />
                        </main>
                      </>
                    }
                  />
                  {/* Profile */}
                  <Route
                    path="/profile"
                    element={
                      <>
                        <Sidebar />
                        <main className="content">
                          <ProfilePage />
                        </main>
                      </>
                    }
                  />
                  {/* Reservation */}
                  <Route
                    path="/reservation"
                    element={
                      <>
                        <Sidebar />
                        <main className="content">
                          <ReservationDetails />
                        </main>
                      </>
                    }
                  />
                  {/* Invoice */}
                  <Route
                    path="/invoice"
                    element={
                      <>
                        <Sidebar />
                        <main className="content">
                          <InvoicePage />
                        </main>
                      </>
                    }
                  />
                  {/* Vehicle */}
                  <Route
                    path="/vehicle"
                    element={
                      <>
                        <Sidebar />
                        <main className="content">
                          <VehiclePage />
                        </main>
                      </>
                    }
                  />
                  {/* Vehicle Details */}
                  <Route
                    path="/vehicle/:vehicleID"
                    element={
                      <>
                        <Sidebar />
                        <main className="content">
                          <VehicleDetails />
                        </main>
                      </>
                    }
                  />
                  {/* Invoice Details */}
                  <Route
                    path="/invoice/:invoiceID"
                    element={
                      <>
                        <Sidebar />
                        <main className="content">
                          <InvoiceDetails />
                        </main>
                      </>
                    }
                  />
                  {/* Reservation Details */}
                  <Route
                    path="/reservation/:reservationID"
                    element={
                      <>
                        <Sidebar />
                        <main className="content">
                          <ReservationPage />
                        </main>
                      </>
                    }
                  />
                  {/* Customer Details */}
                  <Route
                    path="/customer/:customerId"
                    element={
                      <>
                        <Sidebar />
                        <main className="content">
                          <CustomerDetails />
                        </main>
                      </>
                    }
                  />
                </>
              )}
            </Routes>
          </div>
        </Router>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
