import { useState } from "react";

const BASE_URL = "https://jf8k6mp2m8.eu-west-1.awsapprunner.com";

export const useMobilizeVehicle = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const mobilizeVehicle = async (hardwareId) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    const token = localStorage.getItem("token");
    if (!token) {
      setLoading(false);
      setError("Authentication token not found. Please log in.");
      return;
    }

    try {
      const endpoint = `${BASE_URL}/hardware/unlockAndMobilize?hardwareId=${hardwareId}`;
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        setSuccess(true);
      } else {
        const errorData = await response.json();
        setError(errorData.message || "An error occurred");
      }
    } catch (err) {
      setError("An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return { mobilizeVehicle, loading, error, success };
};
