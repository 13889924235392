/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Snackbar,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  FormLabel,
  CardContent,
  Divider,
  Table,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  TableContainer,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Card,
  Stack,
  Pagination,
  Grid,
  CircularProgress,
} from "@mui/material";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import DirectionsCarOutlinedIcon from "@mui/icons-material/DirectionsCarOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import PollOutlinedIcon from "@mui/icons-material/PollOutlined";

import { Chip } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

//State Page Icons
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import TroubleshootOutlinedIcon from "@mui/icons-material/TroubleshootOutlined";
import DeviceHubOutlinedIcon from "@mui/icons-material/DeviceHubOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import BatteryAlertOutlinedIcon from "@mui/icons-material/BatteryAlertOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";

import SaveAsOutlinedIcon from "@mui/icons-material/SaveAsOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useHardwareData } from "./data/hardwaredata";
import { useParams, useNavigate } from "react-router-dom";
import {
  usePricingModelData,
  updatePricingModel,
  updateBusinessModel,
} from "./data/pricingmodeldata";
import { useReservationHistory } from "./data/vehicledata";

//Vehicle Details [Tab]
import { useVehicleDetailsData } from "./data/VehicleDetailsTab";

//Hardware ID history
import { useHardwareHistory } from "./data/hardwarehistory";

//State History
import { useStateHistory } from "./data/statehistory";

//Damage Reports DATA
import { useDamageReportsData } from "./data/damagereportsdata";

//Parking Tickets DATA
import { useParkingData } from "./data/parkingdata";

//Actions for hardware page
import { useImmobilizeVehicle } from "./action/immobilize";
import { useMobilizeVehicle } from "./action/mobilize";
import { usePoll } from "./action/poll";
import { useUnlockVehicle } from "./action/unlock";
import { useLockVehicle } from "./action/lock";

//State Change Action
import { useStateChange } from "./action/statechange";

import { useLocation } from "react-router-dom";

import mapboxgl from "mapbox-gl";
/** Your Mapbox Access Token **/
mapboxgl.accessToken =
  "pk.eyJ1IjoidWRyaXZlLWFkbWluIiwiYSI6ImNtNmo5d3VmZjBmOGcyb3MzMms5a3BmYWoifQ.XAvacgO6cM-odmm8tQg61g";

const VehicleDetails = () => {
  const location = useLocation();
  const { vehicleData } = location.state || {};
  const title = vehicleData?.title;
  const { vehicleID } = useParams();
  const {
    data: VehicleDetailsData,
    loading: VehicleDetailsDataLoading,
    error: VehicleDetailsDataError,
  } = useVehicleDetailsData(vehicleID);

  const [vehicleState, setVehicleState] = useState(
    VehicleDetailsData?.vehicleState || "",
  );

  const [vehicleStateHistory, setVehicleStateHistory] = useState(
    VehicleDetailsData?.vehicleState ? [VehicleDetailsData.vehicleState] : [],
  );

  useEffect(() => {
    if (VehicleDetailsData?.vehicleState) {
      setVehicleState(VehicleDetailsData.vehicleState);
      setVehicleStateHistory((prevHistory) => [
        ...prevHistory,
        VehicleDetailsData.vehicleState,
      ]);
    }
  }, [VehicleDetailsData?.vehicleState]);

  const [value, setValue] = useState(0);
  const {
    data: hardwareData,
    loading: hardwareLoading,
    error: hardwareError,
    updateHardwareData,
    deleteHardwareData,
  } = useHardwareData(vehicleID);
  const [hardwareId, setHardwareId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [newHardwareId, setNewHardwareId] = useState("");
  const [deleteHardwareId, setDeleteHardwareId] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const navigate = useNavigate();

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [pendingState, setPendingState] = useState("");

  const openConfirmDialog = (newState) => {
    setPendingState(newState);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = async (confirm) => {
    setConfirmDialogOpen(false);
    if (confirm) {
      await handleStateChange(pendingState);
    }
    setPendingState("");
  };

  //For buttons in hardware tab
  const { immobilizeVehicle } = useImmobilizeVehicle();
  const { mobilizeVehicle } = useMobilizeVehicle();
  const { poll } = usePoll();
  const { unlockVehicle } = useUnlockVehicle();
  const { lockVehicle } = useLockVehicle();

  //For buttons in state tab
  const { StateChange } = useStateChange();

  const handleStateChange = async (newState) => {
    try {
      await StateChange(newState, vehicleID);
      setSnackbarMessage(`State successfully changed to "${newState}"`);
      refetchStateHistory();
      setVehicleState(newState);
      setVehicleStateHistory((prevHistory) => [...prevHistory, newState]);
      if (location.state) {
        location.state.state = newState;
      }
    } catch (error) {
      setSnackbarMessage(error.message || "Failed to change state");
    } finally {
      setSnackbarOpen(true);
      setTimeout(() => setSnackbarOpen(false), 5000);
    }
  };

  //Remaining states all functions
  const ALL_STATES = [
    "In Garage",
    "Cleaning In Progress",
    "Cleaning Required",
    "Garage Inspection",
    "Total Loss",
    "Testing",
    "Special Fleet",
    "Stolen",
    "Private Parking",
    "On-boarding Process",
    "Impounded",
    "_Defleeted_",
    "Service Required",
    "Defleeting Process",
    "Floating Reservation",
    "Lost & Found",
    "Vehicle on Hold",
    "Emergency",
    "Court/Police Case",
    "Cleaning Done (Wet Seats)",
    "Deployment",
    "In Service",
    "Subscribe NOW",
    "Vehicle Lockdown",
    "DEFLEETED",
    "Passing",
  ];

  const HandledStates = [
    "active",
    "Inspection",
    "Automated IoT Issue",
    "Failed Payment",
    "Battery Voltage Low",
  ];

  const RemainingStates = ALL_STATES.filter(
    (state) => !HandledStates.includes(state),
  );

  const [openOtherDialog, setOpenOtherDialog] = useState(false);
  const [selectedOtherState, setSelectedOtherState] = useState("");

  useEffect(() => {
    if (
      hardwareData &&
      hardwareData.vehicle_id &&
      hardwareData.vehicle_id.toString() === vehicleID
    ) {
      setHardwareId(hardwareData.hardware_id || "");
      setNewHardwareId(hardwareData.hardware_id || "");
    }
  }, [hardwareData, vehicleID]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleHardwareIdChange = (e) => {
    setNewHardwareId(e.target.value);
  };

  const handleDialogClose = async (confirm) => {
    if (confirm) {
      try {
        await updateHardwareData(hardwareId, {
          hardware_id: newHardwareId,
          vehicle_id: vehicleID,
        });
        setSnackbarMessage("Hardware ID updated successfully");
      } catch (error) {
        setSnackbarMessage(error.message || "Failed to update Hardware ID");
      } finally {
        setSnackbarOpen(true);
      }
    }
    setIsEditing(false);
    setOpenDialog(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleConfirmClick = () => {
    setOpenDialog(true);
  };

  const handleDeleteConfirmation = async (confirm) => {
    if (confirm) {
      try {
        await deleteHardwareData({
          hardware_id: deleteHardwareId,
          vehicle_id: vehicleID,
        }); // Call the delete function
        setSnackbarMessage("Hardware ID deleted successfully");
      } catch (error) {
        setSnackbarMessage(error.message || "Failed to delete Hardware ID");
      } finally {
        setSnackbarOpen(true); // Show the snackbar
      }
    }
    setOpenDeleteDialog(false); // Close the dialog
  };

  const getStatusStyles = (state) => {
    switch (state) {
      case "Reserved":
        return { backgroundColor: "#2980B9", color: "#FFFFFF" }; // Light Blue
      case "Running":
        return { backgroundColor: "#27AE60", color: "#FFFFFF" }; // Emerald Green
      case "Cancelled":
        return { backgroundColor: "#E74C3C", color: "#FFFFFF" }; // Red
      case "Expired":
        return { backgroundColor: "#7F8C8D", color: "#FFFFFF" }; // Gray
      case "Completed":
        return { backgroundColor: "#120A8F", color: "#ECF0F1" }; // Navy Blue
      default:
        return {};
    }
  };

  const getSuccessStyles = (success) => {
    switch (success) {
      case "Yes":
        return { backgroundColor: "#28A745", color: "#FFFFFF" }; // Green for success
      case "No":
        return { backgroundColor: "#DC3545", color: "#FFFFFF" }; // Red for failure
      default:
        return {}; // Default styles if no match
    }
  };

  const getStyles = (key) => {
    switch (key) {
      case "active":
        return { backgroundColor: "#009900", color: "#FFFFFF" };
      case "In Garage":
        return { backgroundColor: "#cc0000", color: "#FFFFFF" };
      case "Cleaning In Progress":
        return { backgroundColor: "#347aed", color: "#FFFFFF" };
      case "Cleaning Required":
        return { backgroundColor: "#ffb700", color: "#000000" };
      case "Garage Inspection":
        return { backgroundColor: "#964B00", color: "#FFFFFF" };
      case "Total Loss":
        return { backgroundColor: "#000000", color: "#FFFFFF" };
      case "Testing":
        return { backgroundColor: "#c27ba0", color: "#000000" };
      case "Inspection":
        return { backgroundColor: "#00ffff", color: "#000000" };
      case "Special Fleet":
        return { backgroundColor: "#ff00ff", color: "#000000" };
      case "Stolen":
        return { backgroundColor: "#a61c00", color: "#FFFFFF" };
      case "Private Parking":
        return { backgroundColor: "#cccccc", color: "#000000" };
      case "On-boarding Process":
        return { backgroundColor: "#d9d9d9", color: "#000000" };
      case "Impounded":
        return { backgroundColor: "#ff4500", color: "#FFFFFF" };
      case "_Defleeted_":
        return { backgroundColor: "#666600", color: "#FFFFFF" };
      case "Automated IoT Issue":
        return { backgroundColor: "#ff00ff", color: "#000000" };
      case "Failed Payment":
        return { backgroundColor: "#ffff00", color: "#000000" };
      case "Service Required":
        return { backgroundColor: "#ff9900", color: "#000000" };
      case "Defleeting Process":
        return { backgroundColor: "#4a86e8", color: "#FFFFFF" };
      case "Floating Reservation":
        return { backgroundColor: "#674ea7", color: "#FFFFFF" };
      case "Lost & Found":
        return { backgroundColor: "#c27ba0", color: "#000000" };
      case "Vehicle on Hold":
        return { backgroundColor: "#1c4587", color: "#FFFFFF" };
      case "Emergency":
        return { backgroundColor: "#ffd966", color: "#000000" };
      case "Court/Police Case":
        return { backgroundColor: "#FF0000", color: "#FFFFFF" };
      case "Cleaning Done(Wet Seats)":
        return { backgroundColor: "#11d218", color: "#000000" };
      case "Deployment":
        return { backgroundColor: "#27f1d2", color: "#000000" };
      case "Battery Voltage Low":
        return { backgroundColor: "#ff9900", color: "#000000" };
      case "In Service":
        return { backgroundColor: "#37976e", color: "#FFFFFF" };
      case "Subscribe NOW":
        return { backgroundColor: "#cc0000", color: "#FFFFFF" };
      case "Vehicle Lockdown":
        return { backgroundColor: "#1c4587", color: "#FFFFFF" };
      case "DEFLEETED":
        return { backgroundColor: "#000000", color: "#FFFFFF" };
      case "Passing":
        return { backgroundColor: "#a61c00", color: "#FFFFFF" };
      default:
        return { backgroundColor: "#e0e0e0", color: "#000000" }; // Default style
    }
  };

  const getBusinessType = (business_type) => {
    switch (business_type) {
      case "PPM":
        return { borderColor: "#0056b3", color: "#000000" };
      case "Daily":
        return { borderColor: "#e63946", color: "#000000" };
      case "Monthly":
        return { borderColor: "#2a9d8f", color: "#000000" };
      default:
        return { borderColor: "#b0b0b0", color: "#000000" };
    }
  };

  const getReservationStyle = (reservationState) => {
    switch (reservationState) {
      case "Reserved":
        return { backgroundColor: "#2980B9", color: "#FFFFFF" };
      case "Open":
        return { backgroundColor: "#F1C40F", color: "#FFFFFF" };
      case "Free":
        return { backgroundColor: "#009900", color: "#FFFFFF" };
      default:
        return {};
    }
  };

  // Use the custom hook to get pricing model data
  const [page, setPage] = useState(1);
  const { data, loading, error, refreshPricingModelsData } =
    usePricingModelData(vehicleID);
  const [selectedPricingModel, setSelectedPricingModel] = useState(null);

  const [setSelectedBusinessModel] = useState(null);
  const [formValues, setFormValues] = useState({
    price: "",
    discountedPrice: "",
    CDW: "",
    FDC: "",
    maxCap: "",
    business_type: "",
    intrip_parking_coverage: "No", // Default value
    excess_km_price: "",
    kilometer_limit: "",
    trip_fee: "",
    KilometerLimit: "",
  });
  const [openPricingEditDialog, setOpenPricingEditDialog] = useState(false);
  const [openBusinessEditDialog, setOpenBusinessEditDialog] = useState(false);

  const {
    data: reservations,
    loading: reservationsLoading,
    error: reservationsError,
    totalPages,
    totalCount,
  } = useReservationHistory(vehicleID, page);

  const {
    data: HardwareHistoryData,
    loading: HardwareHistoryLoading,
    error: HardwareHistoryError,
    totalPages: HardwareHistoryTotalPages,
    totalCount: HardwareHistoryTotalCount,
  } = useHardwareHistory(hardwareId, page);

  const {
    data: StateHistoryData,
    loading: StateHistoryLoading,
    error: StateHistoryError,
    totalPages: StateHistoryTotalPages,
    totalCount: StateHistoryTotalCount,
    refetchStateHistory,
  } = useStateHistory(vehicleID, page);

  const {
    data: ParkingData,
    loading: ParkingDataLoading,
    error: ParkingDataError,
    totalPages: ParkingDataTotalPages,
    totalCount: ParkingDataTotalCount,
  } = useParkingData(vehicleID, page);

  const {
    data: DamageReportsData,
    loading: DamageReportsDataLoading,
    error: DamageReportsDataError,
    totalPages: DamageReportsDataTotalPages,
    totalCount: DamageReportsDataTotalCount,
  } = useDamageReportsData(vehicleID, page);

  const [selectedReport, setSelectedReport] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    // Update the URL with the new page number
    navigate(`?page=${value}`);
  };

  const handleEditClickPricing = (model) => {
    setSelectedPricingModel(model);
    setFormValues({
      title: model.title,
      price: model.price,
      discountedPrice: model["Discounted Price"],
      CDW: model.CDW,
      FDC: model.FDC,
      maxCap: model["Max Cap"],
      KilometerLimit: model["Kilometer Limit"],
    });
    setOpenPricingEditDialog(true);
  };

  const handleEditClickBusiness = (item) => {
    if (!item) {
      console.error("Item is undefined");
      return;
    }
    setSelectedBusinessModel(item);
    setFormValues({
      business_type: item.business_type || "",
      intrip_parking_coverage: item.intrip_parking_coverage ?? false,
      excess_km_price: item.excess_km_price || "",
      kilometer_limit: item.kilometer_limit || "",
      trip_fee: item.trip_fee || "",
    });
    setOpenBusinessEditDialog(true);
  };

  const handleSavePricingModel = async () => {
    try {
      await updatePricingModel(
        vehicleID,
        {
          ...formValues,
          title: selectedPricingModel.title, // Title remains unchanged
        },
        refreshPricingModelsData,
      );
      setSnackbarMessage("Pricing model updated successfully");
    } catch (error) {
      setSnackbarMessage(error.message || "Failed to update pricing model");
    } finally {
      setSnackbarOpen(true);
      setOpenPricingEditDialog(false);
    }
  };

  const handleSaveBusinessModel = async () => {
    try {
      await updateBusinessModel(
        vehicleID,
        formValues,
        refreshPricingModelsData,
      );
      setSnackbarMessage("Business model updated successfully");
    } catch (error) {
      setSnackbarMessage(error.message || "Failed to update business model");
    } finally {
      setSnackbarOpen(true);
      setOpenBusinessEditDialog(false);
    }
  };

  const handleLock = async () => {
    try {
      await lockVehicle(hardwareId);
      setSnackbarMessage("Vehicle locked successfully");
    } catch (error) {
      setSnackbarMessage(error.message || "Failed to lock the vehicle");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleUnlock = async () => {
    try {
      await unlockVehicle(hardwareId);
      setSnackbarMessage("Vehicle unlocked successfully");
    } catch (error) {
      setSnackbarMessage(error.message || "Failed to unlock the vehicle");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleImmobilize = async () => {
    try {
      await immobilizeVehicle(hardwareId);
      setSnackbarMessage("Vehicle immobilized successfully");
    } catch (error) {
      setSnackbarMessage(error.message || "Failed to immobilize the vehicle");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleMobilize = async () => {
    try {
      await mobilizeVehicle(hardwareId);
      setSnackbarMessage("Vehicle mobilized successfully");
    } catch (error) {
      setSnackbarMessage(error.message || "Failed to mobilize the vehicle");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handlePoll = async () => {
    try {
      await poll(hardwareId);
      setSnackbarMessage("Vehicle polled successfully");
    } catch (error) {
      setSnackbarMessage(error.message || "Failed to poll the vehicle");
    } finally {
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    if (VehicleDetailsData?.lat && VehicleDetailsData?.lon) {
      const map = new mapboxgl.Map({
        container: "mapbox-container", // The ID of the map container
        style: "mapbox://styles/mapbox/streets-v11", // Map style
        center: [VehicleDetailsData.lon, VehicleDetailsData.lat], // Initial center coordinates
        zoom: 12, // Initial zoom level
      });

      // Add a marker for the vehicle's location
      new mapboxgl.Marker()
        .setLngLat([VehicleDetailsData.lon, VehicleDetailsData.lat])
        .addTo(map);

      return () => {
        map.remove(); // Cleanup the map instance on component unmount
      };
    }
  }, [VehicleDetailsData]);

  return (
    <Box sx={{ p: 2, mt: 5, ml: 1, mr: 1 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          ml: 2,
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            textTransform: "uppercase",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          {title} #{vehicleID}
          <Box
            sx={{
              display: "inline-block",
              borderRadius: "16px",
              padding: "6px 16px",
              fontSize: "1rem",
              fontWeight: "medium",
              textTransform: "none",
              ...getStyles(vehicleState), // Apply styles for the current state
            }}
          >
            {vehicleState}
          </Box>
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.history.back()}
        >
          Go Back
        </Button>
      </Box>

      <Box sx={{ overflowX: "auto", whiteSpace: "nowrap" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ minWidth: "max-content" }}
        >
          <Tab label="Details" />
          <Tab label="Hardware" />
          <Tab label="State" />
          <Tab label="Reservation History" />
          <Tab label="History" />
          <Tab label="Damage Reports" />
          <Tab label="Pricing Models" />
          <Tab label="Documents" />
          <Tab label="Parking Tickets" />
        </Tabs>
      </Box>
      <Box sx={{ p: 2 }}>
        {/* Details Tab */}
        {value === 0 && (
          <>
            {/* Loading Indicator */}
            {VehicleDetailsDataLoading && (
              <Box textAlign="center" marginTop={2}>
                <CircularProgress />
              </Box>
            )}

            {/* Error Message */}
            {VehicleDetailsDataError && (
              <Typography color="error" marginTop={2}>
                {VehicleDetailsDataError}
              </Typography>
            )}

            {/* Vehicle Details */}
            {!VehicleDetailsDataLoading &&
            !VehicleDetailsDataError &&
            VehicleDetailsData ? (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  gap: 2,
                  p: 1,
                }}
              >
                {/* Car Logo + Details Box */}
                <Box
                  sx={{
                    p: 3,
                    borderRadius: 2,
                    boxShadow: 3,
                    bgcolor: "background.paper",
                    border: "1px solid",
                    borderColor: "divider",
                    display: "flex",
                    gap: 3,
                    flexWrap: "nowrap",
                    width: "100%",
                    maxWidth: "100%",
                  }}
                >
                  {/* Left Content */}
                  <Box
                    sx={{
                      flex: "1 1 50%",
                      minWidth: "300px",
                      p: 3,
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      borderRight: "1px solid",
                      borderColor: "divider",
                    }}
                  >
                    {/* Reservation State */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography>
                        <strong>Reservation State:</strong>
                      </Typography>
                      <Box
                        sx={{
                          border: "2px solid",
                          borderRadius: "4px",
                          display: "inline-block",
                          padding: "4px",
                          ...getReservationStyle(
                            VehicleDetailsData.reservationState,
                          ),
                          textTransform: "none",
                        }}
                      >
                        {VehicleDetailsData.reservationState}
                      </Box>
                    </Box>
                    {/* Vehicle State ID */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography>
                        <strong>Vehicle State:</strong>
                      </Typography>
                      <Box
                        sx={{
                          border: "none", // Remove the border
                          borderRadius: "4px",
                          display: "inline-block",
                          padding: "4px",
                          ...getStyles(VehicleDetailsData.vehicleState),
                          textTransform: "none",
                        }}
                      >
                        {VehicleDetailsData.vehicleState}
                      </Box>
                    </Box>
                    {/* VIN */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography>
                        <strong>VIN:</strong>
                      </Typography>
                      <Typography>{VehicleDetailsData.vin}</Typography>
                    </Box>
                    {/* Business Type */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography>
                        <strong>Business Type:</strong>
                      </Typography>
                      <Box
                        sx={{
                          border: "2px solid",
                          borderRadius: "4px",
                          display: "inline-block",
                          padding: "4px",
                          ...getBusinessType(VehicleDetailsData.business_type),
                          fontWeight: "bold",
                          textTransform: "none",
                        }}
                      >
                        {VehicleDetailsData.business_type}
                      </Box>
                    </Box>
                  </Box>

                  {/* Right Content */}
                  <Box
                    sx={{
                      flex: "1 1 50%",
                      minWidth: "300px",
                      p: 3,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      gap: 2,
                    }}
                  >
                    <Typography variant="h4" fontWeight="bold">
                      {VehicleDetailsData.plate_code} -{" "}
                      {VehicleDetailsData.licence_plate}
                    </Typography>
                    <Box
                      sx={{
                        width: "100%",
                        maxWidth: 320,
                        height: 140,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0 auto",
                      }}
                    >
                      {VehicleDetailsData.car_logo ? (
                        <img
                          src={VehicleDetailsData.car_logo}
                          alt={`${VehicleDetailsData.brand} Logo`}
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      ) : (
                        <Typography variant="body2" color="text.secondary">
                          Logo not available
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>

                {/* Address + Google Map Button + Map box */}
                <Box
                  sx={{
                    p: 3,
                    borderRadius: 2,
                    boxShadow: 3,
                    bgcolor: "background.paper",
                    border: "1px solid",
                    borderColor: "divider",
                    display: "flex",
                    gap: 3,
                    flexWrap: "nowrap",
                    width: "100%",
                    maxWidth: "100%",
                  }}
                >
                  {/* Left Content */}
                  <Box
                    sx={{
                      flex: "1 1 50%",
                      minWidth: "300px",
                      p: 3,
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      borderRight: "1px solid",
                      borderColor: "divider",
                    }}
                  >
                    {/* Google Map Button */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                      }}
                    >
                      <a
                        href={`https://www.google.com/maps/place/${VehicleDetailsData.lat},${VehicleDetailsData.lon}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <button
                          style={{
                            backgroundColor: "#1E88E5",
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                            padding: "8px 12px",
                            cursor: "pointer",
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          Open in Maps
                        </button>
                      </a>
                    </Box>
                    {/* Address */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography>
                        <strong>Address:</strong>
                      </Typography>
                      <Typography>
                        {VehicleDetailsData.address || "N/A"}
                      </Typography>
                    </Box>
                  </Box>

                  {/* Right Content */}
                  <Box
                    sx={{
                      flex: "1 1 50%",
                      minWidth: "300px",
                      p: 3,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      id="mapbox-container"
                      sx={{
                        width: "100%",
                        height: "300px",
                        borderRadius: "8px",
                        overflow: "hidden",
                        boxShadow: 2,
                      }}
                    ></Box>
                  </Box>
                </Box>

                {/* LEFT SIDE: Hardware BOX */}
                <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                  {/* LEFT COLUMN */}
                  <Stack spacing={2} width="50%">
                    {/* LEFT SIDE: Hardware BOX */}
                    <Box
                      sx={{
                        p: 2,
                        borderRadius: 2,
                        boxShadow: 3,
                        bgcolor: "background.paper",
                        border: "1px solid",
                        borderColor: "divider",
                        display: "flex",
                        flexDirection: "column",
                        gap: 1.5,
                      }}
                    >
                      {/* Title */}
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "bold",
                          textTransform: "uppercase",
                        }}
                      >
                        Hardware
                      </Typography>
                      <Divider />

                      {/* Hardware ID */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography>
                          <strong>Hardware:</strong>
                        </Typography>
                        <Typography>
                          {VehicleDetailsData.hardware_id || "N/A"}
                        </Typography>
                      </Box>

                      {/* Altitude */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography>
                          <strong>Altitude:</strong>
                        </Typography>
                        <Typography>
                          {VehicleDetailsData.altitude || "N/A"}
                        </Typography>
                      </Box>

                      {/* IoT Battery */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography>
                          <strong>IoT Battery:</strong>
                        </Typography>
                        <Typography>
                          {VehicleDetailsData.iot_battery || "N/A"}
                        </Typography>
                      </Box>

                      {/* Ignition ON */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography>
                          <strong>Ignition ON:</strong>
                        </Typography>
                        <Typography>
                          {VehicleDetailsData.ignition_on ? "Yes" : "No"}
                        </Typography>
                      </Box>

                      {/* Ignition ON Timestamp */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography>
                          <strong>Ignition ON Timestamp:</strong>
                        </Typography>
                        <Typography>
                          {VehicleDetailsData.ignition_on_timestamp || "N/A"}
                        </Typography>
                      </Box>

                      {/* Ignition ON Kilometers */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography>
                          <strong>Ignition ON Kilometers:</strong>
                        </Typography>
                        <Typography>
                          {VehicleDetailsData.ignition_on_kilometers || "N/A"}
                        </Typography>
                      </Box>

                      {/* Supply Voltage */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography>
                          <strong>Supply Voltage:</strong>
                        </Typography>
                        <Typography>
                          {VehicleDetailsData.supply_voltage || "N/A"}
                        </Typography>
                      </Box>

                      {/* Vehicle Locked */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography>
                          <strong>Vehicle Locked:</strong>
                        </Typography>
                        <Typography>
                          {VehicleDetailsData.vehicle_locked ? "Yes" : "No"}
                        </Typography>
                      </Box>

                      {/* Immobilized */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography>
                          <strong>Immobilised:</strong>
                        </Typography>
                        <Typography>
                          {VehicleDetailsData.immobilised ? "Yes" : "No"}
                        </Typography>
                      </Box>

                      {/* Mileage */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography>
                          <strong>Mileage:</strong>
                        </Typography>
                        <Typography>
                          {VehicleDetailsData.mileage || "N/A"}
                        </Typography>
                      </Box>

                      {/* Fuel Level */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography>
                          <strong>Fuel Level:</strong>
                        </Typography>
                        <Typography>
                          {VehicleDetailsData.fuel_level || "N/A"}
                        </Typography>
                      </Box>
                    </Box>
                  </Stack>

                  {/* RIGHT COLUMN */}
                  <Stack spacing={2} width="50%">
                    {/* RIGHT SIDE: Parking BOX */}
                    <Box
                      sx={{
                        p: 2,
                        borderRadius: 2,
                        boxShadow: 3,
                        bgcolor: "background.paper",
                        border: "1px solid",
                        borderColor: "divider",
                        display: "flex",
                        flexDirection: "column",
                        gap: 1.5,
                      }}
                    >
                      {/* Title */}
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "bold",
                          textTransform: "uppercase",
                        }}
                      >
                        Parking
                      </Typography>
                      <Divider />

                      {/* Parking Zone Info */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography>
                          <strong>Zone Info:</strong>
                        </Typography>
                        <Typography>
                          {VehicleDetailsData.parking_zones_info || "N/A"}
                        </Typography>
                      </Box>

                      {/* Plate Source ID */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography>
                          <strong>Plate Source ID:</strong>
                        </Typography>
                        <Typography>
                          {VehicleDetailsData.plate_source_id || "N/A"}
                        </Typography>
                      </Box>

                      {/* Plate Color ID */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography>
                          <strong>Plate Color ID:</strong>
                        </Typography>
                        <Typography>
                          {VehicleDetailsData.plate_color_id || "N/A"}
                        </Typography>
                      </Box>

                      {/* In Trip Parking Coverage */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography>
                          <strong>In Trip Parking Coverage:</strong>
                        </Typography>
                        <Typography>
                          {VehicleDetailsData.intrip_parking_coverage
                            ? "Yes"
                            : "No"}
                        </Typography>
                      </Box>
                    </Box>
                    {/* RIGHT SIDE: TARS BOX */}
                    <Box
                      sx={{
                        p: 2,
                        borderRadius: 2,
                        boxShadow: 3,
                        bgcolor: "background.paper",
                        border: "1px solid",
                        borderColor: "divider",
                        display: "flex",
                        flexDirection: "column",
                        gap: 1.5,
                      }}
                    >
                      {/* Title */}
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "bold",
                          textTransform: "uppercase",
                        }}
                      >
                        TARS
                      </Typography>
                      <Divider />

                      {/* TARS ID */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography>
                          <strong>TARS ID:</strong>
                        </Typography>
                        <Typography>
                          {VehicleDetailsData.tars_id || "N/A"}
                        </Typography>
                      </Box>
                    </Box>
                  </Stack>
                </Stack>
              </Box>
            ) : (
              !VehicleDetailsDataLoading &&
              !VehicleDetailsDataError && (
                <Typography marginTop={2}>
                  No details available for this vehicle.
                </Typography>
              )
            )}
          </>
        )}

        {/* Hardware */}
        {value === 1 && (
          <Box
            sx={{
              display: "flex", // Arrange items in a row
              gap: 2, // Space between left and right sections
              alignItems: "flex-start", // Align items at the top
            }}
          >
            {/* LEFT SECTION: Hardware Box + Buttons Box (stacked) */}
            <Box>
              {/* Hardware Box */}
              <Box
                sx={{
                  p: 2,
                  borderRadius: 2,
                  boxShadow: 2,
                  bgcolor: "background.paper",
                  border: "1px solid",
                  borderColor: "divider",
                  width: "400px",
                  mb: 2, // margin between the two boxes
                }}
              >
                {hardwareLoading && (
                  <Typography variant="body2">Loading...</Typography>
                )}
                {hardwareError && (
                  <Typography color="error" variant="body2" sx={{ mb: 2 }}>
                    {hardwareError}
                  </Typography>
                )}

                <FormLabel
                  component="legend"
                  sx={{ textTransform: "uppercase", fontWeight: "bold" }}
                >
                  Hardware ID
                </FormLabel>

                <TextField
                  value={newHardwareId}
                  onChange={handleHardwareIdChange}
                  disabled={!isEditing}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={
                            isEditing ? handleConfirmClick : handleEditClick
                          }
                          aria-label={isEditing ? "Confirm edit" : "Edit"}
                        >
                          {isEditing ? <SaveAsOutlinedIcon /> : <EditIcon />}
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setDeleteHardwareId(hardwareId); // Set the hardware ID to be deleted
                            setOpenDeleteDialog(true); // Open the delete confirmation dialog
                          }}
                          aria-label="Delete"
                        >
                          <DeleteOutlinedIcon color="error" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ mb: 1 }}
                />

                {/* Dialog for changing hardware ID */}
                <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                  <DialogTitle>Confirm Edit</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Are you sure you want to change the hardware ID?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => handleDialogClose(true)}
                      color="primary"
                    >
                      Confirm
                    </Button>
                    <Button
                      onClick={() => handleDialogClose(false)}
                      color="primary"
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* Dialog for deleting hardware ID */}
                <Dialog
                  open={openDeleteDialog}
                  onClose={() => setOpenDeleteDialog(false)}
                >
                  <DialogTitle>Confirm Delete</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Are you sure you want to delete the hardware ID?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => handleDeleteConfirmation(true)}
                      color="error"
                    >
                      Delete
                    </Button>
                    <Button
                      onClick={() => handleDeleteConfirmation(false)}
                      color="primary"
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* SnackBar for HARDWARE ID */}
                <Snackbar
                  open={snackbarOpen}
                  autoHideDuration={6000}
                  onClose={handleSnackbarClose}
                  message={snackbarMessage}
                  action={
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={handleSnackbarClose}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  }
                />
              </Box>

              {/* Buttons Box */}
              <Box
                sx={{
                  p: 2,
                  borderRadius: 2,
                  boxShadow: 2,
                  bgcolor: "background.paper",
                  border: "1px solid",
                  borderColor: "divider",
                  width: "400px", // Fixed width
                }}
              >
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    mb: 2,
                    height: "70px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#8E44AD",
                    "&:hover": {
                      backgroundColor: "#732D91",
                    },
                  }}
                  onClick={handleLock}
                >
                  <LockOutlinedIcon sx={{ mb: 1 }} />
                  Lock
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    mb: 2,
                    height: "70px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#8E44AD",
                    "&:hover": {
                      backgroundColor: "#732D91",
                    },
                  }}
                  onClick={handleUnlock}
                >
                  <LockOpenOutlinedIcon sx={{ mb: 1 }} />
                  Unlock
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    mb: 2,
                    height: "70px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#8E44AD",
                    "&:hover": {
                      backgroundColor: "#732D91",
                    },
                  }}
                  onClick={handleMobilize}
                >
                  <DirectionsCarOutlinedIcon sx={{ mb: 1 }} />
                  Unlock & Mobilize
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    mb: 2,
                    height: "70px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#8E44AD",
                    "&:hover": {
                      backgroundColor: "#732D91",
                    },
                  }}
                  onClick={handleImmobilize}
                >
                  <SecurityOutlinedIcon sx={{ mb: 1 }} />
                  Lock & Immobilize
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    height: "70px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#8E44AD",
                    "&:hover": {
                      backgroundColor: "#732D91",
                    },
                  }}
                  onClick={handlePoll}
                >
                  <PollOutlinedIcon sx={{ mb: 1 }} />
                  Poll
                </Button>
              </Box>
            </Box>

            {/* RIGHT SECTION: Hardware History Table */}
            <Box
              sx={{
                p: 2,
                borderRadius: 2,
                boxShadow: 2,
                bgcolor: "background.paper",
                border: "1px solid",
                borderColor: "divider",
                flex: 1,
              }}
            >
              {/* Loading State */}
              {HardwareHistoryLoading && (
                <Typography variant="body2">
                  Loading Hardware History...
                </Typography>
              )}

              {/* Error State */}
              {HardwareHistoryError && (
                <Typography color="error" variant="body2">
                  {HardwareHistoryError}
                </Typography>
              )}

              {/* Table Data */}
              {!HardwareHistoryLoading &&
                !HardwareHistoryError &&
                Array.isArray(HardwareHistoryData) &&
                HardwareHistoryData.length > 0 && (
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              textTransform: "uppercase",
                            }}
                          >
                            Created By
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              textTransform: "uppercase",
                            }}
                          >
                            Action
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              textTransform: "uppercase",
                            }}
                          >
                            Success
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              textTransform: "uppercase",
                            }}
                          >
                            Performed By
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {HardwareHistoryData.map((item) => (
                          <TableRow key={item.created_at}>
                            <TableCell>{item.created_at}</TableCell>
                            <TableCell>{item.action}</TableCell>
                            <TableCell
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "inline-block",
                                  borderRadius: "4px",
                                  padding: "4px 8px",
                                  textAlign: "center",
                                  ...getSuccessStyles(
                                    item.success ? "Yes" : "No",
                                  ),
                                }}
                              >
                                {item.success ? "Yes" : "No"}
                              </Box>
                            </TableCell>
                            <TableCell>{item.performed_by}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}

              {/* Pagination */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mt: 1.5, mb: 1 }}
              >
                <Box>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    fontWeight="bold"
                  >
                    Total Records: {HardwareHistoryTotalCount}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Stack spacing={2}>
                    <Pagination
                      count={HardwareHistoryTotalPages}
                      page={page}
                      onChange={handlePageChange}
                      variant="outlined"
                      color="primary"
                      boundaryCount={2}
                      siblingCount={1}
                    />
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {/* State */}
        {value === 2 && (
          <Box
            sx={{
              display: "flex", // Arrange items in a row
              gap: 2, // Space between left and right sections
              alignItems: "flex-start", // Align items at the top
            }}
          >
            {/* LEFT SECTION: Vehicle Name + Buttons Box (stacked) */}
            <Box>
              {/* Buttons Box */}
              <Box
                sx={{
                  p: 2,
                  borderRadius: 2,
                  boxShadow: 2,
                  bgcolor: "background.paper",
                  border: "1px solid",
                  borderColor: "divider",
                  width: "400px", // Fixed width
                }}
              >
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => openConfirmDialog("active")}
                  sx={{
                    mb: 2,
                    height: "70px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textTransform: "none",
                    backgroundColor: "#002366", // Dark royal blue
                    color: "#FFFFFF", // White text
                    "&:hover": {
                      backgroundColor: "#4141e1",
                    },
                  }}
                >
                  <CheckCircleOutlineOutlinedIcon sx={{ mb: 1 }} />
                  active
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => openConfirmDialog("Inspection")}
                  sx={{
                    mb: 2,
                    height: "70px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textTransform: "none",
                    backgroundColor: "#002366", // Dark royal blue
                    color: "#FFFFFF", // White text
                    "&:hover": {
                      backgroundColor: "#4141e1",
                    },
                  }}
                >
                  <TroubleshootOutlinedIcon sx={{ mb: 1 }} />
                  Inspection
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => openConfirmDialog("Automated IoT Issue")}
                  sx={{
                    mb: 2,
                    height: "70px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textTransform: "none",
                    backgroundColor: "#002366", // Dark royal blue
                    color: "#FFFFFF", // White text
                    "&:hover": {
                      backgroundColor: "#4141e1",
                    },
                  }}
                >
                  <DeviceHubOutlinedIcon sx={{ mb: 1 }} />
                  Automated IoT Issue
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => openConfirmDialog("Failed Payment")}
                  sx={{
                    mb: 2,
                    height: "70px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#002366", // Dark royal blue
                    color: "#FFFFFF", // White text
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#4141e1",
                    },
                  }}
                >
                  <CancelOutlinedIcon sx={{ mb: 1 }} />
                  Failed Payment
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => openConfirmDialog("Battery Voltage Low")}
                  sx={{
                    mb: 2,
                    height: "70px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#002366", // Dark royal blue
                    color: "#FFFFFF", // White text
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#4141e1",
                    },
                  }}
                >
                  <BatteryAlertOutlinedIcon sx={{ mb: 1 }} />
                  Battery Voltage Low
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => setOpenOtherDialog(true)}
                  sx={{
                    height: "70px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#002366",
                    color: "#FFFFFF",
                    "&:hover": {
                      backgroundColor: "#4141e1",
                    },
                  }}
                >
                  <TuneOutlinedIcon sx={{ mb: 1 }} />
                  Other
                </Button>
              </Box>
            </Box>
            {/* Dialog for changing state */}
            <Dialog
              open={confirmDialogOpen}
              onClose={() => handleConfirmDialogClose(false)}
              maxWidth="xs"
              PaperProps={{
                style: {
                  borderRadius: "12px", // Slightly rounded corners
                  padding: "16px",
                },
              }}
            >
              <DialogContent>
                <DialogContentText
                  sx={{
                    textAlign: "center",
                    fontSize: "1rem",
                    color: "text.secondary",
                  }}
                >
                  Are you sure you want to change the state to{" "}
                  <strong>{pendingState}</strong>?
                </DialogContentText>
              </DialogContent>
              <DialogActions
                sx={{
                  justifyContent: "center",
                  gap: 2,
                  padding: "16px",
                }}
              >
                <Button
                  onClick={() => handleConfirmDialogClose(false)}
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    color: "#f44336", // Custom color for Cancel button (red)
                    borderColor: "#f44336", // Matching border color
                    "&:hover": {
                      backgroundColor: "rgba(244, 67, 54, 0.08)", // Light red hover
                      borderColor: "#f44336",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => handleConfirmDialogClose(true)}
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#4caf50", // Custom color for Confirm button (green)
                    "&:hover": {
                      backgroundColor: "#388e3c", // Darker green hover
                    },
                  }}
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>

            {/* Other button dialog */}
            <Dialog
              open={openOtherDialog}
              onClose={() => setOpenOtherDialog(false)}
              maxWidth="sm" // Increase the maximum width
              PaperProps={{
                style: {
                  borderRadius: "12px", // Match the rounded corners of the other dialog
                  padding: "16px", // Match the padding of the other dialog
                  width: "500px", // Explicitly set the width
                  maxWidth: "90%", // Ensure responsiveness for smaller screens
                },
              }}
            >
              <DialogTitle
                sx={{
                  textAlign: "center", // Center-align the title
                  fontSize: "1rem", // Match the font size of the other dialog
                  fontWeight: "bold", // Bold for emphasis
                  color: "text.primary", // Use consistent text color
                }}
              >
                Select a State
              </DialogTitle>
              <DialogContent
                sx={{
                  mt: 1, // Add top margin for spacing
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center", // Center-align content
                }}
              >
                <FormControl fullWidth>
                  <Select
                    value={selectedOtherState}
                    onChange={(e) => setSelectedOtherState(e.target.value)}
                    sx={{
                      borderRadius: "8px", // Slightly rounded dropdown
                    }}
                  >
                    {RemainingStates.map((state) => (
                      <MenuItem
                        key={state}
                        value={state}
                        sx={{
                          "&:hover": {
                            backgroundColor: "rgba(0, 123, 255, 0.1)", // Highlight on hover
                          },
                        }}
                      >
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions
                sx={{
                  justifyContent: "center", // Center-align the buttons
                  gap: 2, // Add space between buttons
                  padding: "16px", // Match the padding of the other dialog
                }}
              >
                <Button
                  onClick={() => setOpenOtherDialog(false)}
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    color: "#f44336", // Consistent red for Cancel button
                    borderColor: "#f44336", // Matching border color
                    "&:hover": {
                      backgroundColor: "rgba(244, 67, 54, 0.08)", // Light red hover
                      borderColor: "#f44336",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setOpenOtherDialog(false);
                    openConfirmDialog(selectedOtherState); // Open confirmation dialog with the selected state
                  }}
                  variant="contained"
                  disabled={!selectedOtherState} // Disable button if no state is selected
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#4caf50", // Consistent green for Confirm button
                    "&:hover": {
                      backgroundColor: "#388e3c", // Darker green hover
                    },
                  }}
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>

            {/* RIGHT SECTION: State History Table */}
            <Box
              sx={{
                p: 2,
                borderRadius: 2,
                boxShadow: 2,
                bgcolor: "background.paper",
                border: "1px solid",
                borderColor: "divider",
                flex: 1,
              }}
            >
              {/* Loading State */}
              {StateHistoryLoading && (
                <Typography variant="body2">
                  Loading State History...
                </Typography>
              )}

              {/* Error State */}
              {StateHistoryError && (
                <Typography color="error" variant="body2">
                  {StateHistoryError}
                </Typography>
              )}

              {/* Table Data */}
              {!StateHistoryLoading &&
                !StateHistoryError &&
                Array.isArray(StateHistoryData) &&
                StateHistoryData.length > 0 && (
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              textTransform: "uppercase",
                            }}
                          >
                            Created At
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              textTransform: "uppercase",
                            }}
                          >
                            Title
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              textTransform: "uppercase",
                            }}
                          >
                            Added by
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              textTransform: "uppercase",
                            }}
                          >
                            Removed at
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {StateHistoryData.map((item) => (
                          <TableRow key={item.created_at}>
                            <TableCell>{item.created_at || "N/A"}</TableCell>
                            <TableCell
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "inline-block",
                                  borderRadius: "4px",
                                  padding: "4px 8px",
                                  textAlign: "center",
                                  ...getStyles(item.title),
                                }}
                              >
                                {item.title || "N/A"}
                              </Box>
                            </TableCell>
                            <TableCell>{item.added_by || "N/A"}</TableCell>
                            <TableCell>{item.removed_at || "null"}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}

              {/* Pagination */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mt: 1.5, mb: 1 }}
              >
                <Box>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    fontWeight="bold"
                  >
                    Total Records: {StateHistoryTotalCount}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Stack spacing={2}>
                    <Pagination
                      count={StateHistoryTotalPages}
                      page={page}
                      onChange={handlePageChange}
                      variant="outlined"
                      color="primary"
                      boundaryCount={2}
                      siblingCount={1}
                    />
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {/* Reservation History */}
        {value === 3 && (
          <Box sx={{ p: 2 }}>
            {reservationsLoading && (
              <Typography variant="body2">Loading reservations...</Typography>
            )}
            {reservationsError && (
              <Typography color="error" variant="body2">
                {reservationsError}
              </Typography>
            )}
            {!reservationsLoading &&
              !reservationsError &&
              reservations.length > 0 && (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          Reservation ID
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          Customer ID
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          Start Time
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          End Time
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          Reservation State
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          Distance
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          Location
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          Is Dev
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reservations.map((reservation) => (
                        <TableRow
                          key={reservation.reservation_id}
                          onClick={() =>
                            navigate(
                              `/reservation/${reservation.reservation_id}`,
                            )
                          }
                          sx={{
                            cursor: "pointer",
                            "&:hover": { backgroundColor: "#f5f5f5" },
                          }}
                        >
                          <TableCell>{reservation.reservation_id}</TableCell>
                          <TableCell>{reservation.customer_id}</TableCell>
                          <TableCell>{reservation.start_time}</TableCell>
                          <TableCell>{reservation.end_time}</TableCell>
                          <TableCell
                            sx={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "inline-block",
                                borderRadius: "4px",
                                padding: "4px 8px",
                                ...getStatusStyles(
                                  reservation.reservationState,
                                ),
                                textAlign: "center",
                                width: "auto",
                              }}
                            >
                              {reservation.reservationState || "N/A"}
                            </Box>
                          </TableCell>
                          <TableCell>{reservation.distance || "N/A"}</TableCell>
                          <TableCell>{reservation.location || "N/A"}</TableCell>
                          <TableCell>
                            {reservation.is_dev ? "Yes" : "No"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            {/* Pagination Section */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mt: 3, mb: 3 }}
            >
              {/* Element aligned to flex-start */}
              <Box>
                <Typography
                  variant="body3"
                  color="textSecondary"
                  fontWeight="bold"
                >
                  Total Records: {totalCount}
                </Typography>
              </Box>
              {/* Element aligned to flex-end */}
              <Box display="flex" alignItems="center">
                <Stack spacing={2}>
                  <Pagination
                    count={totalPages > 0 ? totalPages : 1}
                    page={page}
                    onChange={handlePageChange}
                    variant="outlined"
                    color="primary"
                    boundaryCount={2}
                    siblingCount={1}
                  />
                </Stack>
              </Box>
            </Box>
          </Box>
        )}

        {/* History */}
        {value === 4 && (
          <Typography>History content for {vehicleID}</Typography>
        )}

        {/* Damage Reports */}
        {value === 5 && (
          <Box sx={{ p: 2 }}>
            {/* Loading State (Fixed) */}
            {DamageReportsDataLoading && (
              <Typography variant="body2">Loading Parking...</Typography>
            )}

            {/* Error State */}
            {DamageReportsDataError && (
              <Typography color="error" variant="body2">
                {DamageReportsDataError}
              </Typography>
            )}

            {/* Data Table */}
            {!DamageReportsDataLoading &&
              !DamageReportsDataError &&
              DamageReportsData.length > 0 && (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          ID
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          Vehicle ID
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          Reservation ID
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          Car Side
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          Damage Area
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {DamageReportsData.map((ticket) => (
                        <TableRow
                          key={ticket.id}
                          hover
                          onClick={() => setSelectedReport(ticket)}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell>{ticket.id}</TableCell>
                          <TableCell>{ticket.vehicle_id}</TableCell>
                          <TableCell>{ticket.reservation_id}</TableCell>
                          <TableCell>{ticket.car_side}</TableCell>
                          <TableCell>{ticket.damage_area}</TableCell>
                          <TableCell>
                            <Chip
                              label={ticket.status}
                              color={
                                ticket.status === "Active"
                                  ? "success"
                                  : "default"
                              }
                              size="small"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

            {/* Details Dialog with Proper Title, Full Details & Improved Zoom with Smooth Draggable Image Inside Fixed Box */}
            <Dialog
              open={!!selectedReport}
              onClose={() => setSelectedReport(null)}
              maxWidth="md"
              fullWidth
              PaperProps={{
                sx: {
                  borderRadius: 3,
                  boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
                },
              }}
            >
              <DialogTitle
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                Damage Report #{selectedReport?.id}
                <IconButton onClick={() => setSelectedReport(null)}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                {selectedReport && (
                  <Grid container spacing={4}>
                    {/* Left Side - Full Report Details */}
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={2}>
                        <Box>
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            display="block"
                          >
                            Vehicle ID
                          </Typography>
                          <Typography variant="body1">
                            {selectedReport.vehicle_id}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            display="block"
                          >
                            Reservation ID
                          </Typography>
                          <Typography variant="body1">
                            {selectedReport.reservation_id}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            display="block"
                          >
                            Car Side
                          </Typography>
                          <Typography variant="body1">
                            {selectedReport.car_side}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            display="block"
                          >
                            Damage Area
                          </Typography>
                          <Typography variant="body1">
                            {selectedReport.damage_area}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            display="block"
                          >
                            Status
                          </Typography>
                          <Chip
                            label={selectedReport.status}
                            color={
                              selectedReport.status === "Active"
                                ? "success"
                                : "default"
                            }
                            size="small"
                          />
                        </Box>
                        <Box>
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            display="block"
                          >
                            Reviewed By
                          </Typography>
                          <Typography variant="body1">
                            {selectedReport.reviewed_by || "Not Reviewed"}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            display="block"
                          >
                            Reviewed At
                          </Typography>
                          <Typography variant="body1">
                            {selectedReport.reviewed_at
                              ? new Date(
                                  selectedReport.reviewed_at,
                                ).toLocaleString()
                              : "Not Reviewed"}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            display="block"
                          >
                            Created At
                          </Typography>
                          <Typography variant="body1">
                            {new Date(
                              selectedReport.created_at,
                            ).toLocaleString()}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            display="block"
                          >
                            Comment
                          </Typography>
                          <Typography variant="body1">
                            {selectedReport.comment || "No comment"}
                          </Typography>
                        </Box>
                      </Stack>
                    </Grid>
                    {/* Right Side - Image with Fixed Box & Smooth Drag */}
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      position="relative"
                    >
                      {selectedReport.image ? (
                        <Box
                          position="relative"
                          width="400px"
                          height="400px"
                          overflow="hidden"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          border={1}
                          borderColor="grey.300"
                          borderRadius={2}
                        >
                          <Box
                            component="img"
                            src={selectedReport.image}
                            alt="Damage Report"
                            sx={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              objectFit: "contain",
                              cursor: "grab",
                              transform: "translate(0px, 0px) scale(1)",
                              transition: "transform 0.2s ease-in-out",
                            }}
                            id="zoomable-image"
                            onMouseDown={(e) => {
                              e.preventDefault();
                              const img = e.currentTarget;
                              const container = img.parentElement;

                              let startX = e.clientX;
                              let startY = e.clientY;
                              let translateX =
                                parseFloat(img.dataset.translateX) || 0;
                              let translateY =
                                parseFloat(img.dataset.translateY) || 0;
                              let isDragging = true;

                              const handleMouseMove = (event) => {
                                if (!isDragging) return;

                                let newTranslateX =
                                  translateX + event.movementX;
                                let newTranslateY =
                                  translateY + event.movementY;

                                // Prevent overflow by checking bounds
                                const scale =
                                  parseFloat(img.dataset.scale) || 1;
                                const imgRect = img.getBoundingClientRect();
                                const containerRect =
                                  container.getBoundingClientRect();

                                const maxX =
                                  (imgRect.width * scale -
                                    containerRect.width) /
                                  2;
                                const maxY =
                                  (imgRect.height * scale -
                                    containerRect.height) /
                                  2;

                                if (Math.abs(newTranslateX) <= maxX)
                                  translateX = newTranslateX;
                                if (Math.abs(newTranslateY) <= maxY)
                                  translateY = newTranslateY;

                                img.style.transform = `translate(${translateX}px, ${translateY}px) scale(${scale})`;
                                img.dataset.translateX = translateX;
                                img.dataset.translateY = translateY;
                              };

                              const handleMouseUp = () => {
                                isDragging = false;
                                window.removeEventListener(
                                  "mousemove",
                                  handleMouseMove,
                                );
                                window.removeEventListener(
                                  "mouseup",
                                  handleMouseUp,
                                );
                              };

                              window.addEventListener(
                                "mousemove",
                                handleMouseMove,
                              );
                              window.addEventListener("mouseup", handleMouseUp);
                            }}
                          />
                          {/* Zoom Controls on Top of Image */}
                          <Box
                            position="absolute"
                            top={10}
                            right={10}
                            display="flex"
                            gap={1}
                          >
                            <IconButton
                              size="small"
                              onClick={() => {
                                const img =
                                  document.getElementById("zoomable-image");
                                let currentScale =
                                  parseFloat(img.dataset.scale) || 1;
                                let newScale = Math.min(currentScale + 0.2, 3);
                                img.style.transform = `translate(0px, 0px) scale(${newScale})`;
                                img.dataset.scale = newScale;
                              }}
                            >
                              <ZoomInIcon />
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={() => {
                                const img =
                                  document.getElementById("zoomable-image");
                                let currentScale =
                                  parseFloat(img.dataset.scale) || 1;
                                let newScale = Math.max(currentScale - 0.2, 1);
                                img.style.transform = `translate(0px, 0px) scale(${newScale})`;
                                img.dataset.scale = newScale;
                              }}
                            >
                              <ZoomOutIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          No Image Available
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                )}
              </DialogContent>
            </Dialog>
          </Box>
        )}

        {/* Pricing Models and Business Type */}
        {value === 6 && (
          <Box sx={{ p: 1 }}>
            {/* Business Model Section */}
            <>
              <Typography
                variant="h6"
                align="left"
                sx={{ fontWeight: "bold", mb: 1, textTransform: "uppercase" }}
              >
                Business Type
              </Typography>
              <Divider sx={{ mb: 3 }} />
            </>
            <Card
              sx={{
                minWidth: 350,
                maxWidth: 350,
                transition: "transform 0.3s",
                "&:hover": { transform: "scale(1.05)", boxShadow: 10 },
              }}
            >
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {data.map((item) => (
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                    >
                      {item.business_type || "N/A"}
                    </Typography>
                  ))}
                  <IconButton
                    aria-label="edit"
                    onClick={() => handleEditClickBusiness(data[0])}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ mb: 1 }} />

                <Box display="flex" flexDirection="column" gap={1}>
                  {data.map((item, index) => (
                    <Box
                      key={index}
                      display="flex"
                      justifyContent="space-between"
                      sx={{ mb: 1, width: "100%" }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Business Type:
                      </Typography>
                      <Typography variant="body1">
                        {item.business_type || "N/A"}
                      </Typography>
                    </Box>
                  ))}
                  {data.map((item, index) => (
                    <Box
                      key={index}
                      display="flex"
                      justifyContent="space-between"
                      sx={{ mb: 1, width: "100%" }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Trip Fee:
                      </Typography>
                      <Typography variant="body1">
                        AED {item.trip_fee || "N/A"}
                      </Typography>
                    </Box>
                  ))}
                  {data.map((item, index) => (
                    <Box
                      key={index}
                      display="flex"
                      justifyContent="space-between"
                      sx={{ mb: 1, width: "100%" }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        In-trip Parking Coverage:
                      </Typography>
                      <Typography variant="body1">
                        {item.intrip_parking_coverage ? "Yes" : "No"}
                      </Typography>
                    </Box>
                  ))}
                  {data.map((item, index) => (
                    <Box
                      key={index}
                      display="flex"
                      justifyContent="space-between"
                      sx={{ mb: 1, width: "100%" }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Excess KM Price:
                      </Typography>
                      <Typography variant="body1">
                        AED {item.excess_km_price || "N/A"}
                      </Typography>
                    </Box>
                  ))}
                  {data.map((item, index) => (
                    <Box
                      key={index}
                      display="flex"
                      justifyContent="space-between"
                      sx={{ mb: 1, width: "100%" }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Kilometer Limit:
                      </Typography>
                      <Typography variant="body1">
                        {item.kilometer_limit || "N/A"}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </CardContent>
            </Card>

            {/* Edit Business Model Dialog */}
            <Dialog
              open={openBusinessEditDialog}
              onClose={() => setOpenBusinessEditDialog(false)}
              maxWidth="sm"
              PaperProps={{
                style: {
                  width: "500px",
                  height: "auto",
                  padding: "20px",
                  borderRadius: "12px",
                  boxShadow: "0 6px 30px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <DialogTitle
                style={{
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  textAlign: "center",
                }}
              >
                Edit Business Model
              </DialogTitle>
              <DialogContent style={{ padding: "24px" }}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  style={{ marginBottom: "16px", borderRadius: "6px" }}
                >
                  <InputLabel>Business Type</InputLabel>
                  <Select
                    value={formValues.business_type}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        business_type: e.target.value,
                      })
                    }
                    label="Business Type"
                  >
                    <MenuItem value="Per Minute">Per Minute</MenuItem>
                    <MenuItem value="Daily">Daily</MenuItem>
                    <MenuItem value="Monthly">Monthly</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  margin="dense"
                  label="Trip Fee"
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={formValues.trip_fee}
                  onChange={(e) =>
                    setFormValues({ ...formValues, trip_fee: e.target.value })
                  }
                  style={{ marginBottom: "16px", borderRadius: "6px" }}
                />
                <FormControlLabel
                  label="Parking Coverage in an on-ongoing trip"
                  control={
                    <Checkbox
                      checked={!!formValues.intrip_parking_coverage}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          intrip_parking_coverage: e.target.checked,
                        })
                      }
                    />
                  }
                />
                <TextField
                  margin="dense"
                  label="Excess Km Price"
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={formValues.excess_km_price}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      excess_km_price: e.target.value,
                    })
                  }
                  style={{ marginBottom: "16px", borderRadius: "6px" }}
                />
                <TextField
                  margin="dense"
                  label="Kilometer Limit"
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={formValues.kilometer_limit}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      kilometer_limit: e.target.value,
                    })
                  }
                  style={{ marginBottom: "16px", borderRadius: "6px" }}
                />
              </DialogContent>
              <DialogActions
                style={{
                  justifyContent: "space-between",
                  padding: "16px 24px",
                }}
              >
                <Button
                  onClick={() => setOpenBusinessEditDialog(false)}
                  color="secondary"
                  style={{
                    textTransform: "none",
                    backgroundColor: "#e50000",
                    color: "white",
                    borderRadius: "6px",
                    padding: "8px 16px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSaveBusinessModel}
                  color="primary"
                  style={{
                    textTransform: "none",
                    backgroundColor: "#3f51b5",
                    color: "white",
                    borderRadius: "6px",
                    padding: "8px 16px",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#5c6bc0")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "#3f51b5")
                  }
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>

            {/* Pricing Model Information */}
            {loading && (
              <Typography variant="h6" align="center">
                Loading...
              </Typography>
            )}
            {error && (
              <Typography color="error" align="center">
                {error}
              </Typography>
            )}
            {!loading && !error && data.length > 0 && (
              <>
                <Typography
                  variant="h6"
                  marginTop={3}
                  align="left"
                  sx={{ fontWeight: "bold", mb: 1, textTransform: "uppercase" }}
                >
                  Pricing Models
                </Typography>
                <Divider sx={{ mb: 3 }} />
                <Box display="flex" flexWrap="wrap" gap={3}>
                  {data.map((item) =>
                    item.pricingModels.map((model, index) => (
                      <Card
                        key={index}
                        sx={{
                          minWidth: 250,
                          maxWidth: 300,
                          transition: "transform 0.3s",
                          "&:hover": {
                            transform: "scale(1.05)",
                            boxShadow: 10,
                          },
                        }}
                      >
                        <CardContent sx={{ textAlign: "left" }}>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              variant="h5"
                              sx={{ fontWeight: "bold", mb: 1 }}
                            >
                              {model.title}
                            </Typography>
                            <IconButton
                              aria-label="edit"
                              onClick={() => handleEditClickPricing(model)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Box>
                          <Divider sx={{ mb: 1 }} />
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                          >
                            {[
                              {
                                label: "Price",
                                value: model.price,
                                isCurrency: true,
                              },
                              {
                                label: "Discounted Price",
                                value: model["Discounted Price"],
                                isCurrency: true,
                              },
                              {
                                label: "CDW",
                                value: model.CDW,
                                isCurrency: true,
                              },
                              {
                                label: "FDC",
                                value: model.FDC,
                                isCurrency: true,
                              },
                              { label: "Max Cap", value: model["Max Cap"] },
                              {
                                label: "Kilometer Limit",
                                value: model["Kilometer Limit"],
                              },
                            ].map(({ label, value, isCurrency }, idx) => {
                              const displayValue =
                                value !== undefined && value !== null
                                  ? value
                                  : "N/A";
                              return (
                                <Box
                                  key={idx}
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  sx={{ mb: 1, width: "100%" }}
                                >
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      color:
                                        displayValue !== "N/A"
                                          ? "primary.main"
                                          : "grey",
                                      opacity: displayValue !== "N/A" ? 1 : 0.6,
                                    }}
                                  >
                                    {label}:
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      color:
                                        displayValue !== "N/A"
                                          ? "primary.main"
                                          : "grey",
                                      opacity: displayValue !== "N/A" ? 1 : 0.6,
                                      marginLeft: "auto",
                                      flexGrow: 1,
                                      textAlign: "right",
                                    }}
                                  >
                                    {displayValue === "N/A"
                                      ? "N/A"
                                      : isCurrency
                                        ? `AED ${displayValue}`
                                        : displayValue}
                                  </Typography>
                                </Box>
                              );
                            })}
                          </Box>
                        </CardContent>
                      </Card>
                    )),
                  )}
                </Box>
              </>
            )}

            {/* Edit Pricing Model Dialog */}
            <Dialog
              open={openPricingEditDialog}
              onClose={() => setOpenPricingEditDialog(false)}
              maxWidth="sm"
              PaperProps={{
                style: {
                  width: "500px",
                  height: "auto",
                  padding: "20px",
                  borderRadius: "12px",
                  boxShadow: "0 6px 30px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontSize: "1.5rem",
                  textAlign: "center",
                }}
              >
                {formValues.title}
              </Typography>
              <DialogContent style={{ padding: "24px" }}>
                <TextField
                  margin="dense"
                  label="Price"
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={formValues.price}
                  onChange={(e) =>
                    setFormValues({ ...formValues, price: e.target.value })
                  }
                  style={{ marginBottom: "16px", borderRadius: "6px" }}
                />
                <TextField
                  margin="dense"
                  label="Discounted Price"
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={formValues.discountedPrice}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      discountedPrice: e.target.value,
                    })
                  }
                  style={{ marginBottom: "16px", borderRadius: "6px" }}
                />
                <TextField
                  margin="dense"
                  label="CDW"
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={formValues.CDW}
                  onChange={(e) =>
                    setFormValues({ ...formValues, CDW: e.target.value })
                  }
                  style={{ marginBottom: "16px", borderRadius: "6px" }}
                />
                <TextField
                  margin="dense"
                  label="FDC"
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={formValues.FDC}
                  onChange={(e) =>
                    setFormValues({ ...formValues, FDC: e.target.value })
                  }
                  style={{ marginBottom: "16px", borderRadius: "6px" }}
                />
                {formValues.title === "Per Minute" && (
                  <TextField
                    margin="dense"
                    label="Max Cap"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={formValues.maxCap}
                    onChange={(e) =>
                      setFormValues({ ...formValues, maxCap: e.target.value })
                    }
                    style={{ marginBottom: "16px", borderRadius: "6px" }}
                  />
                )}
              </DialogContent>
              <DialogActions
                style={{
                  justifyContent: "space-between",
                  padding: "16px 24px",
                }}
              >
                <Button
                  onClick={() => setOpenPricingEditDialog(false)}
                  color="secondary"
                  style={{
                    textTransform: "none",
                    backgroundColor: "#e50000",
                    color: "white",
                    borderRadius: "6px",
                    padding: "8px 16px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSavePricingModel}
                  color="primary"
                  style={{
                    textTransform: "none",
                    backgroundColor: "#3f51b5",
                    color: "white",
                    borderRadius: "6px",
                    padding: "8px 16px",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#5c6bc0")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "#3f51b5")
                  }
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        )}

        {/* Documents */}
        {value === 7 && <Typography>Documents for {vehicleID}</Typography>}

        {/* Parking Tickets */}
        {value === 8 && (
          <Box sx={{ p: 2 }}>
            {/* Loading State */}
            {ParkingDataLoading && (
              <Typography variant="body2">Loading Parking...</Typography>
            )}

            {/* Error State */}
            {ParkingDataError && (
              <Typography color="error" variant="body2">
                {ParkingDataError}
              </Typography>
            )}

            {/* Data Table */}
            {!ParkingDataLoading &&
              !ParkingDataError &&
              ParkingData.length > 0 && (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          Ticket ID
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          Start Time
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          Expiry Time
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          Zone No
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ParkingData.map((ticket) => (
                        <TableRow key={ticket.ticket_id}>
                          <TableCell>{ticket.ticket_id}</TableCell>
                          <TableCell>{ticket.start_time}</TableCell>
                          <TableCell>{ticket.expiry_time}</TableCell>
                          <TableCell>{ticket.zone_no}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

            {/* No Data State */}
            {!ParkingDataLoading &&
              !ParkingDataError &&
              ParkingData.length === 0 && (
                <Typography variant="body2">
                  No parking data available.
                </Typography>
              )}

            {/* Pagination Section */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mt: 3, mb: 3 }}
            >
              {/* Total Records */}
              <Box>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  fontWeight="bold"
                >
                  Total Records: {ParkingDataTotalCount}
                </Typography>
              </Box>

              {/* Pagination Component */}
              <Box display="flex" alignItems="center">
                <Stack spacing={2}>
                  <Pagination
                    count={
                      ParkingDataTotalPages > 0 ? ParkingDataTotalPages : 1
                    }
                    page={page}
                    onChange={handlePageChange}
                    variant="outlined"
                    color="primary"
                    boundaryCount={2}
                    siblingCount={1}
                  />
                </Stack>
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      {/* Snackbar for feedback */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#4caf50", // Green background
            color: "#ffffff", // White text for better contrast
          },
        }}
      />
    </Box>
  );
};

export default VehicleDetails;
