import { useState, useEffect, useCallback } from "react";

// Define the BASE_URL
const BASE_URL = "https://jf8k6mp2m8.eu-west-1.awsapprunner.com";

export const useCreditData = (customerId = "", page = 1) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0); // New state for total credit count

  // Wrap fetchData inside useCallback
  const fetchData = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      setLoading(false);
      return;
    }

    const endpoint = `${BASE_URL}/credits?customerId=${customerId}&page=${page}`;

    try {
      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        if (response.status === 403) {
          setError("You are not authorized to access this resource");
        } else {
          throw new Error(
            `Failed to fetch ${response.url}: ${response.statusText}`,
          );
        }
        return;
      }

      const result = await response.json();

      const count = result.total_count || 0;
      setTotalCount(count);

      setData(result.records || []);
      setTotalPages(result.total_pages ?? 1);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setError(error.message || "An unknown error occurred");
    } finally {
      setLoading(false);
    }
  }, [customerId, page]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Function to delete a credit voucher by ID
  const deleteCredit = async (credit_voucher_id) => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      return "No token found"; // Return error message
    }

    const deleteEndpoint = `${BASE_URL}/credits/${credit_voucher_id}`;

    try {
      const response = await fetch(deleteEndpoint, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        if (response.status === 403) {
          return "You are not authorized to delete this credit"; // Return error message
        } else {
          throw new Error(
            `Failed to delete ${response.url}: ${response.statusText}`,
          );
        }
      }

      // Re-fetch the data after deletion
      await fetchData();
      return null; // No error
    } catch (error) {
      console.error("Failed to delete credit:", error);
      return error.message || "An unknown error occurred during deletion"; // Return error message
    }
  };

  // Function to create a new credit voucher
  const createCredit = async (
    value,
    expirationDate,
    reservation_id,
    reason,
    comments,
    category,
  ) => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      return "No token found";
    }

    const newCredit = {
      customerId,
      expirationDate,
      reservation_id,
      value,
      reason,
      comments,
      category,
    };

    const createEndpoint = `${BASE_URL}/credits`;

    try {
      const response = await fetch(createEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newCredit),
      });

      if (!response.ok) {
        if (response.status === 403) {
          setError("You are not authorized to create this credit");
          return "You are not authorized to create this credit";
        } else {
          throw new Error(
            `Failed to create credit ${response.url}: ${response.statusText}`,
          );
        }
      }

      // Re-fetch data after creation
      await fetchData();
    } catch (error) {
      console.error("Failed to create credit:", error);
      setError(error.message || "An unknown error occurred during creation");
    }
  };

  return {
    data,
    loading,
    error,
    totalPages,
    totalCount,
    deleteCredit,
    createCredit,
  };
};
