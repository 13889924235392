/* eslint-disable no-unused-vars */
import { useState, useEffect, useCallback } from "react";

const BASE_URL = "https://jf8k6mp2m8.eu-west-1.awsapprunner.com";

export const useHistoryData = (customerId = "", page = 1) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0); // New state for total history count

  // Wrap fetchData in useCallback to prevent re-creation on every render
  const fetchData = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      setLoading(false);
      return;
    }

    const endpoint = customerId
      ? `${BASE_URL}/history?customerId=${customerId}&page=${page}`
      : `${BASE_URL}/history?customerId=${customerId}`;

    try {
      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        localStorage.removeItem("token");
        window.location.href = "/";
        return;
      }

      if (!response.ok) {
        throw new Error(
          `Failed to fetch ${response.url}: ${response.statusText}`,
        );
      }

      const result = await response.json();

      const count = result.total_count || 0;
      setTotalCount(count);

      const dataArray = Array.isArray(result) ? result : [result];
      const transformedData = dataArray.flatMap((item) => item.records || []);

      setData(transformedData);
      setTotalPages(result.total_pages ?? 1);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setError(error.message || "An unknown error occurred");
    } finally {
      setLoading(false);
    }
  }, [customerId, page]); // Add customerId and page as dependencies

  useEffect(() => {
    fetchData();
  }, [fetchData]); // Now include fetchData in the dependency array

  // Function to create a note
  const createNote = async (note, customerId) => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      return null;
    }

    try {
      const response = await fetch(`${BASE_URL}/history/customers`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          note: note,
          customerId: customerId,
        }),
      });

      if (response.status === 403) {
        setError("You do not have permission to create a note.");
        return null;
      }

      if (response.status === 401) {
        localStorage.removeItem("token");
        window.location.href = "/";
        return null;
      }

      if (!response.ok) {
        throw new Error(`Failed to create note: ${response.statusText}`);
      }

      const result = await response.json();

      // Fetch data again after the note is created
      await fetchData();
      return null;
    } catch (error) {
      console.error("Failed to create note:", error);
      setError(error.message || "An unknown error occurred");
      return error.message;
    }
  };

  return { data, loading, error, totalPages, totalCount, createNote };
};
