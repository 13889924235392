import React, { useRef, useEffect, useCallback, useState } from "react";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CloseOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  RestartAltOutlined,
} from "@mui/icons-material";
import { IconButton, Typography, Box } from "@mui/material";

export const CustomImageViewer = ({
  src,
  currentImageIndex,
  setIsVisible,
  setCurrentImageIndex,
  length,
}) => {
  const [scale, setScale] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [lastPosition, setLastPosition] = useState({ x: 0, y: 0 });
  const imageContainerRef = useRef(null);

  useEffect(() => {
    setScale(1);
    setPosition({ x: 0, y: 0 });
  }, [src]);

  const handleClose = useCallback(() => setIsVisible(false), [setIsVisible]);
  const handleNext = useCallback(
    () => setCurrentImageIndex((i) => (i + 1) % length),
    [length, setCurrentImageIndex],
  );
  const handlePrev = useCallback(
    () => setCurrentImageIndex((i) => (i - 1 + length) % length),
    [length, setCurrentImageIndex],
  );

  const handleZoomIn = () => setScale((s) => Math.min(s + 0.2, 3));
  const handleZoomOut = () => setScale((s) => Math.max(s - 0.2, 1));
  const handleReset = () => {
    setScale(1);
    setPosition({ x: 0, y: 0 });
  };

  useEffect(() => {
    const imgEl = imageContainerRef.current;
    if (!imgEl) return;

    const handleWheel = (e) => {
      e.preventDefault();
      if (e.deltaY < 0) setScale((s) => Math.min(s + 0.1, 3));
      else setScale((s) => Math.max(s - 0.1, 1));
    };

    const handleMouseDown = (e) => {
      if (scale === 1) return;
      e.preventDefault();
      setIsDragging(true);
      setLastPosition({ x: e.clientX - position.x, y: e.clientY - position.y });
    };

    const handleMouseMove = (e) => {
      if (!isDragging) return;
      e.preventDefault();
      setPosition({
        x: e.clientX - lastPosition.x,
        y: e.clientY - lastPosition.y,
      });
    };

    const handleMouseUp = () => setIsDragging(false);

    imgEl.addEventListener("wheel", handleWheel, { passive: false });
    imgEl.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      imgEl.removeEventListener("wheel", handleWheel);
      imgEl.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, lastPosition, position, scale]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
        touchAction: "none",
      }}
    >
      <Box
        ref={imageContainerRef}
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: scale > 1 ? (isDragging ? "grabbing" : "grab") : "zoom-in",
        }}
      >
        <img
          src={src}
          alt={`Uploaded document ${currentImageIndex + 1}`}
          style={{
            transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
            transition: isDragging ? "none" : "transform 0.3s ease",
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain",
            borderRadius: "8px",
            boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
            userSelect: "none",
            pointerEvents: "none",
          }}
          draggable={false}
          onClick={() => setScale(scale === 1 ? 1.5 : 1)}
        />
      </Box>

      <Box
        sx={{
          position: "absolute",
          bottom: "10px",
          left: "50%",
          transform: "translateX(-50%)",
          height: "60px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          zIndex: 10,
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          borderRadius: "12px",
          padding: "0 20px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        }}
      >
        <IconButton onClick={handleClose}>
          <CloseOutlined fontSize="medium" sx={{ color: "white" }} />
        </IconButton>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={handlePrev}>
            <ArrowLeftOutlined fontSize="large" sx={{ color: "white" }} />
          </IconButton>
          <Typography sx={{ color: "white", mx: 2 }}>
            {currentImageIndex + 1}/{length}
          </Typography>
          <IconButton onClick={handleNext}>
            <ArrowRightOutlined fontSize="large" sx={{ color: "white" }} />
          </IconButton>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={handleZoomIn}>
            <ZoomInOutlined sx={{ color: "white" }} />
          </IconButton>
          <IconButton onClick={handleZoomOut}>
            <ZoomOutOutlined sx={{ color: "white" }} />
          </IconButton>
          <IconButton onClick={handleReset}>
            <RestartAltOutlined sx={{ color: "white" }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};
