import { useState, useEffect } from "react";

const BASE_URL = "https://jf8k6mp2m8.eu-west-1.awsapprunner.com";

export const usePolygonData = () => {
  const [rawData, setRawData] = useState(null); // Store the original data
  const [transformedData, setTransformedData] = useState([]); // Store transformed data for visualization or operations
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("No token found");
        setLoading(false);
        return;
      }

      const endpoint = `${BASE_URL}/hotspots`;

      try {
        const response = await fetch(endpoint, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          localStorage.removeItem("token");
          window.location.href = "/";
          return;
        }

        if (response.status === 403) {
          setError("You are not authorized to access this page");
          setLoading(false);
          return;
        }

        if (!response.ok) {
          throw new Error(
            `Failed to fetch ${response.url}: ${response.statusText}`,
          );
        }

        const result = await response.json();

        // Store raw data
        setRawData(result);

        // Transform data for visualization or further processing
        if (
          result.type === "FeatureCollection" &&
          Array.isArray(result.features)
        ) {
          const transformed = result.features.map((feature) => ({
            id: feature.id,
            area: feature.properties.Area,
            zone: feature.properties.Zone,
            geometry: feature.geometry,
          }));
          setTransformedData(transformed);
        } else {
          throw new Error("Unexpected data format");
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setError(error.message || "An unknown error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { rawData, transformedData, loading, error };
};
