import { useState, useEffect } from "react";

const BASE_URL = "https://jf8k6mp2m8.eu-west-1.awsapprunner.com";

export const useReservationData = (reservationId) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      let apiEndpoint = `${BASE_URL}/reservations/${reservationId}`;

      const token = localStorage.getItem("token");
      if (!token) {
        setError("No token found");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(apiEndpoint, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          localStorage.removeItem("token");
          window.location.href = "/";
          return;
        }

        if (!response.ok) {
          throw new Error(
            `Network response was not ok: ${response.statusText}`,
          );
        }

        const result = await response.json();

        let dataArray = result[0];

        let combinedData = [
          {
            custID: Number(dataArray.customer_id),
            reservationID:
              Number(dataArray.reservation_id) || dataArray["Reservation ID"],
            PricingModel: dataArray["Pricing Model"],
            ReservedTime: dataArray["Reserved Time"],
            UsageTime: dataArray["Usage Time"],
            KilometerLimit: dataArray["Kilometer Limit"],
            RentalPrice: dataArray["Rental Price"],
            TripFee: dataArray["Trip Fee"],
            ExcessKMPrice: dataArray["Excess KM Price"],
            MileagePackage: dataArray["Mileage Package"],
            MileagePackagePrice: dataArray["Mileage Package Price"],
            UseCredits: dataArray["Use Credits"],
            starttime: dataArray.start_time,
            Route: dataArray.Route,
            Rating: dataArray.Rating || [],
            Device: dataArray.Device || [],
            Customer: dataArray.Customer || [],
            Vehicle: dataArray.Vehicle || [],
            TripStart: dataArray.TripStart || [],
            TripEnd: dataArray.TripEnd || [],
            endtime: dataArray.end_time,
            distance: dataArray.distance || dataArray.Distance,
            location: dataArray.location,
            isdev: dataArray.is_dev,
            reservationState: dataArray.reservationState,
            plate: dataArray.plate,
          },
        ];

        setData(combinedData);
      } catch (error) {
        console.error("Fetch error:", error);
        setError(error.message || "An unknown error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [reservationId]);

  return { data, loading, error };
};

export const useFeesData = (reservationId) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!reservationId) return;

      const apiEndpoint = `${BASE_URL}/fees?reservationId=${reservationId}`;
      const token = localStorage.getItem("token");
      if (!token) {
        setError("No token found");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(apiEndpoint, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          localStorage.removeItem("token");
          window.location.href = "/";
          return;
        }

        if (!response.ok) {
          throw new Error(
            `Network response was not ok: ${response.statusText}`,
          );
        }

        const result = await response.json();
        setData(result.records);
      } catch (error) {
        console.error("Fetch error:", error);
        setError(error.message || "An unknown error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [reservationId]);

  return { data, loading, error };
};

export const useRentals = (reservationId) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!reservationId) return;

      const apiEndpoint = `${BASE_URL}/rentals?reservationId=${reservationId}`;
      const token = localStorage.getItem("token");
      if (!token) {
        setError("No token found");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(apiEndpoint, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          localStorage.removeItem("token");
          window.location.href = "/";
          return;
        }

        if (!response.ok) {
          throw new Error(
            `Network response was not ok: ${response.statusText}`,
          );
        }

        const result = await response.json();
        setData(result.records);
      } catch (error) {
        console.error("Fetch error:", error);
        setError(error.message || "An unknown error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [reservationId]);

  return { data, loading, error };
};

export const useEndReservation = (reservationId) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const endReservation = async () => {
    if (!reservationId) return;

    const apiEndpoint = `${BASE_URL}/reservations/${reservationId}/end`;
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 401) {
        localStorage.removeItem("token");
        window.location.href = "/";
        return;
      }

      if (response.status === 403) {
        const result = await response.json();
        setError("You are not authorized to perform this action.");
        return { success: false, message: result.detail };
      }

      if (response.status === 202) {
        const result = await response.json();
        setError(result); // Store result data
        return { success: false, message: result.error }; // Return success message
      }

      if (response.ok) {
        const result = await response.json();
        setData(result);
        return { success: true, message: "Reservation ended successfully!" }; // Return success message
      } else {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Fetch error:", error);
      setError(error.message || "An unknown error occurred");
      return { error: error.message }; // Return error message
    } finally {
      setLoading(false);
    }
  };

  return { endReservation, loading, error, data };
};
