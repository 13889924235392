import { useState } from "react";

const BASE_URL = "https://jf8k6mp2m8.eu-west-1.awsapprunner.com";

export const useTriggerPayment = () => {
  const [data, setData] = useState(null); // Holds the API response data
  const [loading, setLoading] = useState(false); // Indicates the loading state
  const [error, setError] = useState(null); // Holds error details (message + color)

  const triggerPayment = async (invoiceId, paymentMethodIds) => {
    // Enhanced Validation

    if (
      typeof invoiceId !== "string" ||
      !Array.isArray(paymentMethodIds) ||
      paymentMethodIds.some((id) => typeof id !== "number")
    ) {
      setError({
        message:
          "Invalid input. Invoice ID must be a string, and Payment Method IDs must be an array of strings.",
        color: "#FFB74D", // Amber for warnings
      });
      return;
    }

    // Reset states before making the API call
    setLoading(true);
    setError(null);
    setData(null);

    // Retrieve token from localStorage
    const token = localStorage.getItem("token");
    if (!token) {
      setError({
        message: "Authentication token not found. Please log in again.",
        color: "#F44336", // Red for critical errors
      });
      setLoading(false);
      return;
    }

    const endpoint = `${BASE_URL}/invoices/trigger?invoice_id=${encodeURIComponent(
      invoiceId,
    )}&payment_method_id=${encodeURIComponent(paymentMethodIds[0])}`;

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      switch (response.status) {
        case 200:
          const result = await response.json();
          setData(result.message);
          break;
        case 400:
          const errorText = await response.json();
          setError({
            message: errorText.message || "Payment failed.",
            color: "#FF7043", // Orange for client-side errors
          });
          break;
        case 401:
          localStorage.removeItem("token");
          window.location.href = "/";
          break;
        case 403:
          setError({
            message: "You are not authorized to perform this action.",
            color: "#FF7043", // Orange for forbidden actions
          });
          break;
        default:
          const defaultErrorText = await response.text();
          throw new Error(
            `Error ${response.status}: ${
              defaultErrorText || response.statusText
            }`,
          );
      }
    } catch (err) {
      // Handle network errors or unexpected issues
      if (err.name === "TypeError" && !navigator.onLine) {
        setError({
          message: "Network error: Please check your internet connection.",
          color: "#FFEB3B", // Yellow for network issues
        });
      } else {
        setError({
          message: err.message || "An unexpected error occurred.",
          color: "#F44336", // Red for critical errors
        });
      }
    } finally {
      setLoading(false); // Ensure loading state is reset
    }
  };

  return { data, loading, error, triggerPayment };
};
