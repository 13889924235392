import { useState, useEffect } from "react";

const BASE_URL = "https://jf8k6mp2m8.eu-west-1.awsapprunner.com";

export const useReservationData = (page = 1, query) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      let apiEndpoint = `${BASE_URL}/reservations?page=${page}&${query}`;

      const token = localStorage.getItem("token");
      if (!token) {
        setError("No token found");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(apiEndpoint, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          localStorage.removeItem("token");
          window.location.href = "/";
          return;
        }

        if (!response.ok) {
          throw new Error(
            `Network response was not ok: ${response.statusText}`,
          );
        }

        const result = await response.json();
        const count = result.total_count || 0;
        setTotalCount(count);

        let dataArray = result.records;

        let combinedData = dataArray.map((item) => ({
          custID: Number(item.customer_id),
          reservationID: Number(item.reservation_id) || item["Reservation ID"],
          PricingModel: item["Pricing Model"],
          UsageTime: item["Usage Time"],
          KilometerLimit: item["Kilometer Limit"],
          RentalPrice: item["Rental Price"],
          TripFee: item["Trip Fee"],
          ExcessKMPrice: item["Excess KM Price"],
          MileagePackage: item["Mileage Package"],
          MileagePackagePrice: item["Mileage Package Price"],
          UseCredits: item["Use Credits"],
          starttime: item.start_time,
          Route: item.Route,
          Rating: item.Rating || [],
          Device: item.Device || [],
          Customer: item.Customer || [],
          Vehicle: item.Vehicle || [],
          TripStart: item.TripStart || [],
          TripEnd: item.TripEnd || [],
          endtime: item.end_time,
          distance: item.distance || item.Distance,
          location: item.location,
          isdev: item.is_dev,
          reservationState: item.reservationState,
          plate: item.plate,
        }));

        setData(combinedData);
        setTotalPages(result.total_pages ?? 1);
      } catch (error) {
        console.error("Fetch error:", error);
        setError(error.message || "An unknown error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [page, query]);

  return { data, loading, error, totalPages, totalCount };
};
