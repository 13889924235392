import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Popover, Typography, Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoidWRyaXZlLWFkbWluIiwiYSI6ImNtNmo5dWtoeTAycDkyanMwbXIxdmp2bDcifQ.-Xs-vhVm45stWCe0tUqPoA";

const ReservationMap = ({
  mapCenter,
  hasRoute,
  Route,
  startCoordinate,
  endCoordinate,
  startTime,
  endTime,
}) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popupData, setPopupData] = useState({
    title: "",
    time: "",
    color: "",
  });

  useEffect(() => {
    mapboxgl.accessToken = MAPBOX_TOKEN;

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: mapCenter,
      zoom: 14,
    });

    mapRef.current = map; // Store map instance in ref

    if (hasRoute) {
      map.on("load", () => {
        map.addSource("route", {
          type: "geojson",
          data: Route,
        });

        map.addLayer({
          id: "route-layer",
          type: "line",
          source: "route",
          paint: {
            "line-color": "#0056b2",
            "line-width": 4,
          },
        });
      });
    }

    const createMarker = (coordinates, title, time, color) => {
      const marker = new mapboxgl.Marker({ color })
        .setLngLat(coordinates)
        .addTo(map);

      marker.getElement().addEventListener("click", (event) => {
        event.stopPropagation(); // Prevent map from interfering
        setAnchorEl(event.currentTarget);
        setPopupData({ title, time, color });

        // 🔹 Keep focus on the map
        setTimeout(() => {
          mapRef.current?.getCanvas().focus();
        }, 100);
      });
    };

    if (startCoordinate) {
      createMarker(startCoordinate, "🚗 Start Point", startTime, "#228B22");
    }

    if (endCoordinate) {
      createMarker(endCoordinate, "🏁 End Point", endTime, "#FF0000");
    }

    return () => {
      map.remove();
    };
  }, [
    mapCenter,
    hasRoute,
    Route,
    startCoordinate,
    endCoordinate,
    startTime,
    endTime,
  ]);

  // Function to close popover
  const handleClose = () => {
    setAnchorEl(null);
  };

  // 🔹 Close popover when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (anchorEl && !anchorEl.contains(event.target)) {
        handleClose();
      }
    };

    if (anchorEl) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [anchorEl]);

  return (
    <>
      <div
        ref={mapContainerRef}
        style={{
          width: "100%",
          maxWidth: "100%", // Changed from 1200px to 100% to take full space of parent
          height: "500px", // Keep height as-is
          borderRadius: "10px",
        }}
      />

      {/* MUI Popover - Ensuring Map Zoom/Pan Works */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableRestoreFocus // 🔹 Prevents focus stealing
        disableScrollLock // 🔹 Allows scrolling & zooming
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{
          pointerEvents: "none", // 🔹 Allows interactions with the map
          ".MuiPaper-root": {
            pointerEvents: "auto", // 🔹 Ensures popover remains clickable
            borderRadius: "10px", // Rounded corners
            padding: "8px",
            boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.15)", // Soft shadow
            minWidth: "160px", // Small popover size
            textAlign: "center",
          },
        }}
      >
        {/* Close Button */}
        <IconButton
          size="small"
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 3,
            right: 3,
            color: "#666",
            "&:hover": { color: "#000" },
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>

        {/* Title */}
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "bold",
            color: popupData.color,
            mb: 0.5,
          }}
        >
          {popupData.title}
        </Typography>

        <Divider sx={{ mb: 0.5 }} />

        {/* Time Information */}
        <Typography variant="body2">
          <strong>Time:</strong> {popupData.time || "N/A"}
        </Typography>
      </Popover>
    </>
  );
};

export default ReservationMap;
