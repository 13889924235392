import { useState, useEffect } from "react";

const BASE_URL = "https://jf8k6mp2m8.eu-west-1.awsapprunner.com";

// Hook for fetching vehicle data
export const useVehicleData = (page = 1, query) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("No token found");
        setLoading(false);
        return;
      }

      const endpoint = `${BASE_URL}/vehicles?page=${page}&${query}`;

      try {
        setLoading(true);
        const response = await fetch(endpoint, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          localStorage.removeItem("token");
          window.location.href = "/";
          return;
        }

        if (response.status === 403) {
          setError("You are not authorized to access this page");
          return;
        }

        if (!response.ok) {
          throw new Error(
            `Failed to fetch ${response.url}: ${response.statusText}`,
          );
        }

        const result = await response.json();
        const count = result.total_count || 0;
        setTotalCount(count);
        setData(result.records || []);
        setTotalPages(result.total_pages ?? 1);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setError(error.message || "An unknown error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [page, query]);

  return { data, loading, error, totalPages, totalCount };
};

// Hook for fetching reservation history
export const useReservationHistory = (vehicleId, page = 1) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    // Fetch only if vehicleId is valid
    if (!vehicleId) {
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("No token found");
        setLoading(false);
        return;
      }

      const endpoint = `${BASE_URL}/reservations?vehicleId=${vehicleId}&page=${page}`;

      try {
        setLoading(true);
        const response = await fetch(endpoint, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          localStorage.removeItem("token");
          window.location.href = "/";
          return;
        }

        if (response.status === 403) {
          setError("You are not authorized to access this page");
          return;
        }

        if (!response.ok) {
          throw new Error(
            `Failed to fetch ${response.url}: ${response.statusText}`,
          );
        }

        const result = await response.json();
        const count = result.total_count || 0;
        setTotalCount(count);
        setData(result.records || []);
        setTotalPages(result.total_pages ?? 1);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setError(error.message || "An unknown error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [vehicleId, page]); // Depend on `vehicleId` and `page`

  return { data, loading, error, totalPages, totalCount };
};

// Hook for searching vehicle data
export const useSearchVehicleData = (searchParams = {}, page = 1) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(
    () => {
      // If there are no search parameters, do not fetch
      if (!Object.keys(searchParams).length) {
        setLoading(false);
        return;
      }

      const fetchData = async () => {
        const token = localStorage.getItem("token");
        if (!token) {
          setError("No token found");
          setLoading(false);
          return;
        }

        const filteredParams = removeEmptyParams({ ...searchParams, page });

        const params = new URLSearchParams(filteredParams);

        try {
          setLoading(true);
          const response = await fetch(
            `${BASE_URL}/vehicles?${params.toString()}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );

          if (response.status === 401) {
            localStorage.removeItem("token");
            window.location.href = "/";
            return;
          }

          if (!response.ok) {
            throw new Error(`Failed to fetch: ${response.statusText}`);
          }

          const result = await response.json();
          const count = result.total_count || 0;
          setTotalCount(count);
          setData(result.records || []);
          setTotalPages(result.total_pages ?? 1);
        } catch (error) {
          console.error("Failed to fetch data:", error);
          setError(error.message || "An unknown error occurred");
        } finally {
          setLoading(false);
        }
      };

      const removeEmptyParams = (params) => {
        return Object.fromEntries(
          Object.entries(params).filter(([_, value]) => value !== ""),
        );
      };

      fetchData();
    },
    // eslint-disable-next-line
    [JSON.stringify(searchParams), page],
  ); // Stringify searchParams to ensure accurate dependency comparison

  return { data, loading, error, totalPages, totalCount };
};

// Separate search functions
export const searchByHardwareId = (hardware_id) => ({ hardware_id });
export const searchByVehicleId = (vehicle_id) => ({ vehicle_id });
export const searchByLicencePlate = (licence_plate) => ({ licence_plate });
export const searchByTitle = (title) => ({ title });
export const searchByLocation = (location) => ({ location });
export const searchByRentalType = (rental_type) => ({ rental_type });

export default useVehicleData; // Ensure default export for useVehicleData
