import { useState } from "react";

const BASE_URL = "https://jf8k6mp2m8.eu-west-1.awsapprunner.com";

export const useEditFunnels = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const editFunnels = async (
    customerId,
    phoneVerified,
    emailVerified,
    funnelPersonalInfo,
    funnelPayment,
    funnelLicenseImage,
  ) => {
    // Reset states at the beginning of the call
    setLoading(true);
    setError(null);
    setSuccess(false);

    const token = localStorage.getItem("token");
    if (!token) {
      const authError = "Authentication token not found. Please log in.";
      setLoading(false);
      setError(authError);
      throw new Error(authError); // Ensure error is thrown
    }

    if (!customerId) {
      const idError =
        "Customer ID is missing. Cannot proceed with the API call.";
      setLoading(false);
      setError(idError);
      throw new Error(idError); // Throw an error if customerId is missing
    }

    try {
      const endpoint = `${BASE_URL}/customers/${customerId}/editFunnels`;
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phoneVerified,
          emailVerified,
          funnelPersonalInfo,
          funnelPayment,
          funnelLicenseImage,
        }),
      });

      if (response.status === 200) {
        setSuccess(true);
      } else if (response.status === 401) {
        const errorMessage = "Unauthorized access. Please log in again.";
        setError(errorMessage);
        throw new Error(errorMessage); // Throw the error
      } else if (response.status === 403) {
        const errorMessage = await response.json();
        setError(errorMessage.detail);
        throw new Error(errorMessage.detail); // Throw the error
      } else {
        const errorData = await response.json();
        const errorMessage = errorData.message || "An error occurred.";
        setError(errorMessage);
        throw new Error(errorMessage); // Throw the error
      }
    } catch (err) {
      const networkError = err.message || "A network error occurred.";
      setError(networkError);
      throw new Error(networkError); // Ensure the error is thrown
    } finally {
      setLoading(false);
    }
  };

  return { editFunnels, loading, error, success };
};
