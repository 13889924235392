import { useState } from "react";

const BASE_URL = "https://jf8k6mp2m8.eu-west-1.awsapprunner.com";

export const useInvoiceStateChange = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const changeState = async (invoiceID, status) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    const token = localStorage.getItem("token");
    if (!token) {
      setLoading(false);
      const authError = "Authentication token not found. Please log in.";
      setError(authError);
      throw new Error(authError); // Throw the error for the component to handle
    }

    try {
      const endpoint = `${BASE_URL}/invoices/changeState?invoice_id=${invoiceID}&status=${status}`;
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        setSuccess(true);
      } else {
        let errorMessage = "An error occurred";
        if (response.status === 401) {
          errorMessage = "Unauthorized access. Please log in again.";
        } else if (response.status === 403) {
          errorMessage = "You do not have permission to perform this action.";
        } else {
          try {
            const errorData = await response.json();
            errorMessage = errorData.message || errorMessage;
          } catch {
            // If response.json() fails, keep the fallback message.
          }
        }
        setError(errorMessage);
        throw new Error(errorMessage); // Throw the error for the component to handle
      }
    } catch (err) {
      const networkError =
        err.message || "A network error occurred. Please try again.";
      setError(networkError);
      throw new Error(networkError); // Throw the error for the component to handle
    } finally {
      setLoading(false);
    }
  };

  return { changeState, loading, error, success };
};
