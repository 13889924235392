import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import "./rolepage.css";
import { useRoleData } from "./data/roledata";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
  Snackbar,
  TextField,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MuiAlert from "@mui/material/Alert";
import scopeoptions from "./scope options/scopeoption";
import Autocomplete from "@mui/material/Autocomplete";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import CircularProgress from "@mui/material/CircularProgress";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RolePage = () => {
  const { users, loading, error, createUser, updateUser, deleteUser } =
    useRoleData();
  const [selectedUser, setSelectedUser] = useState(null);
  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [newUserDialogOpen, setNewUserDialogOpen] = useState(false);
  const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] =
    useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [newUser, setNewUser] = useState({
    full_name: "",
    email: "",
    password: "",
    scopes: [],
  });
  const [editUser, setEditUser] = useState({
    full_name: "",
    email: "",
    scopes: [],
  });

  const [showContent, setShowContent] = useState(false);
  useEffect(() => {
    setShowContent(true); // Trigger the animation when the component is mounted
  }, []);

  const handleRowClick = (user) => {
    setSelectedUser(user);
    setUserDialogOpen(true);
  };

  const handleUserDialogClose = () => {
    setSelectedUser(null);
    setUserDialogOpen(false);
  };

  const handleNewUserDialogClose = () => {
    setNewUserDialogOpen(false);
  };

  const handleEditUserDialogClose = () => {
    setEditUserDialogOpen(false);
    setEditUser({ full_name: "", email: "", scopes: [] });
  };

  const handleDeleteConfirmationDialogClose = () => {
    setDeleteConfirmationDialogOpen(false);
    setSelectedUser(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleNewUserChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditUserChange = (e) => {
    const { name, value } = e.target;
    setEditUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreateUser = async () => {
    try {
      await createUser(newUser);
      setSnackbarMessage("User created successfully!");
      setSnackbarOpen(true);
      setNewUser({ full_name: "", email: "", password: "", scopes: [] });
      handleNewUserDialogClose();
      window.location.reload(); // Reload the page
    } catch (error) {
      setSnackbarMessage(error.message);
      setSnackbarOpen(true);
    }
  };

  const handleEditUser = async () => {
    try {
      await updateUser(selectedUser.id, editUser);
      setSnackbarMessage("User updated successfully!");
      setSnackbarOpen(true);
      handleEditUserDialogClose();
      window.location.reload(); // Reload the page
    } catch (error) {
      setSnackbarMessage(error.message);
      setSnackbarOpen(true);
    }
  };

  const handleEditUserDialogOpen = () => {
    setEditUserDialogOpen(true);
  };

  const handleDeleteUserDialogOpen = (user) => {
    setSelectedUser(user);
    setDeleteConfirmationDialogOpen(true);
  };

  const handleDeleteUser = async () => {
    if (selectedUser) {
      try {
        await deleteUser(selectedUser.username);
        setSnackbarMessage("User deleted successfully!");
        setSnackbarOpen(true);
        handleDeleteConfirmationDialogClose();
        window.location.reload(); // Reload the page
      } catch (error) {
        setSnackbarMessage(error.message);
        setSnackbarOpen(true);
      }
    }
  };

  const handleUserSelectChange = (event, user) => {
    if (user) {
      setSelectedUser(user);
      setEditUser({
        full_name: user.full_name,
        email: user.email,
        scopes: user.scopes,
      });
    } else {
      setSelectedUser(null);
      setEditUser({ full_name: "", email: "", scopes: [] });
    }
  };

  // Inside the RolePage component
  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <div style={{ color: "red" }}>{error}</div>;
  }

  return (
    <CSSTransition
      in={showContent}
      timeout={500} // Duration of the animation in ms
      classNames="slide-down"
      unmountOnExit
    >
      <div className="role-page-content" style={{ padding: "20px" }}>
        <Box sx={{ mt: 4, p: 2 }}>
          <Typography
            variant="h4"
            gutterBottom
            style={{ fontWeight: "bold", textTransform: "uppercase" }}
          >
            ROLE ACCESS
          </Typography>

          <Box display="flex" justifyContent="flex-end" mb={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setNewUserDialogOpen(true)}
              sx={{ mr: 2 }}
            >
              <AddIcon fontSize="small" />
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={handleEditUserDialogOpen}
              sx={{ mr: 2 }}
            >
              <CreateOutlinedIcon fontSize="small" />
            </Button>
          </Box>

          {/* Table For The User Data */}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      width: "26%",
                    }}
                  >
                    Full Name
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      width: "26%",
                    }}
                  >
                    User Name
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      width: "26%",
                    }}
                  >
                    Title
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      width: "26%",
                      display: "none",
                    }}
                  >
                    Disabled
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      width: "26%",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users
                  .sort((a, b) => a.full_name.localeCompare(b.full_name)) // Sort by full_name in ascending order
                  .map((user) => {
                    const isDisabled = user.disabled;

                    return (
                      <TableRow
                        key={user.id}
                        onClick={() => !isDisabled && handleRowClick(user)}
                        style={{
                          cursor: isDisabled ? "not-allowed" : "pointer",
                          backgroundColor: "white",
                        }}
                      >
                        <TableCell>{user.full_name}</TableCell>
                        <TableCell>{user.username}</TableCell>
                        <TableCell>{user.title}</TableCell>
                        <TableCell style={{ display: "none" }}>
                          {isDisabled ? "True" : "False"}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            variant="contained"
                            color="primary"
                            onClick={(event) => {
                              event.stopPropagation(); // Prevent the row click event
                              if (!isDisabled) {
                                handleDeleteUserDialogOpen(user); // Pass user to the delete dialog handler
                              }
                            }}
                            disabled={isDisabled} // Disable the button if user is disabled
                          >
                            <PersonRemoveOutlinedIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* User Scopes Dialog */}
          <Dialog open={userDialogOpen} onClose={handleUserDialogClose}>
            <DialogTitle>User Scopes</DialogTitle>
            <DialogContent>
              {selectedUser && (
                <Box display="flex" flexWrap="wrap">
                  {selectedUser.scopes.map((scope, index) => (
                    <Box
                      key={index}
                      bgcolor="#011f4b"
                      color="white"
                      margin={1}
                      padding={1}
                      borderRadius={2}
                    >
                      {scope}
                    </Box>
                  ))}
                </Box>
              )}
            </DialogContent>
          </Dialog>

          {/* Create User Dialog */}
          <Dialog
            open={newUserDialogOpen}
            onClose={handleNewUserDialogClose}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>Create New User</DialogTitle>
            <DialogContent dividers>
              <TextField
                margin="dense"
                label="Full Name"
                type="text"
                fullWidth
                name="full_name"
                value={newUser.full_name}
                onChange={handleNewUserChange}
              />
              <TextField
                margin="dense"
                label="Email"
                type="email"
                fullWidth
                name="email"
                value={newUser.email}
                onChange={handleNewUserChange}
              />
              <TextField
                margin="dense"
                label="Password"
                type="password"
                fullWidth
                name="password"
                value={newUser.password}
                onChange={handleNewUserChange}
              />

              <Typography variant="subtitle1" gutterBottom>
                Scopes
              </Typography>

              <Autocomplete
                multiple
                options={scopeoptions}
                value={newUser.scopes}
                onChange={(event, newValue) => {
                  setNewUser((prev) => ({ ...prev, scopes: newValue }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Scopes"
                  />
                )}
                style={{ marginBottom: "16px" }}
              />

              <Button
                onClick={handleCreateUser}
                variant="contained"
                color="primary"
                style={{ width: "100%" }}
              >
                Create User
              </Button>
            </DialogContent>
          </Dialog>

          {/* Edit User Dialog */}
          <Dialog
            open={editUserDialogOpen}
            onClose={handleEditUserDialogClose}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>Edit User</DialogTitle>
            <DialogContent dividers>
              <Autocomplete
                options={users}
                getOptionLabel={(option) => option.full_name}
                onChange={handleUserSelectChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select User"
                    variant="outlined"
                  />
                )}
                style={{ marginBottom: "16px" }}
              />

              <TextField
                margin="dense"
                label="Full Name"
                type="text"
                fullWidth
                name="full_name"
                value={editUser.full_name}
                onChange={handleEditUserChange}
              />
              <TextField
                margin="dense"
                label="Email"
                type="email"
                fullWidth
                name="email"
                value={editUser.email}
                onChange={handleEditUserChange}
                disabled // Disable the email input
              />

              <Typography variant="subtitle1" gutterBottom>
                Scopes
              </Typography>

              <Autocomplete
                multiple
                options={scopeoptions}
                value={editUser.scopes}
                onChange={(event, newValue) => {
                  setEditUser((prev) => ({ ...prev, scopes: newValue }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Scopes"
                  />
                )}
                style={{ marginBottom: "16px" }}
              />

              <Button
                onClick={handleEditUser}
                variant="contained"
                color="primary"
                style={{ width: "100%" }}
              >
                Update User
              </Button>
            </DialogContent>
          </Dialog>

          {/* Delete Confirmation Dialog */}
          <Dialog
            open={deleteConfirmationDialogOpen}
            onClose={handleDeleteConfirmationDialogClose}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to delete the user{" "}
                {selectedUser ? selectedUser.full_name : ""}?
              </Typography>
              <Button
                onClick={handleDeleteUser}
                variant="contained"
                color="primary"
                style={{ width: "100%", marginTop: "16px" }}
              >
                Confirm Delete
              </Button>
              <Button
                onClick={handleDeleteConfirmationDialogClose}
                variant="outlined"
                color="secondary"
                style={{ width: "100%", marginTop: "8px" }}
              >
                Cancel
              </Button>
            </DialogContent>
          </Dialog>

          {/* Snack Bar Messages */}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={error ? "error" : "success"}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Box>
      </div>
    </CSSTransition>
  );
};

export default RolePage;
