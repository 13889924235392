import { useState, useEffect, useCallback } from "react";

const BASE_URL = "https://jf8k6mp2m8.eu-west-1.awsapprunner.com";

export const useStateHistory = (vehicleID, page = 1) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  // Wrap the fetch logic in useCallback
  const fetchStateHistory = useCallback(async () => {
    if (!vehicleID) {
      setData([]);
      setTotalPages(1);
      setTotalCount(0);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("Authentication token not found.");
        setData([]);
        setLoading(false);
        return;
      }

      const response = await fetch(
        `${BASE_URL}/states/history?vehicleId=${vehicleID}&page=${page}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      );

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.message || "Failed to fetch state history.");
        setData([]);
      } else {
        const result = await response.json();
        setData(result.records || []);
        setTotalPages(result.total_pages || 1);
        setTotalCount(result.total_count || 0);
      }
    } catch (err) {
      setError("An error occurred while fetching data.");
      setData([]);
    } finally {
      setLoading(false);
    }
  }, [vehicleID, page]); // Add dependencies for vehicleID and page

  useEffect(() => {
    fetchStateHistory();
  }, [fetchStateHistory]); // Add fetchStateHistory as a dependency

  const refetchStateHistory = () => {
    fetchStateHistory();
  };

  return {
    data,
    loading,
    error,
    totalPages,
    totalCount,
    refetchStateHistory,
  };
};
