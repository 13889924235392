import React from "react";
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Chip,
} from "@mui/material";

const ReleaseNotes = () => {
  const releases = [
    {
      version: "3.2.5",
      date: "March 28, 2025",
      features: [
        "📦 Layout Improvements:",
        "- Redesigned the page layout with a more intuitive card-based structure.",
        "- Ensured all sections adapt responsively for both desktop and mobile views.",
        "- Improved padding, spacing, and font weights for better visual hierarchy.",

        "📊 Rental Overview:",
        "- Renamed the section from 'Details' to 'Rental Overview'.",
        "- Reordered fields for clarity:",
        "     1. Pricing Model",
        "     2. Trip Fee",
        "     3. Rental Price",
        "     4. Kilometer Limit",
        "     5. Mileage Package",
        "     6. Mileage Package Price",
        "     7. Excess KM Price",
        "     8. Use Credits",

        "👤 Customer & 🚗 Vehicle Information:",
        "- Separated into two side-by-side cards.",
        "- Ensured consistent sizing and spacing between the cards.",
        "- Added icons and clickable links for quick navigation.",

        "🚦 Trip Start & Trip End:",
        "- Displayed both in a side-by-side layout.",
        "- Organized data fields clearly under each:",
        "   • Start: Reserved Time, Start Time, Open Time, Mileage, Fuel, Address",
        "   • End: End Time, Mileage, Distance, Usage Time, Fuel Level, Address",
        "- Ensured uniform icon placement and alignment throughout.",

        "⭐ Ratings:",
        "- Displayed in a separate full-width card.",
        "- Trip Rating and Review are separated with clear spacing.",
        "- Handles longer review content without layout issues.",
      ],
    },
    {
      version: "3.2.4",
      date: "March 26, 2025",
      features: [
        "• Fixed a UI issue on the Verifications page",
        "• Added category and reason fields to the Credits page",
      ],
    },
    {
      version: "3.2.3",
      date: "March 26, 2025",
      features: [
        "• New packages have been added.",
        "• A new Damage Reports page has been added, along with a sidebar link for easy access.",
        "• The Verifications tab on the Customers page has been updated with an improved photo viewer and more compatible spacing.",
      ],
    },
    {
      version: "3.2.2",
      date: "March 19, 2025",
      features: [
        "• Added Damage Reports tab to the Vehicle page",
        "• Added a Release Notes page",
        "• Added Release Notes to the sidebar, accessible via an icon button",
      ],
    },
  ];

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          minHeight: "100vh",
          py: 6,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" fontWeight="bold" gutterBottom>
          Release Notes
        </Typography>

        <Box display="flex" flexDirection="column" gap={4} width="100%">
          {releases.map((release, index) => (
            <Card
              key={index}
              elevation={6}
              sx={{
                borderRadius: 3,
                p: 3,
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "scale(1.02)",
                  boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.15)",
                },
              }}
            >
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <Typography variant="h6" fontWeight="bold">
                    Version {release.version}
                  </Typography>
                  <Chip
                    label={release.date}
                    sx={{
                      backgroundColor: "black",
                      color: "white",
                      fontWeight: "bold",
                      borderRadius: 2,
                      fontSize: "0.8rem",
                    }}
                  />
                </Box>

                <Box component="ul" sx={{ pl: 0, listStyle: "none" }}>
                  {release.features.map((feature, idx) => {
                    const isHeader =
                      !/^(\s*(-|\*|\d+\.)|\s*)/.test(feature) &&
                      feature.trim().endsWith(":");

                    return (
                      <li key={idx} style={{ marginBottom: "0.5rem" }}>
                        {isHeader ? (
                          <Typography
                            variant="subtitle1"
                            fontWeight="bold"
                            sx={{ mb: 1, fontSize: "1rem" }}
                          >
                            {feature}
                          </Typography>
                        ) : (
                          <Typography
                            variant="body1"
                            sx={{
                              pl: 2,
                              fontWeight:
                                /^\d+\./.test(feature.trim()) ||
                                feature.trim().startsWith("*")
                                  ? 600
                                  : 400,
                              whiteSpace: "pre-wrap",
                              fontSize: "0.95rem",
                            }}
                          >
                            {feature}
                          </Typography>
                        )}
                      </li>
                    );
                  })}
                </Box>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default ReleaseNotes;
