import { useState, useEffect } from "react";

const BASE_URL = "https://jf8k6mp2m8.eu-west-1.awsapprunner.com";

export const useHardwareHistory = (hardwareId, page = 1) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const fetchHardwareHistory = async () => {
      if (!hardwareId) {
        setData([]);
        setTotalPages(1);
        setTotalCount(0);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const token = localStorage.getItem("token");
        if (!token) {
          setError("Authentication token not found.");
          setLoading(false);
          return;
        }

        const response = await fetch(
          `${BASE_URL}/hardware/history?hardwareId=${hardwareId}&page=${page}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          },
        );

        if (!response.ok) {
          const errorData = await response.json();
          setError(errorData.message || "Failed to fetch hardware history.");
        } else {
          const result = await response.json();
          setData(result.records || []);
          setTotalPages(result.total_pages || 1);
          setTotalCount(result.total_count || 0);
        }
      } catch (err) {
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchHardwareHistory();
  }, [hardwareId, page]);

  return { data, loading, error, totalPages, totalCount };
};
