import { useState, useEffect } from "react";

const BASE_URL = "https://jf8k6mp2m8.eu-west-1.awsapprunner.com";

export const useParkingData = (vehicleID, page = 1) => {
  const [data, setData] = useState(null); // For single object response
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1); // Default to 1 page
  const [totalCount, setTotalCount] = useState(0); // Default to 0 count

  useEffect(() => {
    const fetchData = async () => {
      if (!vehicleID) return;

      const token = localStorage.getItem("token");
      if (!token) {
        setError("No token found. Please log in again.");
        return;
      }

      const endpoint = `${BASE_URL}/parkings?vehicle_id=${vehicleID}&page=${page}`;
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(endpoint, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          // Unauthorized: Token is invalid or expired
          localStorage.removeItem("token"); // Clear the token
          setError("Your session has expired. Please log in again.");
          window.location.href = "/"; // Redirect to login page
          return;
        }

        if (response.status === 403) {
          // Forbidden: User does not have access
          setError("You do not have permission to access this resource.");
          return;
        }

        if (!response.ok) {
          throw new Error(
            `Failed to fetch data from ${response.url}: ${response.statusText}`,
          );
        }

        const result = await response.json();

        // Store data, and fallback for totalPages and totalCount
        setData(result.records);
        setTotalPages(result.total_pages ?? 1);
        setTotalCount(result.total_count ?? 0);
      } catch (error) {
        console.error("Failed to fetch data:", error); // Optional logging
        setError(
          error.message || "An unknown error occurred. Please try again.",
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [vehicleID, page]);

  return { data, loading, error, totalPages, totalCount };
};
