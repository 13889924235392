import { useState, useEffect, useCallback } from "react";

const BASE_URL = "https://jf8k6mp2m8.eu-west-1.awsapprunner.com";

const useVerificationData = (customerId) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateData = (index, updatedFields) => {
    setData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        ...updatedFields,
      };
      return updatedData;
    });
  };

  // Memoize the fetchVerificationData function using useCallback
  const fetchVerificationData = useCallback(async () => {
    if (!customerId) return;

    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${BASE_URL}/verifications?customerId=${customerId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      );

      if (response.status === 401) {
        setError("Unauthorized access. Please log in again.");
        return;
      }

      if (response.status === 403) {
        setError("Forbidden access. You do not have permission.");
        return;
      }

      if (!response.ok) {
        throw new Error("Failed to fetch verification data.");
      }

      const responseData = await response.json();

      const transformedData = [
        {
          verificationState: responseData.verificationState || "Pending",
          funnel_license_image: responseData.funnel_license_image || false,
          drivers_license_id: responseData.drivers_license_id,
          drivers_license_issue_date: responseData.drivers_license_issue_date,
          drivers_license_expiration_date:
            responseData.drivers_license_expiration_date,
          personal_id: responseData.personal_id,
          personal_id_issue: responseData.personal_id_issue,
          personal_id_expiration: responseData.personal_id_expiration,
          nationality: responseData.nationality,
          customer_type: responseData.customer_type,
          images: responseData.urls || [],
          rejectionReason: responseData.rejectionReason || [],
        },
      ];

      setData(transformedData);
    } catch (err) {
      setError(err.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  }, [customerId]);

  useEffect(() => {
    fetchVerificationData();
  }, [customerId, fetchVerificationData]);

  const postVerificationData = async (updatedVerificationData) => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${BASE_URL}/verifications?customerId=${customerId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedVerificationData),
        },
      );

      if (response.status === 401) {
        setError("Unauthorized access. Please log in again.");
        return;
      }

      if (response.status === 403) {
        setError("Forbidden access. You do not have permission.");
        return;
      }

      if (!response.ok) {
        throw new Error("Failed to post verification data.");
      }

      return await response.json();
    } catch (err) {
      setError(err.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, postVerificationData, updateData };
};

export default useVerificationData;
