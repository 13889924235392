/* eslint-disable no-unused-vars */
import { useState, useEffect, useCallback } from "react";

const BASE_URL = "https://jf8k6mp2m8.eu-west-1.awsapprunner.com";

export const useHardwareData = (vehicleId) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      setLoading(false);
      return;
    }

    const endpoint = `${BASE_URL}/vehicles/hardware?vehicleId=${vehicleId}`;

    try {
      setLoading(true);
      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        localStorage.removeItem("token");
        window.location.href = "/";
        return;
      }

      if (response.status === 403) {
        setError("You are not authorized to access the hardware data");
        setLoading(false);
        return;
      }

      if (!response.ok) {
        throw new Error(
          `Failed to fetch ${response.url}: ${response.statusText}`,
        );
      }

      const result = await response.json();
      setData(result || []);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setError(error.message || "An unknown error occurred");
    } finally {
      setLoading(false);
    }
  }, [vehicleId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Function to update hardware data
  const updateHardwareData = async (hardwareId, updateData) => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      return;
    }

    const endpoint = `${BASE_URL}/vehicles/hardware`;

    try {
      const response = await fetch(endpoint, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updateData),
      });

      if (response.status === 401) {
        localStorage.removeItem("token");
        window.location.href = "/";
        return;
      }

      if (response.status === 403) {
        setError("You are not authorized to update the hardware ID");
        return;
      }

      if (!response.ok) {
        throw new Error(
          `Failed to update ${response.url}: ${response.statusText}`,
        );
      }

      const result = await response.json();
      // Optionally refresh data after update
      fetchData();
    } catch (error) {
      console.error("Failed to update data:", error);
      setError(error.message || "An unknown error occurred");
    }
  };

  // Function to delete hardware data
  const deleteHardwareData = async (deleteData) => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      return;
    }

    const endpoint = `${BASE_URL}/vehicles/hardware`;

    try {
      const response = await fetch(endpoint, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(deleteData),
      });

      if (response.status === 401) {
        localStorage.removeItem("token");
        window.location.href = "/";
        return;
      }

      if (response.status === 403) {
        setError("You are not authorized to delete the hardware ID");
        return;
      }

      if (!response.ok) {
        throw new Error(
          `Failed to delete ${response.url}: ${response.statusText}`,
        );
      }

      // Refresh data after delete
      await fetchData();
    } catch (error) {
      console.error("Failed to delete data:", error);
      setError(error.message || "An unknown error occurred");
    }
  };

  return { data, loading, error, updateHardwareData, deleteHardwareData };
};
