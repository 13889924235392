import { useState, useEffect } from "react";

const BASE_URL = "https://jf8k6mp2m8.eu-west-1.awsapprunner.com";

export const useDamageReportsData = (vehicleID, page = 1) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (!vehicleID) {
      setData(null);
      setTotalPages(1);
      setTotalCount(0);
      return;
    }

    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("No token found. Please log in again.");
        setData(null);
        return;
      }

      const endpoint = `${BASE_URL}/damage-reports?vehicleId=${vehicleID}&page=${page}`;
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(endpoint, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          localStorage.removeItem("token");
          setError("Your session has expired. Please log in again.");
          window.location.href = "/"; // Redirect to login
          return;
        }

        if (response.status === 403) {
          setError("You do not have permission to access this resource.");
          return;
        }

        if (!response.ok) {
          throw new Error(
            `Failed to fetch data from ${response.url}: ${response.statusText}`,
          );
        }

        const result = await response.json();
        setData(result.records || []);
        setTotalPages(result.total_pages ?? 1);
        setTotalCount(result.total_count ?? 0);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setError(
          error.message || "An unknown error occurred. Please try again.",
        );
        setData(null);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [vehicleID, page]);

  return { data, loading, error, totalPages, totalCount };
};
