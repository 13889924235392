import React, { useRef, useEffect, useMemo, useState } from "react";
import mapboxgl from "mapbox-gl";
import "tippy.js/dist/tippy.css";
import { Box, Typography } from "@mui/material";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
// Data
import { useStatsData } from "../data/stats";
import { useVehiclePoints } from "../data/vehiclespoints";

/** Your Mapbox Access Token **/
mapboxgl.accessToken =
  "pk.eyJ1IjoidWRyaXZlLWFkbWluIiwiYSI6ImNtNmo5cnY0ZjAxd2Eya3M1eGtkZ3BtYmsifQ.P1ePncOUVDh9r0gXb1uXig";

const VehiclesMap = () => {
  const mapContainerRef = useRef(null);
  const mapInstanceRef = useRef(null);
  const defaultCenter = useMemo(() => [53.9994829, 24.0002488], []);
  const defaultZoom = 7;

  // ✅ Store stats in state
  const [stats, setStats] = useState(null);
  const { transformedData, loading } = useStatsData();
  const { transformedData: PointsData } = useVehiclePoints();

  // ✅ Fetch new stats every 2 minutes without reloading the page
  useEffect(() => {
    setStats(transformedData); // Initial data update

    const interval = setInterval(() => {
      setStats((prevStats) => ({ ...prevStats, ...transformedData })); // Update only the circular progress bar
    }, 120000); // Refresh every 2 minutes

    return () => clearInterval(interval);
  }, [transformedData]);

  // ✅ Initialize the Mapbox map
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: defaultCenter,
      zoom: defaultZoom,
    });

    mapInstanceRef.current = map;

    // ✅ Add vehicle points as small dots when data is available
    if (PointsData && Array.isArray(PointsData)) {
      const geojson = {
        type: "FeatureCollection",
        features: PointsData.map((vehicle) => ({
          type: "Feature",
          properties: {
            vehicle_id: vehicle.vehicle_id,
            vehicle_state_id: vehicle.vehicle_state_id,
            timestamp: new Date().toISOString().split(".")[0].replace("T", " "), // Current Time (format: YYYY-MM-DD HH:MM:SS)
          },
          geometry: {
            type: "Point",
            coordinates: [vehicle.lon, vehicle.lat],
          },
        })),
      };

      map.on("load", () => {
        // Add source for vehicle dots
        if (!map.getSource("vehicle-points")) {
          map.addSource("vehicle-points", {
            type: "geojson",
            data: geojson,
          });

          // Add layer for vehicle dots
          map.addLayer({
            id: "vehicle-dots",
            type: "circle",
            source: "vehicle-points",
            paint: {
              "circle-radius": 5, // Small dot size
              "circle-color": "#FF0000", // Red color for visibility
              "circle-stroke-width": 1,
              "circle-stroke-color": "#FFFFFF", // White stroke for contrast
            },
          });

          // ✅ Click event to show styled popup with vehicle details
          map.on("click", "vehicle-dots", (e) => {
            const coordinates = e.features[0].geometry.coordinates;
            const { vehicle_id, vehicle_state_id } = e.features[0].properties;

            new mapboxgl.Popup({ closeOnClick: true })
              .setLngLat(coordinates)
              .setHTML(
                `
                <div style="font-family: Arial, sans-serif; padding: 6px 12px; width: 160px; border-radius: 4px;">
                  <p style="margin: 2px 0; font-size: 12px; text-align: left;">
                    <strong>Vehicle ID:</strong> ${vehicle_id}
                  </p>
                  <p style="margin: 2px 0 0 0; font-size: 12px; text-align: left;">
                    <strong>State ID:</strong> ${vehicle_state_id}
                  </p>
                </div>
              `,
              )
              .addTo(map);
          });

          // ✅ Change cursor on hover
          map.on("mouseenter", "vehicle-dots", () => {
            map.getCanvas().style.cursor = "pointer";
          });
          map.on("mouseleave", "vehicle-dots", () => {
            map.getCanvas().style.cursor = "";
          });
        }
      });
    }

    return () => map.remove(); // Cleanup map instance on unmount
  }, [defaultCenter, defaultZoom, PointsData]);

  const handleZoomIn = () => mapInstanceRef.current?.zoomIn();
  const handleZoomOut = () => mapInstanceRef.current?.zoomOut();

  const formatNumber = (num) => (num ? num.toLocaleString() : "0");

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* Map Container */}
      <Box
        sx={{
          position: "relative",
          width: "calc(90% - 40px)",
          height: "65%",
          borderRadius: 2,
          overflow: "hidden",
          boxShadow: 1,
          marginBottom: "2",
          marginTop: "20px",
        }}
      >
        <Box
          ref={mapContainerRef}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />

        {/* Custom Zoom Buttons */}
        <Box
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <button
            onClick={handleZoomIn}
            style={{
              fontSize: "24px",
              width: "40px",
              height: "40px",
              marginBottom: "5px",
              border: "none",
              borderRadius: "4px",
              backgroundColor: "#ffffff",
              cursor: "pointer",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            }}
          >
            +
          </button>
          <button
            onClick={handleZoomOut}
            style={{
              fontSize: "24px",
              width: "40px",
              height: "40px",
              border: "none",
              borderRadius: "4px",
              backgroundColor: "#ffffff",
              cursor: "pointer",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            }}
          >
            −
          </button>
        </Box>
      </Box>

      {/* Bottom Section - Stats Boxes with Circular Progress Bars */}
      <Box sx={{ flex: 1, width: "calc(90% - 40px)", marginTop: "20px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "15px",
            marginTop: "15px",
          }}
        >
          {[
            {
              title: "Running Reservations",
              value: loading ? 0 : (stats?.running_reservations ?? 0),
              color: "#3f51b5",
            },
            {
              title: "Active Vehicles",
              value: loading ? 0 : (stats?.active_cars ?? 0),
              color: "#4caf50",
            },
            {
              title: "Fees Collected",
              value: loading ? 0 : stats?.total_fee / 1000,
              text: loading ? "..." : `AED ${formatNumber(stats?.total_fee)}`,
              color: "#f50057",
            },
          ].map(({ title, value, text, color }, index) => (
            <Box
              key={index}
              sx={{
                flex: 1,
                padding: "12px",
                backgroundColor: "#ffffff",
                borderRadius: "6px",
                boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                border: "1.5px solid #ccc",
                textAlign: "center",
                minWidth: "120px",
                height: "160px", // Fixed height to keep layout consistent
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", marginBottom: "8px" }}
              >
                {title}
              </Typography>
              <Box
                sx={{
                  width: "80px", // Set consistent size for the circular bar
                  height: "80px",
                }}
              >
                <CircularProgressbar
                  value={value}
                  text={text || formatNumber(value)}
                  styles={buildStyles({
                    textSize: "14px",
                    pathColor: color,
                    textColor: "#333",
                    trailColor: "#ddd",
                  })}
                />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default VehiclesMap;
