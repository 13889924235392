import React from "react";
import { useUserData } from "./data/userdata";
import { slide as Menu } from "react-burger-menu";
import { Box, Typography, useTheme, Tooltip } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { tokens } from "../../theme"; // Import ColorModeContext
import SideBarImage from "../../assets/SideBar.png";
import userImage from "../../assets/user.jpg";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import MenuOpenRoundedIcon from "@mui/icons-material/MenuOpenRounded";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import GpsFixedOutlinedIcon from "@mui/icons-material/GpsFixedOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import DirectionsCarOutlinedIcon from "@mui/icons-material/DirectionsCarOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import NewReleasesOutlinedIcon from "@mui/icons-material/NewReleasesOutlined";
import { logout } from "../../utils/auth";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";

const SideBar = ({ setIsAuthenticated }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userData } = useUserData();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const handleMenuClick = (path) => {
    navigate(path);
  };

  const menuItems = [
    {
      path: "/home",
      title: "Home",
      logo: <HomeOutlinedIcon />,
    },
    {
      path: "/rebalances",
      title: "Rebalance Map",
      logo: <GpsFixedOutlinedIcon />,
    },
    { path: "/customer", title: "Customers", logo: <Person2OutlinedIcon /> },
    {
      path: "/reservation",
      title: "Reservations",
      logo: <EventNoteOutlinedIcon />,
    },
    { path: "/invoice", title: "Invoices", logo: <ReceiptOutlinedIcon /> },
    {
      path: "/vehicle",
      title: "Vehicles",
      logo: <DirectionsCarOutlinedIcon />,
    },
    {
      path: "/Damage-Reports",
      title: "Damage Reports",
      logo: <ReportGmailerrorredOutlinedIcon />,
    },
  ];

  return (
    <Menu
      width={"250px"}
      styles={{
        bmBurgerButton: {
          position: "fixed",
          width: "30px",
          height: "30px",
          left: "15px",
          top: "15px",
          zIndex: "1000",
        },
        bmBurgerBars: { background: colors.grey[100] },
        bmBurgerBarsHover: { background: colors.blueAccent[500] },
        bmMenuWrap: { transition: "transform 0.3s ease-in-out" },
        bmMenu: {
          background: colors.primary[400],
          padding: "2.5em 1.5em 0",
          fontSize: "1.15em",
        },
        bmItemList: { color: colors.grey[100], padding: "0.8em" },
        bmItem: { display: "block", textDecoration: "none" },
        bmOverlay: {
          background: "rgba(0, 0, 0, 0.3)",
          transition: "opacity 0.3s ease-in-out",
        },
      }}
      customBurgerIcon={
        <MenuOpenRoundedIcon
          style={{ color: colors.grey[100], fontSize: "30px" }}
        />
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {/* Logo Section */}
        <Box textAlign="center" mb="20px">
          <img
            src={SideBarImage}
            alt="UDrive"
            style={{
              width: "80%",
              marginBottom: "10px",
              borderRadius: "10px",
            }}
          />
        </Box>

        {/* User Section */}
        <Box textAlign="center" mb="20px">
          <img
            alt="profile-user"
            width="80px"
            height="80px"
            src={userImage}
            style={{
              borderRadius: "50%",
              marginBottom: "10px",
              border: `2px solid ${colors.blueAccent[500]}`,
            }}
          />
          <Typography variant="h6" style={{ color: colors.grey[100] }}>
            {userData?.full_name || "User"}
          </Typography>
        </Box>

        {/* Menu Items */}
        <Box>
          {menuItems.map((item) => (
            <Box
              key={item.title}
              onClick={() => handleMenuClick(item.path)}
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "10px",
                cursor: "pointer",
                color: location.pathname.includes(item.path)
                  ? colors.blueAccent[500]
                  : colors.grey[100],
                fontWeight: location.pathname.includes(item.path)
                  ? "bold"
                  : "normal",
                transition: "color 0.3s ease-in-out",
              }}
            >
              {item.logo && <Box sx={{ marginRight: "10px" }}>{item.logo}</Box>}
              <Typography>{item.title}</Typography>
            </Box>
          ))}
        </Box>

        {/* Bottom Actions */}
        <Box
          sx={{
            position: "absolute",
            bottom: "20px",
            left: "20px",
            display: "flex",
            alignItems: "center",
            gap: "15px",
            ml: "10px",
          }}
        >
          <Tooltip title="Logout" placement="top">
            <Box
              onClick={handleLogout}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: colors.grey[100],
                transition: "color 0.3s ease-in-out",
                ":hover": {
                  color: colors.blueAccent[500],
                },
              }}
            >
              <ExitToAppOutlinedIcon />
            </Box>
          </Tooltip>

          <Tooltip title="Profile" placement="top">
            <Box
              onClick={() => navigate("/profile")} // Move the onClick here
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: colors.grey[100],
                transition: "color 0.3s ease-in-out",
                ":hover": {
                  color: colors.blueAccent[500],
                },
              }}
            >
              <ManageAccountsOutlinedIcon />
            </Box>
          </Tooltip>

          <Tooltip title="Role Assignment" placement="top">
            <Box
              onClick={() => navigate("/role")} // Move the onClick here
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: colors.grey[100],
                transition: "color 0.3s ease-in-out",
                ":hover": {
                  color: colors.blueAccent[500],
                },
              }}
            >
              <AdminPanelSettingsOutlinedIcon />
            </Box>
          </Tooltip>

          <Tooltip title="Realease Notes" placement="top">
            <Box
              onClick={() => navigate("/release")} // Move the onClick here
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: colors.grey[100],
                transition: "color 0.3s ease-in-out",
                ":hover": {
                  color: colors.blueAccent[500],
                },
              }}
            >
              <NewReleasesOutlinedIcon />
            </Box>
          </Tooltip>
        </Box>
      </Box>
    </Menu>
  );
};

export default SideBar;
