import React, { useRef, useEffect, useState, useMemo } from "react";
import mapboxgl from "mapbox-gl";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

import { Unstable_Popup as Popup } from "@mui/base/Unstable_Popup";
import {
  Snackbar,
  Alert,
  Modal,
  Box,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { usePolygonData } from "../data/polygon";
import { usePointsData } from "../data/points";

/** Your Mapbox Access Token **/
mapboxgl.accessToken =
  "pk.eyJ1IjoidWRyaXZlLWFkbWluIiwiYSI6ImNtNmo5dG95bzBnMXUybHM1cmtiOXl2M2wifQ.kC4ESzdTgskxNePjRAFKMw";

/**
 * Helper function to get coordinates from Polygon or MultiPolygon,
 * flattening the nested arrays appropriately.
 */
function getAllPolygonCoords(feature) {
  if (!feature || !feature.geometry) return [];
  const { geometry } = feature;

  switch (geometry.type) {
    case "Polygon":
      return geometry.coordinates.flat();
    case "MultiPolygon":
      return geometry.coordinates.flat(2);
    default:
      return [];
  }
}

/** Modal style with sufficient width/height */
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#fff",
  borderRadius: "8px",
  boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
  padding: "20px",
  maxWidth: "800px",
  width: "90%",
  maxHeight: "80vh",
  overflowY: "auto",
};

const Map = ({ hasRoute, startCoordinate }) => {
  const mapContainerRef = useRef(null);
  const popupAnchorRef = useRef(null);
  const popupRef = useRef(null);

  // Memoize the default center array so it doesn't trigger a change on every render
  const defaultCenter = useMemo(() => [53.9994829, 24.0002488], []);
  const defaultZoom = 7;

  // Store the map instance
  const [mapInstance, setMapInstance] = useState(null);

  // Popup states
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });

  // Snackbar states
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Filter modal
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  // Multiple selection states
  const [selectedModelNames, setSelectedModelNames] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);

  // Fetch data
  const {
    rawData: polygonData,
    loading: polygonLoading,
    error: polygonError,
  } = usePolygonData();

  const {
    transformedData: pointsData,
    loading: pointsLoading,
    error: pointsError,
  } = usePointsData();

  /**
   * Popup & global click logic
   */
  useEffect(() => {
    const handleGlobalClick = (e) => {
      const target = e.target;

      // 1) Close popup if user clicks the × icon
      if (target && target.id === "close-popup") {
        setPopupOpen(false);
        return;
      }

      // 2) Show Snackbar messages for the 3 buttons
      if (target && target.id === "reserve-btn") {
        setSnackbarMessage("Car Reserved!");
        setSnackbarOpen(true);
        return;
      } else if (target && target.id === "change-btn") {
        setSnackbarMessage("State Changed!");
        setSnackbarOpen(true);
        return;
      } else if (target && target.id === "clean-btn") {
        setSnackbarMessage("Cleaning Started!");
        setSnackbarOpen(true);
        return;
      }

      // 3) Close popup if click is outside the popup container
      if (popupOpen && popupRef.current && !popupRef.current.contains(target)) {
        setPopupOpen(false);
      }
    };

    document.addEventListener("click", handleGlobalClick, true);
    return () => {
      document.removeEventListener("click", handleGlobalClick, true);
    };
  }, [popupOpen]);

  /**
   * Initialize the Mapbox map
   */
  useEffect(() => {
    // Decide the initial map center
    const mapCenter =
      hasRoute && startCoordinate ? startCoordinate : defaultCenter;

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: mapCenter,
      zoom: defaultZoom,
    });

    map.on("load", () => {
      setMapInstance(map);

      // If polygonData is ready, add polygon source/layers
      if (!polygonLoading && !polygonError && polygonData) {
        map.addSource("hotspots", {
          type: "geojson",
          data: polygonData,
        });
        map.addLayer({
          id: "hotspots-layer",
          type: "fill",
          source: "hotspots",
          paint: {
            "fill-color": "#0080ff",
            "fill-opacity": 0.3,
          },
          layout: {
            // Hide the layer by default
            visibility: "none",
          },
        });
        map.addLayer({
          id: "hotspots-outline-layer",
          type: "line",
          source: "hotspots",
          paint: {
            "line-color": "#000",
            "line-width": 2,
          },
          layout: {
            // Hide the outline by default
            visibility: "none",
          },
        });

        // Add a click handler for polygons to show popup
        map.on("click", "hotspots-layer", (e) => {
          if (!e.features || !e.features.length) return;
          const feature = e.features[0];
          const { Area, Zone } = feature.properties;

          setAnchorPoint({ x: e.point.x, y: e.point.y });
          const content = `
            <div style="position: relative;">
              <p><strong>Area:</strong> ${Area || "N/A"}</p>
              <p><strong>Zone:</strong> ${Zone || "N/A"}</p>
            </div>
          `;
          setPopupContent(content);
          setPopupOpen(true);
        });

        map.on("mouseenter", "hotspots-layer", () => {
          map.getCanvas().style.cursor = "pointer";
        });
      }

      // If pointsData is ready, add points source/layer
      if (!pointsLoading && !pointsError && pointsData) {
        map.addSource("pointsSource", {
          type: "geojson",
          data: pointsData,
        });
        map.addLayer({
          id: "points-layer",
          type: "circle",
          source: "pointsSource",
          paint: {
            "circle-color": "#FF0000",
            "circle-radius": 6,
            "circle-stroke-color": "#fff",
            "circle-stroke-width": 1,
          },
        });

        // Add click for points => show popup
        map.on("click", "points-layer", (e) => {
          if (!e.features || !e.features.length) return;
          const feature = e.features[0];
          const { name, vehicleId, icon } = feature.properties;

          setAnchorPoint({ x: e.point.x, y: e.point.y });
          const content = `
            <div style="position: relative;">
              <button id="close-popup"
                      style="position: absolute; top: 0; right: 0; background: none; border: none; font-size: 20px; cursor: pointer;">
                &times;
              </button>
              <div style="display: flex; justify-content: space-between; align-items: center;">
                  <div>
                      <h3 style="margin: 0 0 10px;">${name || "No Name"}</h3>
                      <p style="margin: 0 0 10px;"><strong>Vehicle ID:</strong> ${vehicleId || "N/A"}</p>
                  </div>
                  <div style="flex-shrink: 0;">
                      <img src="${icon}" alt="Icon" style="width: auto; height: 170px; object-fit: fill;" />
                  </div>
              </div>
              <div style="display: flex; gap: 10px;">
                <button id="reserve-btn" style="padding: 8px 16px; background: #007BFF; color: #fff; border: none; border-radius: 4px; cursor: pointer;">
                  Reserve Car
                </button>
                <button id="change-btn" style="padding: 8px 16px; background: #FF9900; color: #fff; border: none; border-radius: 4px; cursor: pointer;">
                  Change State
                </button>
                <button id="clean-btn" style="padding: 8px 16px; background: #28A745; color: #fff; border: none; border-radius: 4px; cursor: pointer;">
                  Start Cleaning
                </button>
              </div>
            </div>
          `;
          setPopupContent(content);
          setPopupOpen(true);
        });

        map.on("mouseenter", "points-layer", () => {
          map.getCanvas().style.cursor = "pointer";
        });

        // Fit bounds to points initially
        const bounds = new mapboxgl.LngLatBounds();
        pointsData.features.forEach((feature) => {
          bounds.extend(feature.geometry.coordinates);
        });

        if (!bounds.isEmpty()) {
          map.fitBounds(bounds, { padding: 20 });
        }
      }
    });

    // Add custom map controls: zoom in, zoom out, filter
    class ZoomInControl {
      onAdd(map) {
        this.map = map;
        this.container = document.createElement("div");
        this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
        const buttonStyle = {
          fontSize: "24px",
          width: "40px",
          height: "40px",
          border: "none",
          borderRadius: "4px",
          backgroundColor: "#ffffff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        };
        Object.assign(this.container.style, {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        });

        const zoomInButton = document.createElement("button");
        zoomInButton.innerHTML = "+";
        Object.assign(zoomInButton.style, buttonStyle);
        tippy(zoomInButton, { content: "Zoom In" });
        zoomInButton.onclick = () => map.zoomIn();
        this.container.appendChild(zoomInButton);
        return this.container;
      }
      onRemove() {
        this.container?.parentNode?.removeChild(this.container);
        this.map = undefined;
      }
    }

    class ZoomOutControl {
      onAdd(map) {
        this.map = map;
        this.container = document.createElement("div");
        this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
        const buttonStyle = {
          fontSize: "24px",
          width: "40px",
          height: "40px",
          border: "none",
          borderRadius: "4px",
          backgroundColor: "#ffffff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        };
        Object.assign(this.container.style, {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        });

        const zoomOutButton = document.createElement("button");
        zoomOutButton.innerHTML = "−";
        Object.assign(zoomOutButton.style, buttonStyle);
        tippy(zoomOutButton, { content: "Zoom Out" });
        zoomOutButton.onclick = () => map.zoomOut();
        this.container.appendChild(zoomOutButton);
        return this.container;
      }
      onRemove() {
        this.container?.parentNode?.removeChild(this.container);
        this.map = undefined;
      }
    }

    class FilterControl {
      onAdd() {
        this.container = document.createElement("div");
        this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
        const buttonStyle = {
          fontSize: "24px",
          width: "40px",
          height: "40px",
          border: "none",
          borderRadius: "4px",
          backgroundColor: "#ffffff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        };
        Object.assign(this.container.style, {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        });

        const filterButton = document.createElement("button");
        filterButton.innerHTML = "⚙";
        Object.assign(filterButton.style, buttonStyle);
        tippy(filterButton, { content: "Filter" });
        filterButton.onclick = () => setFilterModalOpen(true);

        this.container.appendChild(filterButton);
        return this.container;
      }
      onRemove() {
        this.container?.parentNode?.removeChild(this.container);
      }
    }

    map.addControl(new ZoomInControl(), "top-right");
    map.addControl(new ZoomOutControl(), "top-right");
    map.addControl(new FilterControl(), "top-right");

    // Cleanup when unmounting
    return () => map.remove();

    // Include defaultCenter and defaultZoom in the dependency array
  }, [
    hasRoute,
    startCoordinate,
    polygonData,
    polygonLoading,
    polygonError,
    pointsData,
    pointsLoading,
    pointsError,
    defaultCenter,
    defaultZoom,
  ]);

  /**
   * Filter for Points (Model Names)
   */
  useEffect(() => {
    if (!mapInstance) return;
    if (!mapInstance.isStyleLoaded()) return;

    const pointsLayer = mapInstance.getLayer("points-layer");
    if (!pointsLayer) return;

    if (selectedModelNames.length > 0) {
      mapInstance.setFilter("points-layer", [
        "in",
        ["get", "name"],
        ["literal", selectedModelNames],
      ]);
    } else {
      mapInstance.setFilter("points-layer", null);
    }
  }, [mapInstance, selectedModelNames]);

  /**
   * Filter & zoom for polygons (Area Hotspots)
   * Only show them if at least 1 area is selected;
   * otherwise, hide the polygons entirely and revert to default zoom.
   */
  useEffect(() => {
    if (!mapInstance) return;
    if (!mapInstance.isStyleLoaded()) return;
    if (!polygonData) return;

    const hotspotsLayer = mapInstance.getLayer("hotspots-layer");
    const hotspotsOutline = mapInstance.getLayer("hotspots-outline-layer");
    if (!hotspotsLayer || !hotspotsOutline) return;

    if (selectedAreas.length > 0) {
      // Make the polygon layers visible
      mapInstance.setLayoutProperty("hotspots-layer", "visibility", "visible");
      mapInstance.setLayoutProperty(
        "hotspots-outline-layer",
        "visibility",
        "visible",
      );

      mapInstance.setFilter("hotspots-layer", [
        "in",
        ["get", "Area"],
        ["literal", selectedAreas],
      ]);
      mapInstance.setFilter("hotspots-outline-layer", [
        "in",
        ["get", "Area"],
        ["literal", selectedAreas],
      ]);

      // Zoom to these polygons
      const combinedBounds = new mapboxgl.LngLatBounds();
      polygonData.features.forEach((feature) => {
        const { Area } = feature.properties || {};
        if (selectedAreas.includes(Area)) {
          const coords = getAllPolygonCoords(feature);
          coords.forEach((coord) => combinedBounds.extend(coord));
        }
      });

      if (!combinedBounds.isEmpty()) {
        mapInstance.fitBounds(combinedBounds, { padding: 40, duration: 1000 });
      }
    } else {
      // Hide the polygon layers completely
      mapInstance.setLayoutProperty("hotspots-layer", "visibility", "none");
      mapInstance.setLayoutProperty(
        "hotspots-outline-layer",
        "visibility",
        "none",
      );

      // Revert to default "points" bounds OR fallback to default center if no points
      const bounds = new mapboxgl.LngLatBounds();

      if (pointsData?.features?.length) {
        pointsData.features.forEach((feat) => {
          bounds.extend(feat.geometry.coordinates);
        });
      }

      if (!bounds.isEmpty()) {
        // Fit to whichever points are currently visible
        mapInstance.fitBounds(bounds, { padding: 20, duration: 1000 });
      } else {
        // If no points, reset to the default center/zoom
        mapInstance.setCenter(defaultCenter);
        mapInstance.setZoom(defaultZoom);
      }
    }
  }, [
    mapInstance,
    selectedAreas,
    polygonData,
    pointsData,
    defaultCenter,
    defaultZoom,
  ]);

  /**
   * Distinct Model Names from points
   */
  const distinctModelNames = useMemo(() => {
    if (!pointsData || !pointsData.features) return [];
    const names = new Set();
    pointsData.features.forEach((feat) => {
      if (feat.properties?.name) {
        names.add(feat.properties.name);
      }
    });
    return Array.from(names);
  }, [pointsData]);

  /**
   * Distinct Areas from polygons
   */
  const distinctAreas = useMemo(() => {
    if (!polygonData || !polygonData.features) return [];
    const areas = new Set();
    polygonData.features.forEach((feat) => {
      if (feat.properties?.Area) {
        areas.add(feat.properties.Area);
      }
    });
    return Array.from(areas);
  }, [polygonData]);

  // Toggle selected model names
  const handleModelNameClick = (name) => {
    setSelectedModelNames((prev) =>
      prev.includes(name) ? prev.filter((n) => n !== name) : [...prev, name],
    );
  };

  // Toggle selected areas
  const handleAreaChange = (area) => {
    setSelectedAreas((prev) =>
      prev.includes(area) ? prev.filter((a) => a !== area) : [...prev, area],
    );
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      {/* Map container */}
      <div
        ref={mapContainerRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      />

      {/* Hidden anchor for the popup */}
      <div
        ref={popupAnchorRef}
        style={{
          position: "absolute",
          left: anchorPoint.x,
          top: anchorPoint.y,
          width: 0,
          height: 0,
        }}
      />

      {/* The single "box" Popup (for points & polygons) */}
      <Popup
        open={popupOpen}
        anchor={popupAnchorRef.current}
        onClose={() => setPopupOpen(false)}
        slotProps={{
          root: {
            ref: popupRef,
            style: {
              background: "#fff",
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "20px",
              boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
              fontFamily: "Helvetica, Arial, sans-serif",
              maxWidth: "420px",
            },
          },
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: popupContent }} />
      </Popup>

      {/* Snackbar for button clicks */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Filter Modal */}
      <Modal
        open={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        aria-labelledby="filter-modal-title"
      >
        <Box sx={modalStyle}>
          {/* Model Name Section */}
          <Typography variant="subtitle1" gutterBottom>
            Model Name
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
            {distinctModelNames.map((name) => (
              <Button
                key={name}
                variant={
                  selectedModelNames.includes(name) ? "contained" : "outlined"
                }
                onClick={() => handleModelNameClick(name)}
              >
                {name}
              </Button>
            ))}
          </Box>

          {/* Aging Cars Section */}
          <Typography variant="subtitle1" gutterBottom>
            Aging Cars
          </Typography>
          <Box sx={{ mb: 2 }}>
            {/* Empty for now... */}
            <Typography variant="body2" color="text.secondary">
              (This section is currently empty)
            </Typography>
          </Box>

          {/* Area Hotspots Section (CheckBoxes in Multiple Columns) */}
          <Typography variant="subtitle1" gutterBottom>
            Area Hotspots
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              mb: 2,
            }}
          >
            {distinctAreas.map((area) => (
              <FormControlLabel
                key={area}
                control={
                  <Checkbox
                    checked={selectedAreas.includes(area)}
                    onChange={() => handleAreaChange(area)}
                  />
                }
                label={area}
                sx={{
                  minWidth: "10%",
                }}
              />
            ))}
          </Box>

          {/* Cleaning Car Section */}
          <Typography variant="subtitle1" gutterBottom>
            Cleaning Car
          </Typography>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" color="text.secondary">
              (This section is currently empty)
            </Typography>
          </Box>

          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button
              variant="outlined"
              onClick={() => setFilterModalOpen(false)}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Map;
