import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Tooltip,
  Snackbar,
  Alert,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  MenuItem,
  DialogActions,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CloseIconMaterial from "@mui/icons-material/Close";
//Invoice Data
import { useInvoiceData } from "./data/invoiceDetails";
//Payment Method Data
import { usePaymentMethodData } from "./data/paymentmethoddata";
//Invoice Transaction Data
import { useInvoiceTransactionData } from "./data/invoicetransactions";

//Action
import { useCancelFees } from "./action/cancelfees";
import { useInvoiceStateChange } from "./action/statechange";
import { useTriggerPayment } from "./action/triggerpayment";

const InvoiceDetails = () => {
  const { invoiceID } = useParams();

  const navigate = useNavigate();
  const { cancelFees } = useCancelFees();
  const { changeState } = useInvoiceStateChange();
  const [cancelLoading, setCancelLoading] = useState(false);

  const { data: invoiceData } = useInvoiceData(invoiceID);
  const invoiceUrl = invoiceData?.invoiceUrl;
  const fees = invoiceData?.fee;
  const customerId = invoiceData?.customer_id;

  const [stateDialogOpen, setStateDialogOpen] = useState(false); // To control dialog visibility
  const [selectedState, setSelectedState] = useState(""); // To track the selected state
  const availableStates = [
    "paid",
    "failed",
    "canceled",
    "waiting",
    "instalment",
  ];
  const status = invoiceData?.status; // Define status before filtering
  const [invoiceStatus, setInvoiceStatus] = useState(invoiceData?.status);

  const filteredStates = invoiceStatus
    ? availableStates.filter((state) => state !== invoiceStatus)
    : availableStates;

  const handleStateDialogOpen = () => setStateDialogOpen(true);
  const handleStateDialogClose = () => setStateDialogOpen(false);

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };

  const [stateChangeLoading, setStateChangeLoading] = useState(false);

  const handleConfirmStateChange = async () => {
    if (!selectedState) {
      setSnackbar({
        open: true,
        message: "Please select a state before confirming.",
        severity: "warning",
      });
      return;
    }

    setStateChangeLoading(true); // Start loading
    try {
      await changeState(invoiceID, selectedState); // Call API
      setInvoiceStatus(selectedState); // Update the local status ONLY if API succeeds
      setSnackbar({
        open: true,
        message: `State successfully changed to "${selectedState}"!`,
        severity: "success",
      });
    } catch (error) {
      // Display error in snackbar
      setSnackbar({
        open: true,
        message: error.message, // Use the thrown error message
        severity: "error",
      });
    } finally {
      setStateChangeLoading(false); // Stop loading
      setStateDialogOpen(false); // Close dialog
    }
  };

  useEffect(() => {
    if (invoiceData?.status) {
      setInvoiceStatus(invoiceData.status);
    }
  }, [invoiceData]);

  const getStatusStyles = (status) => {
    switch (status) {
      case "paid":
        return { backgroundColor: "#4CAF50", color: "#FFFFFF" }; // Green
      case "failed":
        return { backgroundColor: "#F44336", color: "#FFFFFF" }; // Red
      case "canceled":
        return { backgroundColor: "#FF9800", color: "#FFFFFF" }; // Orange
      case "waiting":
        return { backgroundColor: "#2196F3", color: "#FFFFFF" }; // Blue
      case "instalment":
        return { backgroundColor: "#7E57C2", color: "#FFFFFF" }; // Light Purple
      case "lost":
        return { backgroundColor: "#9E9E9E", color: "#FFFFFF" }; // Gray
      default:
        return {};
    }
  };

  const {
    data: payment,
    loading: paymentLoading,
    error: paymentError,
  } = usePaymentMethodData(customerId, 1);
  const {
    data: triggerData,
    loading: triggerLoading,
    error: triggerError,
    triggerPayment,
  } = useTriggerPayment();
  const {
    data: transactionData,
    loading: transactionLoading,
    error: transactionError,
  } = useInvoiceTransactionData(invoiceID);

  const [showTable, setShowTable] = useState(false);
  const [selectedMethods, setSelectedMethods] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    setSelectedMethods([]);
  }, [payment]);

  useEffect(() => {
    if (triggerData) {
      setSnackbar({
        open: true,
        message: "Payment triggered successfully!",
        severity: "success",
      });
      setInvoiceStatus("paid");
      setShowTable(false);
      setSelectedMethods([]);
    }
  }, [triggerData]);

  useEffect(() => {
    if (triggerError) {
      setSnackbar({
        open: true,
        message: `${triggerError.message}`,
        severity: "error",
      });
      setInvoiceStatus("failed");
    }
  }, [triggerError]);

  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleToggleTable = () => {
    if (status === "failed" || status === "waiting") {
      setShowTable((prev) => !prev);
      setSelectedMethods([]);
    } else {
      setSnackbar({
        open: true,
        message:
          "Payment Trigger is only available for Failed or Waiting status",
        severity: "warning",
      });
    }
  };

  const handleCheck = (method) => {
    setSelectedMethods((prev) => {
      const isAlreadySelected =
        prev.length > 0 &&
        prev[0].payment_method_id === method.payment_method_id &&
        prev[0].customer_id === method.customer_id;

      return isAlreadySelected
        ? []
        : [
            {
              payment_method_id: method.payment_method_id,
              customer_id: method.customer_id,
            },
          ];
    });
  };

  const handleConfirm = async () => {
    const paymentMethodIds = selectedMethods.map(
      (method) => method.payment_method_id,
    );

    await triggerPayment(invoiceID, paymentMethodIds);
  };

  const handleCancel = () => {
    setShowTable(false);
    setSelectedMethods([]);
  };

  if (!invoiceData) {
    return (
      <Box p={2}>
        <Typography variant="h6" color="error">
          No invoice data available.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2, mt: 5, ml: 1, mr: 1 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Box>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              textTransform: "uppercase",
              fontWeight: "bold",
              display: "inline-block", // Ensure it's inline with the status Box
              marginRight: "20px", // Add space between the Typography and the Box
            }}
          >
            <span>INVOICE #</span>
            {invoiceID}
          </Typography>
          <Tooltip title="Status" arrow>
            <Box
              sx={{
                display: "inline-block",
                borderRadius: "4px",
                padding: "6px 20px",
                ...getStatusStyles(invoiceStatus), // Use the updated status
                textAlign: "center",
                textTransform: "none",
              }}
            >
              {invoiceStatus} {/* Display the updated status */}
            </Box>
          </Tooltip>
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Tooltip
            title={
              status === "failed" || status === "waiting"
                ? "Payment Trigger"
                : "Payment Trigger is only available for Failed or Waiting status"
            }
            arrow
          >
            <span>
              <Button
                color="primary"
                onClick={handleToggleTable}
                disabled={!(status === "failed" || status === "waiting")}
                aria-label={
                  showTable
                    ? "Close Payment Trigger Table"
                    : "Open Payment Trigger Table"
                }
              >
                {showTable ? <CloseIcon /> : <PaymentsOutlinedIcon />}
              </Button>
            </span>
          </Tooltip>

          <Tooltip title="State Change" arrow>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#673AB7", // Custom color (Deep Purple)
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#5E35B1", // Slightly darker purple
                },
              }}
              onClick={handleStateDialogOpen} // Open dialog on click
            >
              State Change
            </Button>
          </Tooltip>

          {/* Dialog for changing states*/}
          <Dialog
            open={stateDialogOpen}
            onClose={handleStateDialogClose}
            maxWidth="sm" // Set maximum width to "sm", "md", or "lg" as per requirement
            fullWidth // Ensure it takes the full width available within the maxWidth
            PaperProps={{
              sx: {
                padding: 2, // Add padding for better spacing
                borderRadius: 2, // Rounded corners for better aesthetics
              },
            }}
          >
            <DialogTitle>
              <Typography variant="h5" fontWeight="bold">
                Change State
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Please select the new state for the invoice.
              </Typography>
              <Select
                value={selectedState}
                onChange={handleStateChange}
                displayEmpty
                fullWidth
                sx={{
                  mb: 2,
                  borderRadius: 1, // Rounded corners for the dropdown
                }}
              >
                <MenuItem value="" disabled>
                  Select a state
                </MenuItem>
                {filteredStates.map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </Select>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "flex-end", gap: 1 }}>
              <Button
                onClick={handleStateDialogClose}
                color="secondary"
                variant="outlined"
                sx={{
                  textTransform: "none", // Avoid uppercase for the button text
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirmStateChange}
                variant="contained"
                color="primary"
                disabled={stateChangeLoading}
                sx={{
                  textTransform: "none", // Avoid uppercase for the button text
                  boxShadow: 2, // Add slight shadow for better aesthetics
                }}
              >
                {stateChangeLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Confirm"
                )}
              </Button>
            </DialogActions>
          </Dialog>

          <Tooltip title="Go Back" arrow>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.history.back()}
            >
              Go Back
            </Button>
          </Tooltip>
        </Box>
      </Box>

      {/* Loading State */}
      {paymentLoading && (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <CircularProgress size={24} />
          <Typography>Loading payment methods...</Typography>
        </Box>
      )}

      {/* Error State */}
      {paymentError && (
        <Typography color="error">Error: {paymentError}</Typography>
      )}

      {/* Payment Methods Table */}
      {!paymentLoading && !paymentError && showTable && (
        <Box>
          {payment.length > 0 ? (
            <>
              {/* Table Container */}
              <TableContainer component={Paper} sx={{ mb: 2 }}>
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="payment methods table"
                >
                  <TableHead>
                    <TableRow>
                      {/* Empty TableCell for alignment */}
                      <TableCell sx={{ fontWeight: "bold" }}></TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Payment Method ID
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Customer ID
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Default</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Title</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {payment.map((method) => (
                      <TableRow
                        key={`${method.payment_method_id}-${method.customer_id}`}
                        hover
                        selected={
                          selectedMethods.length > 0 &&
                          selectedMethods[0].payment_method_id ===
                            method.payment_method_id &&
                          selectedMethods[0].customer_id === method.customer_id
                        }
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={
                              selectedMethods.length > 0 &&
                              selectedMethods[0].payment_method_id ===
                                method.payment_method_id &&
                              selectedMethods[0].customer_id ===
                                method.customer_id
                            }
                            onChange={() => handleCheck(method)}
                            inputProps={{
                              "aria-labelledby": `payment-method-${method.payment_method_id}`,
                            }}
                          />
                        </TableCell>
                        <TableCell>{method.payment_method_id}</TableCell>
                        <TableCell>{method.customer_id}</TableCell>
                        <TableCell>
                          {method.is_default ? (
                            <CheckIcon color="success" />
                          ) : (
                            <CloseIconMaterial color="error" />
                          )}
                        </TableCell>
                        <TableCell>{method.title}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Action Buttons */}
              <Box sx={{ display: "flex", gap: 2 }}>
                {/* Confirm Button */}
                <Tooltip title="Trigger Payment" arrow>
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleConfirm}
                      disabled={selectedMethods.length === 0 || triggerLoading}
                      startIcon={
                        triggerLoading && (
                          <CircularProgress size={20} color="inherit" />
                        )
                      }
                    >
                      {triggerLoading ? "Processing..." : "Confirm"}
                    </Button>
                  </span>
                </Tooltip>
                {/* Cancel Button */}
                <Tooltip title="Cancel" arrow>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCancel}
                    disabled={triggerLoading}
                  >
                    Cancel
                  </Button>
                </Tooltip>
              </Box>
            </>
          ) : (
            <Typography>No payment methods found.</Typography>
          )}
        </Box>
      )}

      {/* Fee Details */}
      {fees && fees.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <TableContainer component={Paper} sx={{ mb: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Create Time</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Reservation ID
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Description</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Value</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fees.map((feeItem) => (
                  <TableRow
                    key={feeItem.fee_id}
                    onClick={() =>
                      navigate(`/reservation/${feeItem.reservation_id}`)
                    }
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                  >
                    <TableCell>{feeItem.create_time}</TableCell>
                    <TableCell>{feeItem.reservation_id}</TableCell>
                    <TableCell>{feeItem.description}</TableCell>
                    <TableCell>{feeItem.value}</TableCell>
                    <TableCell>
                      <Tooltip title="Cancel Fee" arrow>
                        <IconButton
                          onClick={async (e) => {
                            e.stopPropagation(); // Prevent row navigation when clicking the button
                            setCancelLoading(true); // Set loading to true

                            try {
                              await cancelFees(feeItem.fee_id); // Call your API
                              setSnackbar({
                                open: true,
                                message: "Fee canceled successfully!",
                                severity: "success",
                              });
                            } catch (error) {
                              setSnackbar({
                                open: true,
                                message: `Failed to cancel fee: ${
                                  error.message || error
                                }`,
                                severity: "error",
                              });
                            } finally {
                              setCancelLoading(false); // Reset loading state
                            }
                          }}
                          disabled={cancelLoading || status === "canceled"} // Disable if status is 'canceled'
                          sx={{
                            color: "red", // Set the icon color to red
                            "&:hover": {
                              backgroundColor: "rgba(255, 0, 0, 0.1)", // Light red background on hover
                            },
                          }}
                        >
                          {cancelLoading ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            <CancelOutlinedIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {/* Transaction Details */}
      {transactionLoading && (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <CircularProgress size={24} />
          <Typography>Loading transaction details...</Typography>
        </Box>
      )}
      {/* Transaction Error */}
      {transactionError && (
        <Typography color="error">Error: {transactionError}</Typography>
      )}
      {/* Transcation Details */}
      {transactionData && transactionData.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <TableContainer component={Paper} sx={{ mb: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>ID</TableCell>{" "}
                  {/* Add a header for Transaction ID */}
                  <TableCell sx={{ fontWeight: "bold" }}>Invoice ID</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Triggered By
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Triggered At
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Success</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Response</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionData.map((transaction) => (
                  <TableRow key={transaction.id}>
                    <TableCell>{transaction.id}</TableCell>
                    <TableCell>{transaction.invoice_id}</TableCell>
                    <TableCell>{transaction.triggered_by}</TableCell>
                    <TableCell>{transaction.triggered_at}</TableCell>
                    <TableCell>{transaction.success ? "✅" : "❌"}</TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          p: 1,
                          border: "1px solid #ddd",
                          borderRadius: "8px",
                          backgroundColor: "#f5f5f5",
                          display: "inline-block",
                          width: "fit-content",
                          maxWidth: "100%", // Ensures it doesn't overflow the table cell
                        }}
                      >
                        {transaction.response}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {/* PDF Viewer Embedded in Main Page */}
      {invoiceUrl && (
        <Box sx={{ mt: 4 }}>
          <Box
            sx={{
              width: "100%",
              height: "80vh",
              border: "1px solid #ccc",
              borderRadius: "4px",
              overflow: "hidden",
            }}
          >
            <iframe
              src={invoiceUrl}
              title={`Invoice PDF - ${invoiceID}`}
              width="100%"
              height="100%"
              style={{ border: "none" }}
              onError={() => {
                setSnackbar({
                  open: true,
                  message: "Failed to load PDF.",
                  severity: "error",
                });
              }}
            />
          </Box>
        </Box>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          action={
            <Button color="inherit" size="small" onClick={handleSnackbarClose}>
              CLOSE
            </Button>
          }
          sx={{
            width: "100%",
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default InvoiceDetails;
