import { useState, useEffect, useCallback } from "react";

const BASE_URL = "https://jf8k6mp2m8.eu-west-1.awsapprunner.com";

export const usePricingModelData = (vehicleId) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      setLoading(false);
      return;
    }

    const endpoint = `${BASE_URL}/vehicles/pricingModels?vehicleId=${vehicleId}`;

    try {
      setLoading(true);
      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        localStorage.removeItem("token");
        window.location.href = "/";
        return;
      }

      if (!response.ok) {
        throw new Error(
          `Failed to fetch ${response.url}: ${response.statusText}`,
        );
      }

      const result = await response.json();

      const dataArray = Array.isArray(result) ? result : [result];

      const transformedData = dataArray.map((item) => ({
        pricingModels: item.pricingModels || [],
        business_type: item.business_type || [],
        intrip_parking_coverage: item.intrip_parking_coverage || [],
        excess_km_price: item.excess_km_price || [],
        kilometer_limit: item.kilometer_limit || [],
        trip_fee: item.trip_fee || [],
      }));

      setData(transformedData);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setError(error.message || "An unknown error occurred");
    } finally {
      setLoading(false);
    }
  }, [vehicleId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, loading, error, refreshPricingModelsData: fetchData };
};

// Updated function to update the pricing model and refresh the data
export const updatePricingModel = async (
  vehicleId,
  pricingModelData,
  refreshPricingModelsData,
) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }

  const endpoint = `${BASE_URL}/vehicles/pricingModels?vehicleId=${vehicleId}`;

  try {
    const response = await fetch(endpoint, {
      method: "PUT", // Use PUT for updating
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(pricingModelData),
    });

    if (response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
      throw new Error("Unauthorized access. Please log in again.");
    }

    if (response.status === 403) {
      throw new Error(
        "Forbidden access. You do not have permission to update this resource.",
      );
    }

    if (!response.ok) {
      throw new Error(`Failed to update pricing model: ${response.statusText}`);
    }

    const result = await response.json();

    // Refresh data after successful update
    if (refreshPricingModelsData) {
      refreshPricingModelsData();
    }

    return result;
  } catch (error) {
    console.error("Failed to update pricing model:", error);
    throw new Error(error.message || "An unknown error occurred");
  }
};

// Function to update business model and refresh the data
export const updateBusinessModel = async (
  vehicleId,
  businessModelData,
  refreshPricingModelsData,
) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }

  const endpoint = `${BASE_URL}/vehicles/businessType?vehicleId=${vehicleId}`;

  try {
    const response = await fetch(endpoint, {
      method: "PUT", // Use PUT for updating
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(businessModelData),
    });

    if (response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
      throw new Error("Unauthorized access. Please log in again.");
    }

    if (response.status === 403) {
      throw new Error(
        "Forbidden access. You do not have permission to update this resource.",
      );
    }

    if (!response.ok) {
      throw new Error(
        `Failed to update business model: ${response.statusText}`,
      );
    }

    const result = await response.json();

    // Refresh data after successful update
    if (refreshPricingModelsData) {
      refreshPricingModelsData();
    }

    return result;
  } catch (error) {
    console.error("Failed to update business model:", error);
    throw new Error(error.message || "An unknown error occurred");
  }
};
