import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

//Data
import { useCustomerData } from "./data/customerdata";
import { useReservationData } from "./data/reservationdata";
import { useInvoiceData } from "./data/invoicedata";
import { useCreditData } from "./data/creditdata";
import { useDeviceData } from "./data/devicedata";
import { useHistoryData } from "./data/historydata";
import useVerificationData from "./data/verificationdata";
import { usePaymentMethodData } from "./data/paymentmethoddata";
import { useLinkedAccountData } from "./data/linkedaccountdata";
import { useActivateCustomer } from "./action/activate";
import { useDeactivateCustomer } from "./action/deactivate";

//Action
import { useBlockCustomer } from "./action/block";
import { useUnblockCustomer } from "./action/unblock";
import { useEditFunnels } from "./action/Funnels";
import { useRestore } from "./action/restore";
import { useUpdatePlan } from "./action/updatePlan";
import { useUpdateLocation } from "./action/updateLocation";
import { useUpdateIsDev } from "./action/updateIsDev";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import SaveIcon from "@mui/icons-material/Save";
import Tooltip from "@mui/material/Tooltip";

//Icon button
import CheckIcon from "@mui/icons-material/Check";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Typography,
  CircularProgress,
  Tabs,
  Tab,
  Card,
  CardContent,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
  Pagination,
  MenuItem,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  FormControlLabel,
} from "@mui/material";
import {
  Mail as MailIcon,
  Phone as PhoneIcon,
  CalendarToday as CalendarTodayIcon,
  LocationOn as LocationOnIcon,
  Language as LanguageIcon,
  Verified as VerifiedIcon,
  SupportAgentOutlined as AgentIcon,
} from "@mui/icons-material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import { CustomImageViewer } from "./zoomimage";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

//Date Picker
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

//Drop-Down
import Autocomplete from "@mui/material/Autocomplete";
import verificationstate from "../customer/dropdown/verificationstate";
import nationality from "../customer/dropdown/nationality";
import customertype from "../customer/dropdown/customertype";
import rejectionReasons from "../customer/dropdown/rejectionreason";

//Customer Page
const CustomerDetails = () => {
  const { customerId } = useParams();
  const [customer, setCustomer] = useState(null);
  // Hooks for API actions
  const { updatePlan } = useUpdatePlan();
  const { updateLocation } = useUpdateLocation();
  const { updateIsDev } = useUpdateIsDev();

  // State management
  const [isDev, setIsDev] = useState(false);
  const [plan, setPlan] = useState("");
  const [location, setLocation] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [pendingUpdate, setPendingUpdate] = useState(null); // Store pending update details

  // Sync state with customer data
  useEffect(() => {
    if (customer) {
      setIsDev(customer.isDev || false);
      setPlan(customer.plan || "");
      setLocation(customer.location || "");
    }
  }, [customer]);

  // Handlers for edit customer dialog
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
    setPendingUpdate(null);
  };

  const handleConfirmUpdate = async () => {
    if (pendingUpdate) {
      const { field, value } = pendingUpdate;

      // Update local state immediately for better UI responsiveness

      let success = false;
      try {
        // Call the appropriate API based on the field
        if (field === "isDev") {
          await updateIsDev(customer.id, value);
          success = true;
        }
        if (field === "plan") {
          await updatePlan(customer.id, value);
          success = true;
        }
        if (field === "location") {
          await updateLocation(customer.id, value);
          success = true;
        }

        if (success) {
          setCustomer((prev) => ({
            ...prev,
            [field]: value,
          }));

          setSnackbarMessage(
            `Successfully updated ${field} to "${String(value)}"`,
          );
          setSnackbarSeverity("success");
        }
      } catch (error) {
        console.error("Error updating customer data:", error);

        setSnackbarMessage(error.message || "Failed to update customer data");
        setSnackbarSeverity("error");
      } finally {
        setOpenSnackbar(true); // Show Snackbar
        handleConfirmDialogClose(); // Close confirmation dialog
        setPendingUpdate(null); // Reset pending update
      }
    }
  };

  // Handle change events
  const handleChange = (field, value) => {
    setPendingUpdate({ field, value }); // Store the field and value for confirmation
    setConfirmDialogOpen(true); // Open the confirmation dialog
  };

  /*For Page*/
  const [page, setPage] = useState(1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editableData, setEditableData] = useState({});
  const [blockDialogOpen, setBlockDialogOpen] = useState(false);
  const [blockData, setBlockData] = useState({
    blocked: false,
    blockreason: "",
  });
  const [openUnblockDialog, setOpenUnblockDialog] = useState(false);
  const { blockCustomer } = useBlockCustomer();
  const { unblockCustomer } = useUnblockCustomer();
  const { editFunnels } = useEditFunnels();
  const { Restore } = useRestore();
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);

  const getStatusStyles = (state) => {
    switch (state) {
      case "Reserved":
        return { backgroundColor: "#2980B9", color: "#FFFFFF" };
      case "Running":
        return { backgroundColor: "#27AE60", color: "#FFFFFF" };
      case "Cancelled":
        return { backgroundColor: "#E74C3C", color: "#FFFFFF" };
      case "Expired":
        return { backgroundColor: "#7F8C8D", color: "#FFFFFF" };
      case "Completed":
        return { backgroundColor: "#120A8F", color: "#ECF0F1" };
      default:
        return {};
    }
  };

  const getInvoiceStatus = (status) => {
    switch (status) {
      case "paid":
        return { backgroundColor: "#4CAF50", color: "#FFFFFF" };
      case "failed":
        return { backgroundColor: "#F44336", color: "#FFFFFF" };
      case "canceled":
        return { backgroundColor: "#FF9800", color: "#FFFFFF" };
      case "waiting":
        return { backgroundColor: "#2196F3", color: "#FFFFFF" };
      default:
        return {};
    }
  };

  const getLinkedAccount = (verification_state) => {
    switch (verification_state) {
      case "Unverified":
        return { backgroundColor: "#9e9e9e", color: "#fff" };
      case "Approved":
        return { backgroundColor: "#4caf50", color: "#fff" };
      case "Pending":
        return { backgroundColor: "#ff9800", color: "#fff" };
      case "Rejected":
        return { backgroundColor: "#f44336", color: "#fff" };
      default:
        return {};
    }
  };

  const [originalVerificationData, setOriginalVerificationData] = useState([]);

  // Function to handle reactivation confirmation
  const handleReactivateConfirm = async () => {
    try {
      await Restore(customerId); // Call Restore API

      // Update the customer state to mark it as restored
      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        deleted: false,
        deleteReason: null,
      }));

      // Show success snackbar
      setSnackbarMessage(`Customer ID ${customerId} reactivated successfully`);
      setSnackbarSeverity("success");
    } catch (error) {
      // Show error snackbar if Restore API fails
      setSnackbarMessage("Failed to restore the customer. Please try again.");
      setSnackbarSeverity("error");
    } finally {
      setOpenSnackbar(true); // Show the snackbar
      setOpenRestoreDialog(false); // Close the restore dialog
    }
  };

  const handleEdit = (data) => {
    setEditableData({
      customer_id: customerId, // Use customerId from useParams
      phoneverified: data.phoneverified,
      emailverified: data.emailverified,
      funnelpersonalinfo: data.funnelpersonalinfo,
      funnelpayment: data.funnelpayment,
      funnellicenseimage: data.funnellicenseimage,
    });

    setDialogOpen(true); // Open the dialog
  };

  const handleBlockEdit = (customer) => {
    if (customer.blocked) {
      setOpenUnblockDialog(true); // Show the unblock confirmation dialog
    } else {
      setBlockData({
        blocked: customer.blocked,
        blockreason: customer.blockreason || "",
      });
      setBlockDialogOpen(true); // Open block dialog
    }
  };

  const handleUnblockCustomer = async () => {
    try {
      await unblockCustomer(customerId);

      // Update customer state
      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        blocked: false,
        blockreason: null,
      }));

      // Show success message
      setSnackbarMessage("Customer unblocked successfully");
      setSnackbarSeverity("success");
    } catch (error) {
      setSnackbarMessage("Failed to unblock customer. Please try again.");
      setSnackbarSeverity("error");
    } finally {
      setOpenSnackbar(true);
      setOpenUnblockDialog(false);
    }
  };

  // Close Block Dialog
  const handleBlockDialogClose = () => {
    setBlockDialogOpen(false);
    setBlockData({ blocked: false, blockreason: "" });
  };

  // Save Block Status
  const handleBlockSave = async () => {
    if (blockData.blockreason) {
      try {
        await blockCustomer(customerId, blockData.blockreason);

        // Update customer state
        setCustomer((prevCustomer) => ({
          ...prevCustomer,
          blocked: true,
          blockreason: blockData.blockreason,
        }));

        // Show success message
        setSnackbarMessage("Customer has been successfully blocked.");
        setSnackbarSeverity("success");
      } catch (error) {
        console.error("Error blocking customer:", error);

        // Show error message
        setSnackbarMessage("Failed to block the customer. Please try again.");
        setSnackbarSeverity("error");
      }
    } else {
      setSnackbarMessage("Please provide a reason for blocking the customer.");
      setSnackbarSeverity("error");
    }

    setOpenSnackbar(true);
    setBlockDialogOpen(false);
  };

  // Close dialog
  const handleDialogClose = () => {
    setDialogOpen(false);
    setEditableData({});
  };

  // Handle input change in the dialog
  const handleInputChange = (field, value) => {
    setEditableData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSave = async () => {
    const idToUse = editableData.customer_id || customerId; // Fallback to customerId from useParams
    if (!idToUse) {
      setOpenSnackbar(true);
      setSnackbarMessage("Customer ID is missing. Cannot save funnels.");
      setSnackbarSeverity("error");
      return;
    }

    try {
      await editFunnels(
        idToUse, // Use the valid customerId
        editableData.phoneverified,
        editableData.emailverified,
        editableData.funnelpersonalinfo,
        editableData.funnelpayment,
        editableData.funnellicenseimage,
      );

      setOpenSnackbar(true);
      setSnackbarMessage("Funnels updated successfully!");
      setSnackbarSeverity("success");

      setDialogOpen(false);
    } catch (error) {
      setOpenSnackbar(true);
      setSnackbarMessage(
        error.message || "Failed to update funnels. Please try again.",
      );
      setSnackbarSeverity("error");
    }
  };

  // State for controlling which item is editable
  const [editableItemIndex, setEditableItemIndex] = useState(null);
  // eslint-disable-next-line
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  //READ API DATA
  const { data: customers, loading, error } = useCustomerData(customerId);
  const {
    data: reservations,
    loading: reservationLoading,
    error: reservationError,
    totalPages,
    totalCount,
  } = useReservationData(page, customerId);
  const {
    data: credits,
    loading: creditsLoading,
    error: creditsError,
    totalPages: creditTotalPages,
    totalCount: creditTotalCount,
    deleteCredit,
    createCredit,
  } = useCreditData(customerId, page);
  const {
    data: invoices,
    loading: invoiceLoading,
    error: invoiceError,
    totalPages: invoiceTotalPages,
    totalCount: invoiceTotalCount,
  } = useInvoiceData(customerId, page);
  const {
    data: devices,
    loading: deviceLoading,
    error: deviceError,
    totalPages: deviceTotalPages,
    totalCount: deviceTotalCount,
  } = useDeviceData(customerId, page);
  const {
    data: history,
    loading: historyLoading,
    error: historyError,
    totalPages: historyTotalPages,
    totalCount: historyTotalCount,
    createNote,
  } = useHistoryData(customerId, page);
  const {
    data: verificationData,
    loading: verificationLoading,
    error: verificationError,
    postVerificationData,
    updateData, // Destructure updateData here
  } = useVerificationData(customerId);
  const {
    data: payment,
    loading: paymentLoading,
    error: paymentError,
  } = usePaymentMethodData(customerId, 1);
  const {
    data: LinkedAccountData,
    loading: LinkedAccountDataLoading,
    error: LinkedAccountDataError,
  } = useLinkedAccountData(customerId);

  //ACTIVATE/DEACTIVATE CUSTOMER
  const [openDialogAction, setOpenDialogAction] = useState(false);
  const [actionType, setActionType] = useState("activate"); // Default action
  const [load, setLoad] = useState(false);

  const { activateCustomer } = useActivateCustomer();
  const { deactivateCustomer } = useDeactivateCustomer();

  // Set actionType based on `customer.activated`
  useEffect(() => {
    if (customer?.activated) {
      setActionType("deactivate");
    } else {
      setActionType("activate");
    }
  }, [customer?.activated]); // Sync when customer changes

  const handleOpenDialogAction = () => setOpenDialogAction(true);
  const handleCloseDialogAction = () => setOpenDialogAction(false);

  const handleToggleStatus = async () => {
    setLoad(true);
    if (actionType === "activate") {
      await activateCustomer(customer.id);
      setActionType("deactivate"); // Switch button to Deactivate
    } else {
      await deactivateCustomer(customer.id);
      setActionType("activate"); // Switch button to Activate
    }
    setLoad(false);
    handleCloseDialogAction();
  };

  //SHOWS CUSTOMER AND TAB NAVIAGATION VALUE
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();

  //SNACKBAR MESSAGE
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  //For Credits
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [newCreditValue, setNewCreditValue] = useState("");
  const [newReservationID, setNewReservationID] = useState("");
  const [newComment, setNewComment] = useState("");
  const [newReason, setNewReason] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [newCreditExpiration, setNewCreditExpiration] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [creditToDelete, setCreditToDelete] = useState(null);

  //CREATE NOTE AND DESCRIPTION IN HISORY
  const [openCreateNoteDialog, setCreateNoteDialog] = useState(false);
  const [newNoteDescription, setNewNoteDescription] = useState("");

  //STATUS IN VERIFICATION
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isVisibleFullImage, setVisibleFullImage] = useState(false);

  // Handle edit button click
  const handleEditClick = (index) => {
    if (originalVerificationData.length === 0) {
      setOriginalVerificationData([...verificationData]); // Backup only once
    }
    setEditableItemIndex(index);
  };

  // IMAGES FOR VERIFICATION
  const images = verificationData ? verificationData[0]?.images || [] : []; // Modify based on your data structure
  const handleImageClick = (index) => {
    setCurrentImageIndex(index); // Set the image index
    setVisibleFullImage(true); // Open the modal
  };

  //CREATE NOTE HANDLE
  const handleCreateNote = async () => {
    const result = await createNote(newNoteDescription, customer.id);
    if (result) {
      setSnackbarMessage(result);
      setSnackbarSeverity("error");
    } else {
      setSnackbarMessage("Note Created Successfully");
      setSnackbarSeverity("success");
    }
    setOpenSnackbar(true);
    setCreateNoteDialog(false);
    setNewNoteDescription("");
  };

  useEffect(() => {
    if (customers.length > 0 && customerId) {
      const id = parseInt(customerId, 10);
      const selectedCustomer = customers.find((cust) => cust.id === id);
      setCustomer(selectedCustomer || null);
    } else if (!customerId) {
      setCustomer(null);
    }
  }, [customers, customerId]);

  useEffect(() => {
    // Function to calculate the default expiration date (one year from today)
    const nextYear = new Date();
    nextYear.setFullYear(nextYear.getFullYear() + 1);
    const formattedDate = nextYear.toISOString().split("T")[0]; // Format to YYYY-MM-DD

    setNewCreditExpiration(formattedDate); // Set default expiration date on mount
  }, [openCreateDialog]); // Re-run this when the dialog is opened

  //DELETE CREDIT HANDLE
  const handleDeleteCredit = (id) => {
    setCreditToDelete(id);
    setOpenDeleteDialog(true);
  };

  useEffect(() => {
    if (openSnackbar && snackbarSeverity === "error") {
      return;
    }
  }, [openSnackbar, snackbarSeverity]);

  const formRef = useRef(null);

  const handleSaveClick = async (e) => {
    e.preventDefault();
    try {
      const result = await postVerificationData(verificationData[0]);
      if (result) {
        setSnackbarMessage("Verification data updated successfully");
        setSnackbarSeverity("success");
        setOriginalVerificationData([]); // Clear backup after saving
      }
    } catch (error) {
      setSnackbarMessage("Error updating verification data");
      setSnackbarSeverity("error");
    }
    setOpenSnackbar(true);
    setEditableItemIndex(null);
  };

  const handleCancelEdit = () => {
    // Revert each field to its original value
    originalVerificationData.forEach((item, index) => {
      updateData(index, { ...item });
    });
    setEditableItemIndex(null); // Exit edit mode
  };

  const onVerificationStateChange = (newValue, index) => {
    updateData(index, { verificationState: newValue });
  };

  const onNationalityChange = (newValue, index) => {
    updateData(index, { nationality: newValue });
  };

  const onCTypeChange = (newValue, index) => {
    updateData(index, { customer_type: newValue });
  };

  const onRejectionReasonChange = (newValue, index) => {
    updateData(index, { rejectionReason: newValue });
  };

  //CONFIRM DELETE CREDIT
  const handleConfirmDeleteCredit = async () => {
    if (creditToDelete) {
      const result = await deleteCredit(creditToDelete);
      if (result) {
        // If there's an error message returned
        setSnackbarMessage(result); // Set error message
        setSnackbarSeverity("error"); // Set severity to error
      } else {
        setSnackbarMessage(`${creditToDelete} has been archived`);
        setSnackbarSeverity("success");
      }
      setOpenSnackbar(true);
      setOpenDeleteDialog(false);
      setCreditToDelete(null);
    }
  };

  //CLOSE SNACKBAR
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  //TAB CHANGE
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  //PAGE CHANGE FOR PAGINATION
  const handlePageChange = (event, value) => {
    setPage(value);
    // Update the URL with the new page number
    navigate(`?page=${value}`);
  };

  const cardStyle = {
    mb: 2,
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
  };

  const headerStyle = {
    textTransform: "uppercase",
    fontWeight: "bold",
  };

  const getPlan = (plan) => {
    switch (plan) {
      case "Emirates Platinum":
        return { backgroundColor: "#607D8B", color: "#fff" }; // Dark Blue-Gray
      case "FACE":
        return { backgroundColor: "#2196F3", color: "#fff" }; // Bright Blue
      case "Udrive Employee Discount":
        return { backgroundColor: "#FF5722", color: "#fff" }; // Deep Orange
      case "Udrive Now Plan":
        return { backgroundColor: "#673AB7", color: "#fff" }; // Deep Purple
      default:
        return { backgroundColor: "#9E9E9E", color: "#fff" }; // Gray (for unknown plans)
    }
  };

  //CONFIRM CREAT CREDIT DIALOG
  const handleConfirmCreateCredit = async () => {
    const result = await createCredit(
      newCreditValue,
      newCreditExpiration,
      newReservationID,
      newReason,
      newComment,
      newCategory,
    );

    if (result) {
      setSnackbarMessage(result);
      setSnackbarSeverity("error");
    } else {
      setSnackbarMessage("Credit created successfully");
      setSnackbarSeverity("success");
    }

    setOpenSnackbar(true);
    resetForm();
    setOpenCreateDialog(false);
  };

  const resetForm = () => {
    setNewReservationID("");
    setNewCreditValue("");
    setNewReason("");
    setNewCreditExpiration("");
    setNewComment("");
  };

  if (creditsLoading) return <CircularProgress />;
  if (creditsError)
    return <Typography color="error">{creditsError}</Typography>;

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );

  if (error)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="h6" color="error">
          Error: {error}
        </Typography>
      </Box>
    );

  if (!customer && customerId)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="h6" color="grey">
          Loading...
        </Typography>
      </Box>
    );

  return (
    <Box sx={{ p: 2, mt: 5, ml: 1, mr: 1 }}>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontWeight: "bold", textTransform: "uppercase" }}
          >
            {customer?.firstname && customer?.lastname
              ? `${customer.firstname} ${customer.lastname}`
              : customer?.name || "Unknown"}{" "}
            | {customerId}
          </Typography>

          {/* Controls container */}
          <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
            {/* Deactivate/Activate Button */}
            <Button
              variant="contained"
              sx={{
                height: 36,
                backgroundColor:
                  actionType === "deactivate" ? "#FF3B3B" : "#006A4E",
                "&:hover": {
                  backgroundColor:
                    actionType === "deactivate" ? "#D32F2F" : "#17B169",
                },
              }}
              onClick={handleOpenDialogAction}
              disabled={load}
            >
              {load
                ? "Processing..."
                : actionType === "deactivate"
                  ? "Deactivate"
                  : "Activate"}
            </Button>

            {/* Edit Customer Button (Opens Dialog) */}
            <Button
              variant="contained"
              sx={{
                height: 36,
                backgroundColor: "#006A4E",
                "&:hover": { backgroundColor: "#17B169" },
              }}
              onClick={handleOpenDialog}
            >
              Edit Customer
            </Button>

            {/* Go Back Button */}
            <Button
              variant="contained"
              color="primary"
              sx={{ height: 36 }}
              onClick={() => window.history.back()}
            >
              Go Back
            </Button>
          </Box>

          {/* Activate/Deactivate Confirmation Dialog */}
          <Dialog
            open={openDialogAction}
            onClose={handleCloseDialogAction}
            fullWidth
            maxWidth="xs"
          >
            <DialogTitle
              sx={{ fontWeight: "bold", textAlign: "center", pb: 1 }}
            >
              {actionType === "deactivate"
                ? "Deactivate Customer"
                : "Activate Customer"}
            </DialogTitle>
            <DialogContent sx={{ textAlign: "center", px: 3, py: 2 }}>
              <Typography>
                Are you sure you want to{" "}
                {actionType === "deactivate" ? "deactivate" : "activate"} this
                customer?
              </Typography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", gap: 2, pb: 2 }}>
              <Button
                onClick={handleCloseDialogAction}
                color="secondary"
                variant="outlined"
                disabled={load}
              >
                Cancel
              </Button>
              <Button
                onClick={handleToggleStatus}
                color="primary"
                variant="contained"
                disabled={load}
              >
                {load
                  ? "Processing..."
                  : actionType === "deactivate"
                    ? "Deactivate"
                    : "Activate"}
              </Button>
            </DialogActions>
          </Dialog>

          {/* Edit Customer Dialog */}
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle
              sx={{ fontWeight: "bold", textAlign: "center", pb: 1 }}
            >
              Edit Customer
            </DialogTitle>

            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                mt: 1,
                px: 3,
                py: 2,
              }}
            >
              {/* IsDev Checkbox */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isDev}
                    onChange={(e) => handleChange("isDev", e.target.checked)}
                    color="primary"
                  />
                }
                label="IsDev"
                sx={{ fontSize: "14px", mb: 1 }}
              />

              {/* Plan Dropdown */}
              <FormControl fullWidth variant="outlined" margin="dense">
                <InputLabel>Plan</InputLabel>
                <Select
                  value={plan}
                  onChange={(e) => handleChange("plan", e.target.value)}
                  label="Plan"
                >
                  <MenuItem value="" disabled>
                    Select Plan
                  </MenuItem>
                  <MenuItem value="Emirates Platinum">
                    Emirates Platinum
                  </MenuItem>
                  <MenuItem value="FACE">FACE</MenuItem>
                  <MenuItem value="Udrive Employee Discount">
                    Udrive Employee Discount
                  </MenuItem>
                  <MenuItem value="Udrive Now Plan">Udrive Now Plan</MenuItem>
                </Select>
              </FormControl>

              {/* Location Dropdown */}
              <FormControl fullWidth variant="outlined" margin="dense">
                <InputLabel>Location</InputLabel>
                <Select
                  value={location}
                  onChange={(e) => handleChange("location", e.target.value)}
                  label="Location"
                >
                  <MenuItem value="" disabled>
                    Select Location
                  </MenuItem>
                  <MenuItem value="Dubai">Dubai</MenuItem>
                  <MenuItem value="Sharjah">Sharjah</MenuItem>
                  <MenuItem value="Abu Dhabi">Abu Dhabi</MenuItem>
                  <MenuItem value="Ajman">Ajman</MenuItem>
                  <MenuItem value="Drivers">Drivers</MenuItem>
                </Select>
              </FormControl>
            </DialogContent>

            <DialogActions
              sx={{ justifyContent: "flex-end", gap: 2, pb: 2, pr: 3 }}
            >
              <Button
                onClick={handleCloseDialog}
                color="secondary"
                variant="outlined"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {/* Confirmation Dialog */}
          <Dialog
            open={confirmDialogOpen}
            onClose={handleConfirmDialogClose}
            maxWidth="xs" // Set width to extra small (xs)
            fullWidth
          >
            <DialogTitle
              sx={{
                fontSize: "1.1rem",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Confirm Update
            </DialogTitle>

            <DialogContent sx={{ textAlign: "center", py: 2 }}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Are you sure you want to update this information?
              </Typography>

              {pendingUpdate && (
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", color: "primary.main" }}
                >
                  Changing{" "}
                  <span style={{ color: "#d32f2f" }}>
                    {pendingUpdate.field}
                  </span>{" "}
                  to{" "}
                  <span style={{ color: "#388e3c" }}>
                    {String(pendingUpdate.value)}
                  </span>
                </Typography>
              )}
            </DialogContent>

            <DialogActions sx={{ justifyContent: "center", pb: 2 }}>
              <Button
                onClick={handleConfirmDialogClose}
                color="secondary"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirmUpdate}
                variant="contained"
                color="primary"
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Box>

        <Box sx={{ overflowX: "auto", whiteSpace: "nowrap", mt: 2 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ minWidth: "max-content" }}
          >
            <Tab label="Details" />
            <Tab label="Reservations" />
            <Tab label="Credits" />
            <Tab label="Invoices" />
            <Tab label="Devices" />
            <Tab label="History" />
            <Tab label="Verifications" />
            <Tab label="Payment Method" />
          </Tabs>
        </Box>
      </Box>

      {/* Tab 0 Details */}
      {tabValue === 0 && customer && (
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2}>
            {/* Basic Information */}
            <Grid item xs={12} sm={6}>
              <Card variant="outlined" sx={{ ...cardStyle, height: "100%" }}>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5" gutterBottom sx={headerStyle}>
                      Basic Information
                    </Typography>
                    <Box
                      sx={{
                        px: 2, // Adds horizontal padding
                        py: 0.5, // Adds slight vertical padding
                        borderRadius: "6px", // Rounded corners
                        ...getPlan(customer?.plan), // Dynamically set background & text color
                      }}
                    >
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        {customer?.plan || "N/A"}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider sx={{ mb: 1, mt: 1 }} />
                  <Typography
                    variant="body1"
                    display="flex"
                    alignItems="center"
                  >
                    <BadgeOutlinedIcon sx={{ mr: 1 }} />
                    <strong>Name:</strong>
                    <span style={{ marginLeft: "auto" }}>
                      {customer.firstname && customer.lastname
                        ? `${customer.firstname} ${customer.lastname}`
                        : customer.name}
                    </span>
                  </Typography>
                  <Typography
                    variant="body1"
                    display="flex"
                    alignItems="center"
                  >
                    <MailIcon sx={{ mr: 1 }} />
                    <strong>Email:</strong>
                    <span style={{ marginLeft: "auto" }}>{customer.email}</span>
                  </Typography>
                  <Typography
                    variant="body1"
                    display="flex"
                    alignItems="center"
                  >
                    <PhoneIcon sx={{ mr: 1 }} />
                    <strong>Phone:</strong>
                    <span style={{ marginLeft: "auto" }}>{customer.phone}</span>
                  </Typography>
                  <Typography
                    variant="body1"
                    display="flex"
                    alignItems="center"
                  >
                    <CalendarTodayIcon sx={{ mr: 1 }} />
                    <strong>Date of Birth:</strong>
                    <span style={{ marginLeft: "auto" }}>
                      {customer.birthdate}
                    </span>
                  </Typography>
                  <Typography
                    variant="body1"
                    display="flex"
                    alignItems="center"
                  >
                    <LocationOnIcon sx={{ mr: 1 }} />
                    <strong>Location:</strong>
                    <span style={{ marginLeft: "auto" }}>
                      {customer.location || customer.place}
                    </span>
                  </Typography>
                  <Typography
                    variant="body1"
                    display="flex"
                    alignItems="center"
                  >
                    <LanguageIcon sx={{ mr: 1 }} />
                    <strong>Language:</strong>
                    <span style={{ marginLeft: "auto" }}>
                      {customer.language}
                    </span>
                  </Typography>
                  <Typography
                    variant="body1"
                    display="flex"
                    alignItems="center"
                  >
                    <VerifiedIcon sx={{ mr: 1 }} />
                    <strong>Verification State:</strong>
                    <span style={{ marginLeft: "auto" }}>
                      {customer.verificationState || customer.state}
                    </span>
                  </Typography>
                  <Typography
                    variant="body1"
                    display="flex"
                    alignItems="center"
                  >
                    <AgentIcon sx={{ mr: 1 }} />
                    <strong>Activated</strong>
                    <span style={{ marginLeft: "auto" }}>
                      {customer.activated ? "True" : "False"}
                    </span>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Driver’s License Information */}
            <Grid item xs={12} sm={6}>
              <Card variant="outlined" sx={{ ...cardStyle, height: "100%" }}>
                <CardContent>
                  <Typography variant="h5" gutterBottom sx={headerStyle}>
                    Driver’s License Information
                  </Typography>
                  <Divider sx={{ mb: 2 }} />
                  {[
                    {
                      label: "License Number",
                      value: customer.licensenumber || "N/A",
                    },
                    {
                      label: "License Issue Date",
                      value: customer.licenseissuedate || "N/A",
                    },
                    {
                      label: "License Expiration Date",
                      value: customer.licenseexpiration || "N/A",
                    },
                  ].map((field, idx) => (
                    <Typography
                      key={idx}
                      variant="body1"
                      display="flex"
                      alignItems="center"
                      sx={{ mb: 1 }}
                    >
                      <strong>{field.label}:</strong>
                      <span style={{ marginLeft: "auto" }}>{field.value}</span>
                    </Typography>
                  ))}
                </CardContent>
              </Card>
            </Grid>

            {/* Other Sections */}
            {[
              {
                title: "Registration & Verification",
                fields: [
                  {
                    label: "Registration Date",
                    value: customer.registrationdate,
                  },
                  {
                    label: "Customer Type",
                    value: customer.customertype || "N/A",
                  },
                ],
              },
              {
                title: "Block Status",
                fields: [
                  { label: "Blocked", value: customer.blocked ? "Yes" : "No" },
                  {
                    label: "Reason for Block",
                    value: customer.blockreason || "N/A",
                  },
                ],
                action: (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: customer.blocked ? "green" : "red",
                      color: "white",
                      "&:hover": {
                        backgroundColor: customer.blocked
                          ? "darkgreen"
                          : "darkred",
                      },
                      mb: 1,
                    }}
                    onClick={() => handleBlockEdit(customer)} // Opens the block dialog
                  >
                    {customer.blocked ? "Unblock" : "Block"}
                  </Button>
                ),
              },
              {
                title: "Delete Status",
                fields: [
                  { label: "Deleted", value: customer.deleted ? "Yes" : "No" },
                  {
                    label: "Reason for Delete",
                    value: customer.deleteReason || "N/A",
                  },
                  {
                    label: "Delete Date",
                    value: customer.deleted_at || "N/A",
                  },
                ],
                action: customer.deleted && (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "green",
                      color: "white",
                      "&:hover": { backgroundColor: "darkgreen" },
                      mb: 1,
                    }}
                    onClick={() => setOpenRestoreDialog(true)} // Open the reactivation dialog
                  >
                    Restore
                  </Button>
                ),
              },
              {
                title: "Personal Info",
                fields: [
                  { label: "Personal Id", value: customer.personalid || "N/A" },
                  {
                    label: "Personal Id Issue",
                    value: customer.personalidissue || "N/A",
                  },
                  {
                    label: "Personal Id Expiration",
                    value: customer.personalidexpiration || "N/A",
                  },
                ],
              },
            ].map((section, idx) => (
              <Grid item xs={12} sm={6} key={idx}>
                <Card variant="outlined" sx={{ ...cardStyle, height: "100%" }}>
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h5" gutterBottom sx={headerStyle}>
                        {section.title}
                      </Typography>
                      {section.action}
                    </Box>
                    <Divider sx={{ mb: 2 }} />
                    {section.fields.map((field, index) => (
                      <Typography
                        key={index}
                        variant="body1"
                        display="flex"
                        alignItems="center"
                        sx={{ mb: 1 }}
                      >
                        <strong>{field.label}:</strong>
                        <span style={{ marginLeft: "auto" }}>
                          {field.value}
                        </span>
                      </Typography>
                    ))}
                  </CardContent>
                </Card>
              </Grid>
            ))}

            {/* Funnels Section */}
            <Grid item xs={12} sm={6}>
              <Card variant="outlined" sx={{ ...cardStyle, height: "100%" }}>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5" gutterBottom sx={headerStyle}>
                      Funnels
                    </Typography>
                    <IconButton onClick={() => handleEdit(customer)}>
                      <EditOutlinedIcon />
                    </IconButton>
                  </Box>
                  <Divider sx={{ mb: 2 }} />
                  {[
                    { label: "Email Verified", value: customer.emailverified },
                    { label: "Phone Verified", value: customer.phoneverified },
                    {
                      label: "Personal Info",
                      value: customer.funnelpersonalinfo,
                    },
                    { label: "Payment", value: customer.funnelpayment },
                    {
                      label: "License Image",
                      value: customer.funnellicenseimage,
                    },
                  ].map((field, idx) => (
                    <Typography
                      key={idx}
                      variant="body1"
                      display="flex"
                      alignItems="center"
                      sx={{ mb: 1 }}
                    >
                      <strong>{field.label}:</strong>
                      <span style={{ marginLeft: "auto" }}>
                        {field.value ? "Yes" : "No"}
                      </span>
                    </Typography>
                  ))}
                </CardContent>
              </Card>
            </Grid>

            {/* Ban Status */}
            <Grid item xs={12} sm={6}>
              <Card variant="outlined" sx={{ ...cardStyle, height: "100%" }}>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5" gutterBottom sx={headerStyle}>
                      Ban Status
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: customer.banned ? "green" : "red",
                        color: "white",
                        "&:hover": {
                          backgroundColor: customer.banned
                            ? "darkgreen"
                            : "darkred",
                        },
                        mb: 1,
                      }}
                    >
                      {customer.banned ? "Unban" : "Ban"}
                    </Button>
                  </Box>
                  <Divider sx={{ mb: 2 }} />
                  {[
                    { label: "Banned", value: customer.banned ? "Yes" : "No" },
                    {
                      label: "Reason for Ban",
                      value: customer.banReason || "N/A",
                    },
                    { label: "Ban Date", value: customer.banned_at || "N/A" },
                  ].map((field, index) => (
                    <Typography
                      key={index}
                      variant="body1"
                      display="flex"
                      alignItems="center"
                      sx={{ mb: 1 }}
                    >
                      <strong>{field.label}:</strong>
                      <span style={{ marginLeft: "auto" }}>{field.value}</span>
                    </Typography>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Block Dialog */}
          <Dialog
            open={blockDialogOpen}
            onClose={handleBlockDialogClose}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle sx={{ fontWeight: "bold" }}>
              Customer Block Reason
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ py: 3, px: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    value={blockData.blockreason}
                    onChange={(e) =>
                      setBlockData({
                        ...blockData,
                        blockreason: e.target.value,
                      })
                    }
                    label="Block Reason"
                    sx={{
                      backgroundColor: "#f9f9f9",
                      "& .MuiInputBase-root": {
                        fontSize: "1rem",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#ddd",
                        },
                        "&:hover fieldset": {
                          borderColor: "#aaa",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#3f51b5",
                        },
                      },
                      "& .MuiFormLabel-root": {
                        fontSize: "0.9rem",
                        color: "#666",
                      },
                      "& .MuiMenuItem-root": {
                        fontSize: "0.9rem",
                      },
                    }}
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="Expired Documents">
                      Expired Documents
                    </MenuItem>
                    <MenuItem value="Unpaid bills">Unpaid bills</MenuItem>
                    <MenuItem value="Violated Terms of Service">
                      Violated Terms of Service
                    </MenuItem>
                    <MenuItem value="Migrated block from Udrive system">
                      Migrated block from Udrive system
                    </MenuItem>
                    <MenuItem value="Potential Fraud">Potential Fraud</MenuItem>
                    <MenuItem value="Registered using a duplicate card">
                      Registered using a duplicate card
                    </MenuItem>
                    <MenuItem value="Card Re-Registration Required">
                      Card Re-Registration Required
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 2 }}>
              <Button
                onClick={handleBlockDialogClose}
                variant="outlined"
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                onClick={handleBlockSave}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>

          {/* Unblock Dialog */}
          <Dialog
            open={openUnblockDialog}
            onClose={() => setOpenUnblockDialog(false)}
            maxWidth="xs"
            fullWidth
            PaperProps={{
              sx: {
                borderRadius: 3,
                p: 3,
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <DialogTitle
              sx={{ fontWeight: "bold", textAlign: "center", pb: 0 }}
            >
              Confirm Unblock
            </DialogTitle>
            <DialogContent sx={{ textAlign: "center", py: 3 }}>
              <Typography variant="body1" sx={{ mt: 1 }}>
                Are you sure you want to unblock this customer?
              </Typography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", px: 3, pb: 2 }}>
              <Button
                onClick={() => setOpenUnblockDialog(false)}
                variant="outlined"
                color="secondary"
                sx={{
                  textTransform: "none",
                  borderRadius: 2,
                  px: 3,
                  py: 1,
                  borderColor: "#ddd",
                  "&:hover": {
                    borderColor: "#bbb",
                    backgroundColor: "#f5f5f5",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleUnblockCustomer();
                  setOpenUnblockDialog(false);
                }}
                variant="contained"
                color="primary"
                sx={{
                  textTransform: "none",
                  borderRadius: 2,
                  px: 3,
                  py: 1,
                  backgroundColor: "#3f51b5",
                  "&:hover": {
                    backgroundColor: "#303f9f",
                  },
                }}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          {/* Restore Dialog */}
          <Dialog
            open={openRestoreDialog}
            onClose={() => setOpenRestoreDialog(false)}
            maxWidth="xs"
            fullWidth
            PaperProps={{
              sx: {
                borderRadius: 3,
                p: 3,
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <DialogTitle
              sx={{ fontWeight: "bold", textAlign: "center", pb: 0 }}
            >
              Confirm Reactivation
            </DialogTitle>
            <DialogContent sx={{ textAlign: "center", py: 3 }}>
              <Typography variant="body1" sx={{ mt: 1 }}>
                Are you sure you want to reactivate this customer?
              </Typography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", px: 3, pb: 2 }}>
              <Button
                onClick={() => setOpenRestoreDialog(false)}
                variant="outlined"
                sx={{
                  textTransform: "none",
                  borderRadius: 2,
                  px: 3,
                  py: 1,
                  borderColor: "#ddd",
                  "&:hover": {
                    borderColor: "#bbb",
                    backgroundColor: "#f5f5f5",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleReactivateConfirm} // Call updated handleReactivateConfirm
                variant="contained"
                sx={{
                  textTransform: "none",
                  borderRadius: 2,
                  px: 3,
                  py: 1,
                  backgroundColor: "#3f51b5",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#303f9f",
                  },
                }}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          {/* Dialog for Editing */}
          <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle sx={{ fontWeight: "bold" }}>
              Edit Funnels Information
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ py: 3, px: 4 }}>
              <Grid container spacing={3}>
                {[
                  { label: "Email Verified", field: "emailverified" },
                  { label: "Phone Verified", field: "phoneverified" },
                  {
                    label: "Personal Info Provided",
                    field: "funnelpersonalinfo",
                  },
                  { label: "Payment Done", field: "funnelpayment" },
                  {
                    label: "License Image Uploaded",
                    field: "funnellicenseimage",
                  },
                ].map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        justifyContent: "space-between",
                        backgroundColor: "#f9f9f9",
                        p: 2,
                        borderRadius: 2,
                        boxShadow: 1,
                      }}
                    >
                      <Typography>{item.label}</Typography>
                      <Tooltip
                        title={
                          [
                            "emailverified",
                            "funnelpersonalinfo",
                            "funnelpayment",
                          ].includes(item.field)
                            ? "This field cannot be edited"
                            : `Mark as ${
                                editableData[item.field]
                                  ? "Unchecked"
                                  : "Checked"
                              }`
                        }
                        arrow
                      >
                        <Checkbox
                          checked={Boolean(editableData[item.field])}
                          onChange={(e) =>
                            handleInputChange(item.field, e.target.checked)
                          }
                          color="primary"
                          disabled={[
                            "emailverified",
                            "funnelpersonalinfo",
                            "funnelpayment",
                          ].includes(item.field)}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                        />
                      </Tooltip>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 2 }}>
              <Button
                onClick={handleDialogClose}
                variant="outlined"
                color="secondary"
                sx={{ textTransform: "none" }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                variant="contained"
                color="primary"
                disabled={loading} // Disable the button when the API call is in progress
                sx={{ textTransform: "none" }}
              >
                {loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Save"
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}

      {/* Tab 1 Reservations */}
      {tabValue === 1 && (
        <Box sx={{ p: 2 }}>
          {reservationLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <CircularProgress />
            </Box>
          ) : reservationError ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <Typography variant="h6" color="error">
                Error: {reservationError}
              </Typography>
            </Box>
          ) : reservations.length === 0 ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <Typography variant="h6" color="textSecondary">
                No reservations found
              </Typography>
            </Box>
          ) : (
            <Box>
              <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
                <Table
                  sx={{ width: "auto", minWidth: "100%", maxWidth: "100%" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                      >
                        Customer ID
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                      >
                        Reservation ID
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                      >
                        Plate
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                      >
                        Start Time
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                      >
                        End Time
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                      >
                        Distance
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                      >
                        Location
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                      >
                        Is Dev
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                      >
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reservations.map((reservation) => (
                      <TableRow
                        key={reservation.reservationID}
                        onClick={() =>
                          navigate(`/reservation/${reservation.reservationID}`)
                        }
                        sx={{
                          cursor: "pointer",
                          "&:hover": { backgroundColor: "#f5f5f5" },
                        }}
                      >
                        <TableCell>{reservation.custID}</TableCell>
                        <TableCell>{reservation.reservationID}</TableCell>
                        <TableCell>{reservation.plate || "N/A"}</TableCell>
                        <TableCell>{reservation.starttime || "N/A"}</TableCell>
                        <TableCell>{reservation.endtime || "N/A"}</TableCell>
                        <TableCell>{reservation.distance || "N/A"}</TableCell>
                        <TableCell>{reservation.location || "N/A"}</TableCell>
                        <TableCell>
                          {reservation.is_dev ? "Yes" : "No"}
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: "inline-block",
                              borderRadius: "4px",
                              padding: "4px 8px",
                              ...getStatusStyles(reservation.reservationState),
                              textAlign: "center",
                              width: "auto",
                            }}
                          >
                            {reservation.reservationState || "N/A"}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}

          {/* Pagination Section */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mt: 3, mb: 3 }}
          >
            {/* Element aligned to flex-start */}
            <Box>
              <Typography
                variant="body3"
                color="textSecondary"
                fontWeight="bold"
              >
                Total Records: {totalCount}
              </Typography>
            </Box>
            {/* Element aligned to flex-end */}
            <Box display="flex" alignItems="center">
              <Stack spacing={2}>
                <Pagination
                  count={totalPages > 0 ? totalPages : 1}
                  page={page}
                  onChange={handlePageChange}
                  variant="outlined"
                  color="primary"
                  boundaryCount={2}
                  siblingCount={1}
                />
              </Stack>
            </Box>
          </Box>
        </Box>
      )}

      {/* Tab 2 Credits */}
      {tabValue === 2 && (
        <Box>
          {/* Total Credits Info Box & Add Credits Button */}
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap={1} // Reduced spacing for a more compact look
            sx={{ mb: 2 }} // Reduced top and bottom margin
          >
            {/* Total Credits Info - More Compact */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "6px 12px", // Reduced padding
                backgroundColor: "#1434A4",
                borderRadius: "6px", // Slightly smaller rounded edges
                boxShadow: 1,
                height: "32px", // Reduced height
                minWidth: "140px", // Smaller width
                maxWidth: "160px",
              }}
            >
              <Typography variant="body2" color="white" fontSize="0.85rem">
                Available Credits:
              </Typography>
              <Typography
                variant="body2"
                fontWeight="bold"
                color="white"
                fontSize="0.85rem"
              >
                {customer?.credits ?? 0}
              </Typography>
            </Box>

            {/* Add Credits Button (Smaller to Match) */}
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenCreateDialog(true)}
              sx={{
                height: "32px", // Reduced height
                minWidth: "100px", // Slightly smaller width
                fontSize: "0.85rem", // Smaller font
                padding: "4px 8px", // Compact padding
              }}
            >
              Add Credits
            </Button>
          </Box>

          {creditsLoading ? (
            <CircularProgress />
          ) : creditsError ? (
            <Typography color="error">Error: {creditsError}</Typography>
          ) : (
            <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
              <Table sx={{ width: "auto", minWidth: "100%", maxWidth: "100%" }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                    >
                      Grant Date
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                    >
                      Granted By
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                    >
                      Category
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                    >
                      Reason
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                    >
                      Reservation ID
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                    >
                      Comments
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                    >
                      Value
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                    >
                      Expiration Date
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                    >
                      Archived By
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                    >
                      Archived At
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {credits.map((credit) => (
                    <TableRow key={credit.credit_voucher_id}>
                      <TableCell>
                        {credit.grant_date.toLocaleString()}
                      </TableCell>
                      <TableCell>{credit.granted_by}</TableCell>
                      <TableCell>{credit.category ?? "N/A"}</TableCell>
                      <TableCell>{credit.reason ?? "N/A"}</TableCell>
                      <TableCell>{credit.reservation_id ?? "N/A"}</TableCell>
                      <TableCell>{credit.comments ?? "N/A"}</TableCell>
                      <TableCell>
                        {(credit.credits_utilized ?? "0") +
                          " / " +
                          credit.value}
                      </TableCell>
                      <TableCell>
                        {credit.expiration_date.toLocaleString()}
                      </TableCell>
                      <TableCell>{credit.archived_by ?? "N/A"}</TableCell>
                      <TableCell>{credit.archived_at ?? "N/A"}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            handleDeleteCredit(credit.credit_voucher_id)
                          }
                          disabled={credit.archived}
                          style={{
                            color: credit.archived ? "lightgray" : "red",
                          }}
                        >
                          <ArchiveOutlinedIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {/* Pagination Section */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mt: 3, mb: 3 }}
          >
            {/* Element aligned to flex-start */}
            <Box>
              <Typography
                variant="body3"
                color="textSecondary"
                fontWeight="bold"
              >
                Total Records: {creditTotalCount}
              </Typography>
            </Box>
            {/* Element aligned to flex-end */}
            <Box display="flex" alignItems="center">
              <Stack spacing={2}>
                <Pagination
                  count={creditTotalPages > 0 ? creditTotalPages : 1}
                  page={page}
                  onChange={handlePageChange}
                  variant="outlined"
                  color="primary"
                  boundaryCount={2}
                  siblingCount={1}
                />
              </Stack>
            </Box>
          </Box>
        </Box>
      )}

      {/* Create Credit Dialog */}
      <Dialog
        open={openCreateDialog}
        onClose={() => {
          resetForm(); // Reset form when dialog is closed directly
          setOpenCreateDialog(false);
        }}
      >
        <DialogTitle
          style={{
            fontWeight: "bold",
            fontSize: "1.5rem",
            textAlign: "center",
          }}
        >
          Create Credit
        </DialogTitle>
        <DialogContent style={{ padding: "24px" }}>
          <TextField
            margin="dense"
            label="Reservation ID"
            type="number"
            fullWidth
            variant="outlined"
            value={newReservationID}
            onChange={(e) => setNewReservationID(e.target.value)}
            style={{ marginBottom: "16px", borderRadius: "4px" }}
            error={newReservationID === ""}
            helperText={
              newReservationID === "" ? "Reservation ID is required" : ""
            }
          />
          <TextField
            autoFocus
            margin="dense"
            label="Credit Value"
            type="number"
            fullWidth
            variant="outlined"
            value={newCreditValue}
            onChange={(e) => setNewCreditValue(e.target.value)}
            style={{ marginBottom: "16px", borderRadius: "4px" }}
            error={newCreditValue === ""}
            helperText={newCreditValue === "" ? "Credit Value is required" : ""}
          />
          <FormControl
            margin="dense"
            fullWidth
            variant="outlined"
            style={{ marginBottom: "16px", borderRadius: "4px" }}
            error={!newCategory}
            required
          >
            <InputLabel>Category</InputLabel>
            <Select
              value={newCategory}
              onChange={(e) => {
                setNewCategory(e.target.value);
                setNewReason("");
              }}
              label="Category"
            >
              <MenuItem value="" disabled>
                Select a Category
              </MenuItem>
              <MenuItem value="Adjustment">Adjustment</MenuItem>
              <MenuItem value="Compensation">Compensation</MenuItem>
              <MenuItem value="Marketing">Marketing</MenuItem>
              <MenuItem value="Reimbursement">Reimbursement</MenuItem>
            </Select>
            {!newCategory && (
              <FormHelperText>Please select a category</FormHelperText>
            )}
          </FormControl>

          <FormControl
            margin="dense"
            fullWidth
            variant="outlined"
            style={{ marginBottom: "16px", borderRadius: "4px" }}
            error={!newReason}
            required
            disabled={!newCategory}
          >
            <InputLabel>Reason</InputLabel>
            <Select
              value={newReason}
              onChange={(e) => setNewReason(e.target.value)}
              label="Reason"
            >
              <MenuItem value="" disabled>
                Select a Reason
              </MenuItem>
              {newCategory === "Adjustment" &&
                [
                  "Admin fees",
                  "Penalties",
                  "Accidental Trip Extension",
                  "Excess Bank Transfer",
                  "Unused Trip",
                  "Subscribe Now",
                  "Service required (Operations)",
                  "Car Replacement",
                ].map((reason) => (
                  <MenuItem key={reason} value={reason}>
                    {reason}
                  </MenuItem>
                ))}
              {newCategory === "Compensation" &&
                [
                  "Dead Battery",
                  "OverHeating",
                  "Underneath noise",
                  "Radio/Blutooth not working",
                  "A/C Not working",
                  "Dirty Car",
                  "Flat Tire",
                  "Wrong Location",
                  "Device not responding",
                  "Reviews",
                ].map((reason) => (
                  <MenuItem key={reason} value={reason}>
                    {reason}
                  </MenuItem>
                ))}
              {newCategory === "Marketing" &&
                [
                  "Welcome Credits",
                  "Come back Credits",
                  "Activation Credits",
                  "Seasonal Credits",
                ].map((reason) => (
                  <MenuItem key={reason} value={reason}>
                    {reason}
                  </MenuItem>
                ))}
              {newCategory === "Reimbursement" &&
                ["Fuel", "Parking", "Taxi", "Car wash"].map((reason) => (
                  <MenuItem key={reason} value={reason}>
                    {reason}
                  </MenuItem>
                ))}
            </Select>
            {!newReason && (
              <FormHelperText>Please select a reason</FormHelperText>
            )}
          </FormControl>
          <TextField
            margin="dense"
            label="Expiration Date"
            type="date"
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={newCreditExpiration}
            onChange={(e) => setNewCreditExpiration(e.target.value)}
            style={{ marginBottom: "16px", borderRadius: "4px" }}
            error={newCreditExpiration === ""}
            helperText={
              newCreditExpiration === "" ? "Expiration Date is required" : ""
            }
          />
          <TextField
            margin="dense"
            label="Comment"
            type="text"
            fullWidth
            variant="outlined"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            style={{ marginBottom: "16px", borderRadius: "4px" }}
          />
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "space-between", padding: "16px 24px" }}
        >
          <Button
            onClick={() => {
              resetForm();
              setOpenCreateDialog(false);
            }}
            color="primary"
            style={{
              textTransform: "none",
              backgroundColor: "#e50000",
              color: "white",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmCreateCredit}
            color="primary"
            style={{
              textTransform: "none",
              backgroundColor: "#3f51b5",
              color: "white",
            }}
            disabled={newCreditValue === "" || newCreditExpiration === ""}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Credit Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this credit?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDeleteCredit} color="error">
            YES
          </Button>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            NO
          </Button>
        </DialogActions>
      </Dialog>

      {/* Tab 3 invoice */}
      {tabValue === 3 && (
        <Box sx={{ p: 2 }}>
          {/* Customer Summary Cards */}
          <Box display="flex" gap={2} mb={2}>
            {/* Failed Amount Card */}
            <Card sx={{ flex: 1, p: 2, textAlign: "center" }}>
              <Typography variant="h6" color="error" fontWeight="bold">
                Failed Amount
              </Typography>
              <Typography variant="h4" fontWeight="bold">
                AED {customer?.failedAmount ?? "0.00"}
              </Typography>
            </Card>

            {/* Pending Invoices Card */}
            <Card sx={{ flex: 1, p: 2, textAlign: "center" }}>
              <Typography variant="h6" color="#FFA500" fontWeight="bold">
                Pending Invoices
              </Typography>
              <Typography variant="h4" fontWeight="bold">
                {customer?.pendingInvoices ?? 0}
              </Typography>
            </Card>
          </Box>

          {invoiceLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <CircularProgress />
            </Box>
          ) : invoiceError ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <Typography variant="h6" color="error">
                Error: {invoiceError}
              </Typography>
            </Box>
          ) : invoices.length === 0 ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <Typography variant="h6" color="textSecondary">
                No invoices found
              </Typography>
            </Box>
          ) : (
            <Box>
              <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
                <Table
                  sx={{ width: "auto", minWidth: "100%", maxWidth: "100%" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                      >
                        Invoice ID
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                      >
                        Amount
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                      >
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoices.map((invoice) => (
                      <TableRow
                        key={invoice.invoice_id}
                        onClick={() =>
                          navigate(`/invoice/${invoice.invoice_id}`, {
                            state: { invoiceData: invoice },
                          })
                        }
                        sx={{
                          cursor: "pointer",
                          "&:hover": { backgroundColor: "#f5f5f5" },
                        }}
                      >
                        <TableCell>{invoice.invoice_id}</TableCell>
                        <TableCell>{invoice.total_price}</TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: "inline-block",
                              borderRadius: "4px",
                              padding: "4px 8px",
                              ...getInvoiceStatus(invoice.status),
                              textAlign: "center",
                            }}
                          >
                            {invoice.status}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}

          {/* Pagination Section */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mt: 3, mb: 3 }}
          >
            {/* Element aligned to flex-start */}
            <Box>
              <Typography
                variant="body3"
                color="textSecondary"
                fontWeight="bold"
              >
                Total Records: {invoiceTotalCount}
              </Typography>
            </Box>
            {/* Element aligned to flex-end */}
            <Box display="flex" alignItems="center">
              <Stack spacing={2}>
                <Pagination
                  count={invoiceTotalPages > 0 ? invoiceTotalPages : 1}
                  page={page}
                  onChange={handlePageChange}
                  variant="outlined"
                  color="primary"
                  boundaryCount={2}
                  siblingCount={1}
                />
              </Stack>
            </Box>
          </Box>
        </Box>
      )}

      {/* Tab 4 device */}
      {tabValue === 4 && (
        <Box sx={{ p: 2 }}>
          {deviceLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <CircularProgress />
            </Box>
          ) : deviceError ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <Typography variant="h6" color="error">
                Error: {deviceError}
              </Typography>
            </Box>
          ) : devices.length === 0 ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <Typography variant="h6" color="textSecondary">
                No devices found
              </Typography>
            </Box>
          ) : (
            <Box>
              <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
                <Table
                  sx={{ width: "auto", minWidth: "100%", maxWidth: "100%" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                      >
                        Device ID
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                      >
                        Device Model
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                      >
                        Created at
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {devices.map((device) => (
                      <TableRow>
                        <TableCell>{device.device_id}</TableCell>
                        <TableCell>{device.device_model}</TableCell>
                        <TableCell>{device.created_at || "N/A"}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
          {/* Pagination Section */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mt: 3, mb: 3 }}
          >
            {/* Element aligned to flex-start */}
            <Box>
              <Typography
                variant="body3"
                color="textSecondary"
                fontWeight="bold"
              >
                Total Records: {deviceTotalCount}
              </Typography>
            </Box>
            {/* Element aligned to flex-end */}
            <Box display="flex" alignItems="center">
              <Stack spacing={2}>
                <Pagination
                  count={deviceTotalPages > 0 ? deviceTotalPages : 1}
                  page={page}
                  onChange={handlePageChange}
                  variant="outlined"
                  color="primary"
                  boundaryCount={2}
                  siblingCount={1}
                />
              </Stack>
            </Box>
          </Box>
        </Box>
      )}

      {/* Tab 5 history */}
      {tabValue === 5 && (
        <Box sx={{ p: 2, pt: 0 }}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginBottom: "16px", float: "right" }}
            onClick={() => setCreateNoteDialog(true)}
          >
            NEW NOTE +
          </Button>
          {historyLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <CircularProgress />
            </Box>
          ) : historyError ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <Typography variant="h6" color="error">
                Error: {historyError}
              </Typography>
            </Box>
          ) : history.length === 0 ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <Typography variant="h6" color="textSecondary">
                No History found
              </Typography>
            </Box>
          ) : (
            <Box>
              <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
                <Table
                  sx={{ width: "auto", minWidth: "100%", maxWidth: "100%" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                      >
                        Created at
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                      >
                        Note
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                      >
                        Created By
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {history.map((history) => (
                      <TableRow key={history.created_at}>
                        <TableCell>{history.created_at || "N/A"}</TableCell>
                        <TableCell>{history.note}</TableCell>
                        <TableCell>{history.created_by || "N/A"}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
          {/* Pagination Section */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mt: 3, mb: 3 }}
          >
            {/* Element aligned to flex-start */}
            <Box>
              <Typography
                variant="body2"
                color="textSecondary"
                fontWeight="bold"
              >
                Total Records: {historyTotalCount}
              </Typography>
            </Box>
            {/* Element aligned to flex-end */}
            <Box display="flex" alignItems="center">
              <Stack spacing={2}>
                <Pagination
                  count={historyTotalPages > 0 ? historyTotalPages : 1}
                  page={page}
                  onChange={handlePageChange}
                  variant="outlined"
                  color="primary"
                  boundaryCount={2}
                  siblingCount={1}
                />
              </Stack>
            </Box>
          </Box>
        </Box>
      )}

      {/* Create Note Dialog */}
      <Dialog
        open={openCreateNoteDialog}
        onClose={() => setCreateNoteDialog(false)}
        maxWidth="sm"
        PaperProps={{
          style: {
            width: "500px",
            height: "auto",
            padding: "20px",
            borderRadius: "12px", // More rounded corners
            boxShadow: "0 6px 30px rgba(0, 0, 0, 0.2)", // Enhanced shadow for depth
          },
        }}
      >
        <DialogTitle
          style={{
            fontWeight: "bold",
            fontSize: "1.5rem",
            textAlign: "center",
          }}
        >
          Create Note
        </DialogTitle>
        <DialogContent style={{ padding: "24px" }}>
          <TextField
            autoFocus
            margin="dense"
            label="Create Note"
            type="text"
            fullWidth
            variant="outlined"
            value={newNoteDescription}
            onChange={(e) => setNewNoteDescription(e.target.value)}
            multiline
            rows={4}
            style={{
              fontSize: "1.2rem",
              borderRadius: "6px",
              backgroundColor: "#f9f9f9",
              marginBottom: "16px", // Space below the text field
            }}
            InputLabelProps={{
              style: { color: "#666" },
            }}
            InputProps={{
              style: { padding: "10px" },
            }}
          />
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "space-between", padding: "16px 24px" }}
        >
          <Button
            onClick={() => setCreateNoteDialog(false)}
            color="primary"
            style={{
              textTransform: "none",
              backgroundColor: "#e50000",
              color: "white",
              borderRadius: "6px",
              padding: "8px 16px",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleCreateNote}
            color="primary"
            style={{
              textTransform: "none",
              backgroundColor: "#3f51b5",
              color: "white",
              borderRadius: "6px",
              padding: "8px 16px",
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.backgroundColor = "#5c6bc0")
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.backgroundColor = "#3f51b5")
            }
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* Tab 6 Verification */}
      {tabValue === 6 && (
        <Box sx={{ p: 2 }}>
          {verificationLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <CircularProgress />
            </Box>
          ) : verificationError ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <Typography variant="h6" color="error">
                {verificationError ||
                  "Something went wrong while fetching verification data."}
              </Typography>
            </Box>
          ) : verificationData.length === 0 ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <Typography variant="h6" color="textSecondary">
                No verification found
              </Typography>
            </Box>
          ) : (
            <Grid container spacing={3}>
              {/* Left Side: Verification Details */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid item xs={6} sm={6}>
                  <Box
                    sx={{
                      height: "650px",
                      overflowY: "auto",
                      border: "1px solid #e0e0e0",
                      p: 2,
                      borderRadius: 2,
                      boxShadow: 2,
                      "&::-webkit-scrollbar": {
                        width: "8px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#888",
                        borderRadius: "10px",
                      },
                      "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "#555",
                      },
                    }}
                  >
                    <form ref={formRef} onSubmit={handleSaveClick}>
                      {verificationData.map((verificationItem, index) => (
                        <Box key={index} sx={{ mb: 3 }}>
                          <Box
                            sx={{
                              border: "1px solid #e0e0e0",
                              p: 3,
                              borderRadius: 2,
                              boxShadow: 1,
                              position: "relative",
                            }}
                          >
                            <Grid container spacing={1}>
                              <div>
                                {/* Edit Button */}
                                {editableItemIndex !== index && (
                                  <Tooltip title="Edit" arrow>
                                    <IconButton
                                      onClick={() => handleEditClick(index)}
                                      sx={{
                                        position: "absolute",
                                        top: 16,
                                        right: 16,
                                        zIndex: 1,
                                        transition: "all 0.3s ease",
                                        "&:hover": {
                                          backgroundColor: "#e3f2fd",
                                        },
                                      }}
                                    >
                                      <EditIcon sx={{ color: "green" }} />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </div>

                              {/* Cancel Button (only visible in edit mode) */}
                              {editableItemIndex === index && (
                                <Tooltip title="Cancel" arrow>
                                  <Button
                                    onClick={handleCancelEdit}
                                    sx={{
                                      position: "absolute",
                                      top: 16,
                                      right: 16,
                                      zIndex: 1,
                                      color: "red",
                                      minWidth: 20,
                                      padding: 1,
                                    }}
                                  >
                                    <CloseIcon />
                                  </Button>
                                </Tooltip>
                              )}

                              {/* Save All Button */}
                              {editableItemIndex !== null && (
                                <Tooltip title="Save All Changes" arrow>
                                  <Button
                                    onClick={handleSaveClick}
                                    sx={{
                                      position: "absolute",
                                      top: 16,
                                      right: 50,
                                      zIndex: 1,
                                      color: "black",
                                      minWidth: 20,
                                      padding: 1, // Make the text bold (although it won't be visible here)
                                    }}
                                  >
                                    <SaveIcon />
                                  </Button>
                                </Tooltip>
                              )}

                              {/* Verification State */}
                              <Grid item xs={12} sx={{ mt: 2 }}>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  Verification State:
                                </Typography>
                                <Autocomplete
                                  name={`verificationState_${index}`}
                                  value={
                                    verificationData[index]
                                      ?.verificationState || ""
                                  }
                                  onChange={(_event, newValue) => {
                                    onVerificationStateChange(newValue, index); // Using the function here
                                  }}
                                  options={verificationstate} // List of verification states
                                  getOptionLabel={(option) => option} // Assuming the option is a string
                                  disableClearable
                                  disabled={editableItemIndex !== index}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      fullWidth
                                      sx={{ bgcolor: "#fff", borderRadius: 1 }}
                                    />
                                  )}
                                />
                              </Grid>

                              {/* Rejection Reason */}
                              {verificationData[index]?.verificationState ===
                                "Rejected" && (
                                <Grid item xs={12} sx={{ mt: 2 }}>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    Rejection Reason:
                                  </Typography>
                                  <Autocomplete
                                    multiple
                                    value={
                                      verificationData[index]
                                        ?.rejectionReason || []
                                    }
                                    onChange={(_event, newValue) => {
                                      onRejectionReasonChange(newValue, index);
                                    }}
                                    options={rejectionReasons} // Use keys or values depending on your requirement
                                    getOptionLabel={(option) => option}
                                    disableClearable
                                    disabled={editableItemIndex !== index}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        fullWidth
                                        sx={{
                                          bgcolor: "#fff",
                                          borderRadius: 1,
                                        }}
                                      />
                                    )}
                                  />
                                </Grid>
                              )}
                              {/* Driver License Section */}
                              <Grid item xs={6} sx={{ mt: 2 }}>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  Driver License ID:
                                </Typography>
                                <TextField
                                  name={`drivers_license_id_${index}`}
                                  defaultValue={
                                    verificationItem.drivers_license_id || ""
                                  }
                                  variant="outlined"
                                  fullWidth
                                  disabled={editableItemIndex !== index}
                                  sx={{ bgcolor: "#fff", borderRadius: 1 }}
                                />

                                {/* Driver License Dates */}
                                <Grid container spacing={1} sx={{ mt: 2 }}>
                                  <Grid item xs={6}>
                                    <Typography
                                      variant="subtitle1"
                                      sx={{ fontWeight: "bold" }}
                                    >
                                      Issue Date:
                                    </Typography>
                                    {editableItemIndex !== index ? (
                                      <Box>
                                        {verificationItem.drivers_license_issue_date
                                          ? dayjs(
                                              verificationItem.drivers_license_issue_date,
                                            ).format("YYYY-MM-DD")
                                          : "N/A"}
                                      </Box>
                                    ) : (
                                      <TextField
                                        name={`drivers_license_issue_date_${index}`}
                                        type="date"
                                        defaultValue={
                                          verificationItem.drivers_license_issue_date ||
                                          ""
                                        }
                                        fullWidth
                                        variant="outlined"
                                      />
                                    )}
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography
                                      variant="subtitle1"
                                      sx={{ fontWeight: "bold" }}
                                    >
                                      Expiration Date:
                                    </Typography>
                                    {editableItemIndex !== index ? (
                                      <Box>
                                        {verificationItem.drivers_license_expiration_date
                                          ? dayjs(
                                              verificationItem.drivers_license_expiration_date,
                                            ).format("YYYY-MM-DD")
                                          : "N/A"}
                                      </Box>
                                    ) : (
                                      <TextField
                                        name={`drivers_license_expiration_date_${index}`}
                                        type="date"
                                        defaultValue={
                                          verificationItem.drivers_license_expiration_date ||
                                          ""
                                        }
                                        fullWidth
                                        variant="outlined"
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>

                              {/* Personal ID Section */}
                              <Grid item xs={6} sx={{ mt: 2 }}>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  Personal ID:
                                </Typography>
                                <TextField
                                  name={`personal_id_${index}`}
                                  defaultValue={
                                    verificationItem.personal_id || ""
                                  }
                                  variant="outlined"
                                  fullWidth
                                  disabled={editableItemIndex !== index}
                                  sx={{ bgcolor: "#fff", borderRadius: 1 }}
                                />

                                {/* Personal ID Dates */}
                                <Grid container spacing={1} sx={{ mt: 2 }}>
                                  <Grid item xs={6}>
                                    <Typography
                                      variant="subtitle1"
                                      sx={{ fontWeight: "bold" }}
                                    >
                                      Issue Date:
                                    </Typography>
                                    {editableItemIndex !== index ? (
                                      <Box>
                                        {verificationItem.personal_id_issue
                                          ? dayjs(
                                              verificationItem.personal_id_issue,
                                            ).format("YYYY-MM-DD")
                                          : "N/A"}
                                      </Box>
                                    ) : (
                                      <TextField
                                        name={`personal_id_issue_${index}`}
                                        type="date"
                                        defaultValue={
                                          verificationItem.personal_id_issue ||
                                          ""
                                        }
                                        fullWidth
                                        variant="outlined"
                                      />
                                    )}
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography
                                      variant="subtitle1"
                                      sx={{ fontWeight: "bold" }}
                                    >
                                      Expiration Date:
                                    </Typography>
                                    {editableItemIndex !== index ? (
                                      <Box>
                                        {verificationItem.personal_id_expiration
                                          ? dayjs(
                                              verificationItem.personal_id_expiration,
                                            ).format("YYYY-MM-DD")
                                          : "N/A"}
                                      </Box>
                                    ) : (
                                      <TextField
                                        name={`personal_id_expiration_${index}`}
                                        type="date"
                                        defaultValue={
                                          verificationItem.personal_id_expiration ||
                                          ""
                                        }
                                        fullWidth
                                        variant="outlined"
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>

                              {/* Nationality */}
                              <Grid item xs={6} sx={{ mt: 2 }}>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  Nationality:
                                </Typography>
                                <Autocomplete
                                  name={`nationality_${index}`}
                                  value={
                                    verificationData[index]?.nationality || ""
                                  }
                                  onChange={(_event, newValue) => {
                                    onNationalityChange(newValue, index);
                                  }}
                                  options={nationality}
                                  getOptionLabel={(option) => option}
                                  disableClearable
                                  disabled={editableItemIndex !== index}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      fullWidth
                                      sx={{ bgcolor: "#fff", borderRadius: 1 }}
                                    />
                                  )}
                                />
                              </Grid>

                              {/* Customer Type */}
                              <Grid item xs={6} sx={{ mt: 2 }}>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  Customer Type:
                                </Typography>
                                <Autocomplete
                                  name={`customer_type_${index}`}
                                  value={
                                    verificationData[index]?.customer_type || ""
                                  }
                                  onChange={(_event, newValue) => {
                                    onCTypeChange(newValue, index); // Using the function here
                                  }}
                                  options={customertype} // List of verification states
                                  getOptionLabel={(option) => option} // Assuming the option is a string
                                  disableClearable
                                  disabled={editableItemIndex !== index}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      fullWidth
                                      sx={{ bgcolor: "#fff", borderRadius: 1 }}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      ))}
                    </form>
                  </Box>
                </Grid>

                {/* Confirmation Dialog */}
              </LocalizationProvider>

              {/* Right Side: Gallery Section */}
              <Grid item xs={6} sm={6}>
                <Box
                  sx={{
                    height: "650px",
                    overflowY: "auto",
                    border: "1px solid #e0e0e0",
                    p: 2,
                    borderRadius: 2,
                    boxShadow: 2,
                    "&::-webkit-scrollbar": {
                      width: "8px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#888",
                      borderRadius: "10px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      backgroundColor: "#555",
                    },
                  }}
                >
                  {isVisibleFullImage ? (
                    <CustomImageViewer
                      src={images[currentImageIndex]}
                      currentImageIndex={currentImageIndex}
                      setIsVisible={setVisibleFullImage}
                      setCurrentImageIndex={setCurrentImageIndex}
                      length={images.length}
                    />
                  ) : (
                    <>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", mb: 2 }}
                      >
                        Uploaded Documents
                      </Typography>
                      {images.length > 0 ? (
                        <Grid container spacing={1}>
                          {images.map((url, index) => (
                            <Grid item xs={4} key={index}>
                              <img
                                src={url}
                                alt={`Uploaded document ${index + 1}`}
                                style={{
                                  width: "100%",
                                  height: "auto",
                                  objectFit: "contain",
                                  cursor: "pointer",
                                  borderRadius: "8px",
                                  boxShadow: 2,
                                }}
                                onClick={() => handleImageClick(index)} // Open the modal with the clicked image
                              />
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <Box sx={{ textAlign: "center", p: 3 }}>
                          <Typography>No images available</Typography>
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
          {/* Linked Accounts Table */}
          <Box
            sx={{
              mt: 3,
              p: 2,
              border: "1px solid #e0e0e0",
              borderRadius: 2,
              boxShadow: 2,
              height: "400px", // Set a fixed height
              overflow: "hidden", // Prevent content from overflowing the box
            }}
          >
            <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
              Linked Accounts
            </Typography>
            {LinkedAccountDataLoading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="200px"
              >
                <CircularProgress />
              </Box>
            ) : LinkedAccountDataError ? (
              <Typography variant="h6" color="error">
                {LinkedAccountDataError ||
                  "Something went wrong while fetching linked account data."}
              </Typography>
            ) : LinkedAccountData?.length > 0 ? (
              <Box
                sx={{
                  height: "calc(100% - 50px)", // Subtract the height of the heading
                  overflowY: "auto", // Enable vertical scrolling
                  "&::-webkit-scrollbar": {
                    width: "8px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                    borderRadius: "10px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "#555",
                  },
                }}
              >
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <strong>Customer ID</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Name</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Verification State</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Blocked</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Failed Amount</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Tags</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Action</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {LinkedAccountData.map((account, index) => (
                        <TableRow key={index}>
                          <TableCell>{account.customer_id}</TableCell>
                          <TableCell>{account.name}</TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                px: 1, // Reduced horizontal padding for compactness
                                py: 0.5, // Slightly larger vertical padding for balanced look
                                borderRadius: 1.5, // More rounded corners for a softer look
                                textAlign: "center",
                                fontWeight: 500,
                                fontSize: "0.875rem", // Smaller font size for compact appearance
                                display: "inline-block", // Ensure the box fits the text
                                ...getLinkedAccount(account.verification_state), // Apply styles based on verification_state
                              }}
                            >
                              {account.verification_state ?? "N/A"}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                px: 1, // Reduced horizontal padding for a smaller width
                                py: 0.25, // Reduced vertical padding for a smaller height
                                borderRadius: 1.5, // Rounded corners for a softer look
                                textAlign: "center",
                                backgroundColor: account.blocked
                                  ? "#f8d7da"
                                  : "#d4edda", // Red for true, green for false
                                color: account.blocked ? "#721c24" : "#155724", // Dark red for true, dark green for false
                                fontWeight: 500,
                                fontSize: "0.875rem", // Slightly smaller font size
                                display: "inline-block", // Ensures box fits snugly around the text
                              }}
                            >
                              {account.blocked ? "True" : "False"}
                            </Box>
                          </TableCell>
                          <TableCell>{account.failed_amount}</TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 1, // Space between tags
                              }}
                            >
                              {account.tags?.length > 0
                                ? account.tags.map((tag, tagIndex) => (
                                    <Box
                                      key={tagIndex}
                                      sx={{
                                        px: 1.5,
                                        py: 0.5,
                                        borderRadius: "10px",
                                        backgroundColor: "#e3f2fd",
                                        fontSize: "0.8rem",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {tag}
                                    </Box>
                                  ))
                                : "None"}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => {
                                setTabValue(0);
                                setTimeout(() => {
                                  navigate(`/customer/${account.customer_id}`);
                                }, 3000);
                              }}
                              style={{ color: "blue" }}
                              aria-label={`View details for customer ${account.customer_id}`}
                            >
                              <ArrowForwardIosOutlinedIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ) : (
              <Typography variant="body1" color="textSecondary">
                No linked accounts found.
              </Typography>
            )}
          </Box>
        </Box>
      )}

      {/* Tab 7 Payment Method */}
      {tabValue === 7 && (
        <Box sx={{ p: 2 }}>
          {paymentLoading && <Typography>Loading...</Typography>}

          {paymentError && (
            <Typography color="error">Error: {paymentError}</Typography>
          )}

          {!paymentLoading && !paymentError && payment.length > 0 && (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="payment methods table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Payment Method ID
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Customer ID
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Default</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Title</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payment.map((method) => (
                    <TableRow key={method.payment_method_id}>
                      <TableCell>{method.payment_method_id}</TableCell>
                      <TableCell>{method.customer_id}</TableCell>
                      <TableCell>
                        {method.is_default ? (
                          <CheckIcon color="success" />
                        ) : (
                          <CloseIcon color="error" />
                        )}
                      </TableCell>
                      <TableCell>{method.title}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {!paymentLoading && !paymentError && payment.length === 0 && (
            <Typography>No payment methods found.</Typography>
          )}
        </Box>
      )}
      {/* Snackbar for notifications */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CustomerDetails;
