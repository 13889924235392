import { useState, useEffect } from "react";

const BASE_URL = "https://jf8k6mp2m8.eu-west-1.awsapprunner.com";

export const useInvoiceTransactionData = (invoiceID) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!invoiceID) {
      setError("Invoice ID is required");
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        setError("No token found");
        setLoading(false);
        return;
      }

      const endpoint = `${BASE_URL}/invoices/transactions?invoice_id=${invoiceID}`;

      try {
        const response = await fetch(endpoint, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          localStorage.removeItem("token");
          window.location.href = "/";
          return;
        }

        if (response.status === 403) {
          setError("You are not authorized to access this page");
          setLoading(false);
          return;
        }

        if (!response.ok) {
          throw new Error(
            `Failed to fetch ${response.url}: ${response.statusText}`,
          );
        }

        const result = await response.json();

        if (!result || typeof result !== "object") {
          throw new Error("Invalid response format");
        }

        // Ensure records exist in the expected format
        const transformedData = result || [];
        setData(transformedData);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setError(error.message || "An unknown error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [invoiceID]);

  return { data, loading, error };
};
