import React from "react";
import { useState } from "react";
import { Alert } from "@mui/material";
import { useParams, Link } from "react-router-dom";
import {
  useReservationData,
  useFeesData,
  useRentals,
  useEndReservation,
} from "./data/reservationPageData";
import PersonSearchOutlinedIcon from "@mui/icons-material/PersonSearchOutlined";
import DirectionsCarOutlinedIcon from "@mui/icons-material/DirectionsCarOutlined";
import CancelPresentationOutlinedIcon from "@mui/icons-material/CancelPresentationOutlined";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Divider,
  CircularProgress,
  Button,
  TableCell,
  TableBody,
  TableRow,
  Paper,
  Table,
  TableContainer,
  TableHead,
  Snackbar,
  IconButton,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
} from "@mui/material";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import ReservationMap from "./map/reservationmap";

import PersonIcon from "@mui/icons-material/Person";
import SpeedIcon from "@mui/icons-material/Speed";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import PaymentsIcon from "@mui/icons-material/Payments";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RouteIcon from "@mui/icons-material/Route";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import StarRateIcon from "@mui/icons-material/StarRate";
import RateReviewIcon from "@mui/icons-material/RateReview";
import LocationOnIcon from "@mui/icons-material/LocationOn";

//Action
import { useCancelFees } from "./action/cancelfees";

const InfoCard = ({ title, children }) => (
  <Card
    variant="outlined"
    sx={{
      mb: 2,
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
      width: "100%",
    }}
  >
    <CardContent>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ textTransform: "uppercase", fontWeight: "bold" }}
      >
        {title}
      </Typography>
      <Divider sx={{ mb: 2 }} />
      {children}
    </CardContent>
  </Card>
);

const RentalsTable = ({ rentals }) => (
  <TableContainer
    component={Paper}
    sx={{ maxHeight: "300px", overflowY: "auto" }}
  >
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <strong>Start Time</strong>
          </TableCell>
          <TableCell>
            <strong>End Time</strong>
          </TableCell>
          <TableCell>
            <strong>Usage Time</strong>
          </TableCell>
          <TableCell>
            <strong>Rental Day</strong>
          </TableCell>
          <TableCell>
            <strong>Driven Distance</strong>
          </TableCell>
          <TableCell>
            <strong>Excess KM</strong>
          </TableCell>
          <TableCell>
            <strong>Rental Cost</strong>
          </TableCell>
          <TableCell>
            <strong>Excess KM Cost</strong>
          </TableCell>
          <TableCell>
            <strong>Credits</strong>
          </TableCell>
          <TableCell>
            <strong>Discount</strong>
          </TableCell>
          <TableCell>
            <strong>CDW Cost</strong>
          </TableCell>
          <TableCell>
            <strong>Total Cost</strong>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rentals.map((rental) => (
          <TableRow key={rental.rental_id}>
            <TableCell>{rental.start_time || "N/A"}</TableCell>
            <TableCell>{rental.end_time || "N/A"}</TableCell>
            <TableCell>{rental.usage_time || "N/A"}</TableCell>
            <TableCell>{rental.rental_day || "N/A"}</TableCell>
            <TableCell>{rental.driven_distance || "N/A"}</TableCell>
            <TableCell>{rental.excess_km || "N/A"}</TableCell>
            <TableCell>
              {rental.rental_cost ? `${rental.rental_cost}` : "N/A"}
            </TableCell>
            <TableCell>
              {rental.excess_km_cost ? `${rental.excess_km_cost}` : "N/A"}
            </TableCell>
            <TableCell>
              {rental.credits ? `${rental.credits}` : "N/A"}
            </TableCell>
            <TableCell>
              {rental.discount ? `${rental.discount}` : "N/A"}
            </TableCell>
            <TableCell>
              {rental.cdw_cost ? `${rental.cdw_cost}` : "N/A"}
            </TableCell>
            <TableCell>
              {rental.total_cost ? `${rental.total_cost}` : "N/A"}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const FeesTable = ({ fees, handleCancelFee }) => (
  <TableContainer
    component={Paper}
    sx={{ maxHeight: "300px", overflowY: "auto" }}
  >
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ padding: "8px 16px" }}>
            <strong>Create Time</strong>
          </TableCell>
          <TableCell sx={{ padding: "8px 16px" }}>
            <strong>Fee ID</strong>
          </TableCell>
          <TableCell sx={{ padding: "8px 16px" }}>
            <strong>Invoice ID</strong>
          </TableCell>
          <TableCell sx={{ padding: "8px 16px" }}>
            <strong>Value</strong>
          </TableCell>
          <TableCell sx={{ padding: "8px 16px" }}>
            <strong>Description</strong>
          </TableCell>
          <TableCell sx={{ padding: "8px 16px" }}>
            <strong>Status</strong>
          </TableCell>
          <TableCell sx={{ padding: "8px 16px" }}>
            <strong>Action</strong>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {fees.map((fee) => (
          <TableRow key={fee.fee_id}>
            <TableCell sx={{ padding: "8px 16px" }}>
              {fee.create_time || "N/A"}
            </TableCell>
            <TableCell sx={{ padding: "8px 16px" }}>
              {fee.fee_id || "N/A"}
            </TableCell>
            <TableCell sx={{ padding: "8px 16px" }}>
              {fee.invoice_id || "N/A"}
            </TableCell>
            <TableCell sx={{ padding: "8px 16px" }}>
              {fee.value || "N/A"}
            </TableCell>
            <TableCell sx={{ padding: "8px 16px" }}>
              {fee.description || "N/A"}
            </TableCell>
            <TableCell sx={{ padding: "8px 16px" }}>
              {fee.status || "N/A"}
            </TableCell>
            <TableCell sx={{ padding: "8px 16px" }}>
              <IconButton
                onClick={() => handleCancelFee()}
                sx={{
                  color: "red",
                  "&:hover": {
                    backgroundColor: "rgba(255, 0, 0, 0.1)",
                  },
                }}
              >
                <CancelPresentationOutlinedIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const ReservationPage = () => {
  const { reservationID } = useParams();
  const { data, loading, error } = useReservationData(reservationID);
  const {
    data: feesData,
    loading: feesLoading,
    error: feesError,
  } = useFeesData(reservationID);
  const {
    data: rentalsData,
    loading: rentalsLoading,
    error: rentalsError,
  } = useRentals(reservationID);
  const { endReservation } = useEndReservation(reservationID);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [forceEndDialogOpen, setForceEndDialogOpen] = useState(false);

  //Calling cancel fees
  const { cancelFees } = useCancelFees();
  const handleCancelFee = async (feeId) => {
    try {
      await cancelFees(feeId);
      setSnackbarMessage("Fee canceled successfully!");
      setSnackbarSeverity("success");
    } catch (error) {
      setSnackbarMessage(error?.message || "Failed to cancel fee.");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  if (loading || feesLoading || rentalsLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
        <Typography variant="body1" sx={{ ml: 2 }}>
          Loading reservation details...
        </Typography>
      </Box>
    );
  }

  if (error || feesError || rentalsError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="h6" color="error">
          Error: {error?.message || feesError?.message || rentalsError?.message}
        </Typography>
      </Box>
    );
  }

  const reservation = data?.[0];
  if (!reservation) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="h6" color="textSecondary">
          No reservation found
        </Typography>
      </Box>
    );
  }

  const {
    Customer,
    Vehicle,
    Rentals = [],
    Fees = [],
    Rating,
    TripStart,
    TripEnd,
    Route,
  } = reservation;

  const hasRoute =
    Route && Array.isArray(Route.features) && Route.features.length > 0;

  const startCoordinate = hasRoute
    ? Route.features[0].geometry.coordinates[0]
    : null;
  const endCoordinate = hasRoute
    ? Route.features[0].geometry.coordinates[
        Route.features[0].geometry.coordinates.length - 1
      ]
    : null;

  const mapCenter =
    hasRoute && startCoordinate
      ? [startCoordinate[0], startCoordinate[1]]
      : [53.9994829, 24.0002488];

  const getStatusStyles = (state) => {
    switch (state) {
      case "Reserved":
        return { backgroundColor: "#2980B9", color: "#FFFFFF" }; // Light Blue
      case "Running":
        return { backgroundColor: "#27AE60", color: "#FFFFFF" }; // Emerald Green
      case "Cancelled":
        return { backgroundColor: "#E74C3C", color: "#FFFFFF" }; // Red
      case "Expired":
        return { backgroundColor: "#7F8C8D", color: "#FFFFFF" }; // Gray
      case "Completed":
        return { backgroundColor: "#120A8F", color: "#ECF0F1" }; // Navy Blue
      default:
        return {};
    }
  };

  const { reservationState } = reservation;

  let buttonText = "";
  let buttonColor = "primary"; // Default color
  let isDisabled = false;

  // Determine button text, color, and disabled state based on reservationState
  if (reservationState === "Reserved") {
    buttonText = "Cancel Reservation";
    buttonColor = "error"; // Red color
  } else if (reservationState === "Running") {
    buttonText = "End Reservation";
    buttonColor = "error"; // Red color
  } else if (["Expired", "Completed", "Cancelled"].includes(reservationState)) {
    buttonText = "End Reservation";
    buttonColor = "grey"; // Grey color
    isDisabled = true;
  }

  const handleEndReservation = async () => {
    setConfirmDialogOpen(false); // Close the confirmation dialog if open
    const result = await endReservation();

    if (result?.status === 202) {
      setForceEndDialogOpen(true); // Open force end dialog
      return;
    }

    if (result?.success === true) {
      setSnackbarMessage(result.message || "Reservation ended successfully!");
      setSnackbarSeverity("success");
    } else if (result?.success === false) {
      setSnackbarMessage(result.message);
      setSnackbarSeverity("error");
    } else {
      setSnackbarMessage("Unexpected response received.");
      setSnackbarSeverity("warning");
    }
    setSnackbarOpen(true);
  };

  const fields = [
    {
      label: "Pricing Model",
      icon: <PaymentsIcon color="primary" />,
      value: reservation.PricingModel || "N/A",
    },
    {
      label: "Trip Fee",
      icon: <LocalOfferIcon color="primary" />,
      value: reservation.TripFee ? `AED ${reservation.TripFee}` : "N/A",
    },
    {
      label: "Rental Price",
      icon: <MonetizationOnIcon color="primary" />,
      value: reservation.RentalPrice ? `AED ${reservation.RentalPrice}` : "N/A",
    },
    {
      label: "Kilometer Limit",
      icon: <SpeedIcon color="primary" />,
      value: reservation.KilometerLimit
        ? `${reservation.KilometerLimit} km`
        : "N/A",
    },
    {
      label: "Mileage Package",
      icon: <SpeedIcon color="primary" />,
      value: reservation.MileagePackage || "N/A",
    },
    {
      label: "Mileage Package Price",
      icon: <LocalGasStationIcon color="primary" />,
      value: reservation.MileagePackagePrice
        ? `AED ${reservation.MileagePackagePrice}`
        : "N/A",
    },
    {
      label: "Excess KM Price",
      icon: <PriceCheckIcon color="primary" />,
      value: reservation.ExcessKMPrice
        ? `AED ${reservation.ExcessKMPrice}`
        : "N/A",
    },
    {
      label: "Use Credits",
      icon: <CreditScoreIcon color="primary" />,
      value: reservation.UseCredits || "N/A",
    },
  ];

  return (
    <Box sx={{ p: 2, mt: 5, ml: 1, mr: 1 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "16px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "16px",
              flexWrap: "wrap",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                textTransform: "uppercase",
                fontWeight: "bold",
                display: "flex",
                gap: "8px",
              }}
            >
              <span style={{ fontWeight: "bold" }}>Reservation#</span>
              <span style={{ fontWeight: "normal" }}>{reservationID}</span>
              <Tooltip title="Status" arrow>
                <Box
                  sx={{
                    borderRadius: "4px",
                    padding: "6px 10px",
                    ...getStatusStyles(reservation.reservationState),
                    textAlign: "center",
                    height: "25px", // Matches the "Back" button height
                    display: "flex", // Centers content
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "normal",
                    textTransform: "none", // Prevents uppercase transformation
                  }}
                >
                  {reservation.reservationState || "N/A"}
                </Box>
              </Tooltip>
            </Typography>
          </div>
        </div>

        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexDirection: "row",
            alignItems: "end",
          }}
        >
          {/* End Reservation Button */}
          <Button
            variant="contained"
            color={buttonColor}
            disabled={isDisabled}
            onClick={() => setConfirmDialogOpen(true)} // Open confirmation dialog
            sx={{ width: "auto" }}
          >
            {buttonText}
          </Button>

          {/* Back Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.history.back()}
          >
            Back
          </Button>
        </Box>
      </Box>

      {/* End Reservation Dialog */}
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogContent>
          <Typography>
            Are you sure you want to end this reservation?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleEndReservation} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Force end reservation dialog */}
      <Dialog
        open={forceEndDialogOpen}
        onClose={() => setForceEndDialogOpen(false)}
      >
        <DialogContent>
          <Typography>
            The reservation cannot be ended normally. Do you want to force-end
            it?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setForceEndDialogOpen(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              const forceEndResult = await endReservation({ force: true });
              if (forceEndResult?.success) {
                setSnackbarMessage("Reservation force-ended successfully!");
                setSnackbarSeverity("success");
              } else {
                setSnackbarMessage("Failed to force-end the reservation.");
                setSnackbarSeverity("error");
              }
              setSnackbarOpen(true);
              setForceEndDialogOpen(false);
            }}
            color="error"
          >
            Force End Reservation
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Reservation Infomation */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          width: "100%",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {/* Rental Overview - Full Width */}
          <InfoCard
            title={
              <Typography
                variant="body1"
                sx={{ textTransform: "uppercase", fontWeight: 700 }}
              >
                Rental Overview
              </Typography>
            }
          >
            <Grid container spacing={1.5}>
              {fields.map((item, idx) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  key={idx}
                  display="flex"
                  alignItems="center"
                  gap={1.5}
                >
                  <Box>{item.icon}</Box>
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary", fontWeight: 800 }}
                    >
                      {item.label}
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 800 }}>
                      {item.value}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </InfoCard>

          {/* Customer & Vehicle Info Side by Side */}
          <Box
            sx={{
              display: "flex",
              gap: 1,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box flex={1}>
              {/* Customer Info */}
              <InfoCard
                title={
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      variant="body1"
                      sx={{ textTransform: "uppercase", fontWeight: 700 }}
                    >
                      Customer Information
                    </Typography>
                    <Link
                      to={`/customer/${Customer?.customerId || ""}`}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <PersonSearchOutlinedIcon fontSize="medium" />
                    </Link>
                  </Box>
                }
              >
                <Grid container spacing={1.5}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    alignItems="center"
                    gap={1.5}
                  >
                    <PersonIcon color="primary" />
                    <Box flexGrow={1}>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary", fontWeight: 700 }}
                      >
                        Customer ID
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: Customer?.customerId
                            ? "text.primary"
                            : "grey.500",
                          fontWeight: 700,
                        }}
                      >
                        <Link to={`/customer/${Customer?.customerId || ""}`}>
                          {Customer?.customerId || "N/A"}
                        </Link>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    alignItems="center"
                    gap={1.5}
                  >
                    <PersonSearchOutlinedIcon color="primary" />
                    <Box flexGrow={1}>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary", fontWeight: 700 }}
                      >
                        Name
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: Customer?.name ? "text.primary" : "grey.500",
                          fontWeight: 700,
                        }}
                      >
                        {Customer?.name || "N/A"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </InfoCard>
            </Box>
            <Box flex={1}>
              {/* Vehicle Info */}
              <InfoCard
                title={
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      variant="body1"
                      sx={{ textTransform: "uppercase", fontWeight: 700 }}
                    >
                      Vehicle Information
                    </Typography>
                    <Link
                      to={`/vehicle/${Vehicle?.vehicleId || ""}`}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <DirectionsCarOutlinedIcon fontSize="medium" />
                    </Link>
                  </Box>
                }
              >
                <Grid container spacing={1.5}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    alignItems="center"
                    gap={1.5}
                  >
                    <ConfirmationNumberIcon color="primary" />
                    <Box flexGrow={1}>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary", fontWeight: 700 }}
                      >
                        License Plate
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: Vehicle?.licencePlate
                            ? "text.primary"
                            : "grey.500",
                          fontWeight: 700,
                        }}
                      >
                        {Vehicle?.licencePlate || "N/A"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    alignItems="center"
                    gap={1.5}
                  >
                    <DriveEtaIcon color="primary" />
                    <Box flexGrow={1}>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary", fontWeight: 700 }}
                      >
                        Car Model
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: Vehicle?.carModel
                            ? "text.primary"
                            : "grey.500",
                          fontWeight: 700,
                        }}
                      >
                        {Vehicle?.carModel || "N/A"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </InfoCard>
            </Box>
          </Box>

          {/* Trip Start & Trip End Side by Side */}
          <Box
            sx={{
              display: "flex",
              gap: 1,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box flex={1}>
              {/* Trip Start Info */}
              <InfoCard
                title={
                  <Typography
                    variant="body1"
                    sx={{ textTransform: "uppercase", fontWeight: 700 }}
                  >
                    Trip Start
                  </Typography>
                }
              >
                <Grid container spacing={1.5}>
                  {/* Reserved Time */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    alignItems="center"
                    gap={1.5}
                  >
                    <AccessTimeIcon color="primary" />
                    <Box flexGrow={1}>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary", fontWeight: 700 }}
                      >
                        Reserved Time
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: reservation?.ReservedTime
                            ? "text.primary"
                            : "grey.500",
                          fontWeight: 700,
                        }}
                      >
                        {reservation?.ReservedTime || "N/A"}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Start Time */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    alignItems="center"
                    gap={1.5}
                  >
                    <AccessTimeIcon color="primary" />
                    <Box flexGrow={1}>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary", fontWeight: 700 }}
                      >
                        Start Time
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: TripStart?.["Start Time"]
                            ? "text.primary"
                            : "grey.500",
                          fontWeight: 700,
                        }}
                      >
                        {TripStart?.["Start Time"] || "N/A"}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Open Time */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    alignItems="center"
                    gap={1.5}
                  >
                    <AccessTimeIcon color="primary" />
                    <Box flexGrow={1}>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary", fontWeight: 700 }}
                      >
                        Open Time
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: TripStart?.["Open Time"]
                            ? "text.primary"
                            : "grey.500",
                          fontWeight: 700,
                        }}
                      >
                        {TripStart?.["Open Time"] || "N/A"}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Mileage */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    alignItems="center"
                    gap={1.5}
                  >
                    <SpeedIcon color="primary" />
                    <Box flexGrow={1}>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary", fontWeight: 700 }}
                      >
                        Mileage
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: TripStart?.["Mileage"]
                            ? "text.primary"
                            : "grey.500",
                          fontWeight: 700,
                        }}
                      >
                        {TripStart?.["Mileage"] || "N/A"}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Fuel */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    alignItems="center"
                    gap={1.5}
                  >
                    <LocalGasStationIcon color="primary" />
                    <Box flexGrow={1}>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary", fontWeight: 700 }}
                      >
                        Fuel
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: TripStart?.["Fuel"]
                            ? "text.primary"
                            : "grey.500",
                          fontWeight: 700,
                        }}
                      >
                        {TripStart?.["Fuel"] || "N/A"}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Address */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    alignItems="center"
                    gap={1.5}
                  >
                    <LocationOnIcon color="primary" />
                    <Box flexGrow={1}>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary", fontWeight: 700 }}
                      >
                        Address
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: TripStart?.["Address"]
                            ? "text.primary"
                            : "grey.500",
                          fontWeight: 700,
                        }}
                      >
                        {TripStart?.["Address"] || "N/A"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </InfoCard>
            </Box>
            <Box flex={1}>
              {/* Trip End Info */}
              <InfoCard
                title={
                  <Typography
                    variant="body1"
                    sx={{ textTransform: "uppercase", fontWeight: 700 }}
                  >
                    Trip End
                  </Typography>
                }
              >
                <Grid container spacing={1.5}>
                  {/* End Time */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    alignItems="center"
                    gap={1.5}
                  >
                    <AccessTimeIcon color="primary" />
                    <Box flexGrow={1}>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary", fontWeight: 700 }}
                      >
                        End Time
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: TripEnd?.["End Time"]
                            ? "text.primary"
                            : "grey.500",
                          fontWeight: 700,
                        }}
                      >
                        {TripEnd?.["End Time"] || "N/A"}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Mileage */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    alignItems="center"
                    gap={1.5}
                  >
                    <SpeedIcon color="primary" />
                    <Box flexGrow={1}>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary", fontWeight: 700 }}
                      >
                        Mileage
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: TripEnd?.["Mileage"]
                            ? "text.primary"
                            : "grey.500",
                          fontWeight: 700,
                        }}
                      >
                        {TripEnd?.["Mileage"] || "N/A"}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Distance */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    alignItems="center"
                    gap={1.5}
                  >
                    <RouteIcon color="primary" />
                    <Box flexGrow={1}>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary", fontWeight: 700 }}
                      >
                        Distance
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: reservation?.Distance
                            ? "text.primary"
                            : "grey.500",
                          fontWeight: 700,
                        }}
                      >
                        {reservation?.Distance || "N/A"}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Usage Time */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    alignItems="center"
                    gap={1.5}
                  >
                    <TimelapseIcon color="primary" />
                    <Box flexGrow={1}>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary", fontWeight: 700 }}
                      >
                        Usage Time
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: reservation?.UsageTime
                            ? "text.primary"
                            : "grey.500",
                          fontWeight: 700,
                        }}
                      >
                        {reservation?.UsageTime || "N/A"}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Fuel Level */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    alignItems="center"
                    gap={1.5}
                  >
                    <LocalGasStationIcon color="primary" />
                    <Box flexGrow={1}>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary", fontWeight: 700 }}
                      >
                        Fuel Level
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: TripEnd?.["Fuel Level"]
                            ? "text.primary"
                            : "grey.500",
                          fontWeight: 700,
                        }}
                      >
                        {TripEnd?.["Fuel Level"] || "N/A"}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Address */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    alignItems="center"
                    gap={1.5}
                  >
                    <LocationOnIcon color="primary" />
                    <Box flexGrow={1}>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary", fontWeight: 700 }}
                      >
                        Address
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: TripEnd?.["Address"]
                            ? "text.primary"
                            : "grey.500",
                          fontWeight: 700,
                        }}
                      >
                        {TripEnd?.["Address"] || "N/A"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </InfoCard>
            </Box>
          </Box>

          {/* Ratings - Full Width */}
          <InfoCard
            title={
              <Typography
                variant="body1"
                sx={{ textTransform: "uppercase", fontWeight: 700 }}
              >
                Ratings
              </Typography>
            }
          >
            <Grid container spacing={1.5}>
              <Grid
                item
                xs={12}
                sm={6}
                display="flex"
                alignItems="center"
                gap={1.5}
              >
                <StarRateIcon color="primary" />
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary", fontWeight: 700 }}
                  >
                    Trip Rating
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 700 }}>
                    {Rating?.["Trip Rating"] || "N/A"}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                display="flex"
                alignItems="center"
                gap={1.5}
              >
                <RateReviewIcon color="primary" />
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary", fontWeight: 700 }}
                  >
                    Review
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 700 }}>
                    {Rating?.["Review"] || "N/A"}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </InfoCard>
        </Box>
      </Box>

      {/* Map Section */}
      <InfoCard
        title={
          <Typography
            variant="body1"
            sx={{ textTransform: "uppercase", fontWeight: 700 }}
          >
            Route Map
          </Typography>
        }
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          <ReservationMap
            mapCenter={mapCenter}
            hasRoute={hasRoute}
            Route={Route}
            startCoordinate={startCoordinate}
            endCoordinate={endCoordinate}
            startTime={TripStart?.["Start Time"]}
            endTime={TripEnd?.["End Time"]}
          />
        </Box>
      </InfoCard>

      {/* Rentals info */}
      <InfoCard title="Rentals">
        <RentalsTable rentals={rentalsData || Rentals} />
      </InfoCard>

      {/* Fees info */}
      <InfoCard
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span>Fees</span>
            <IconButton
              aria-label="Create Fee"
              onClick={() => {}}
              sx={{ color: "#117864" }} // You can also use a specific color like '#ff5722'
            >
              <ControlPointOutlinedIcon />
            </IconButton>
          </div>
        }
      >
        <FeesTable fees={feesData || Fees} handleCancelFee={handleCancelFee} />
      </InfoCard>
    </Box>
  );
};

export default ReservationPage;
