const rejectionReasons = [
  "Selfie with ID Proof Missing",
  "Driving License Missing",
  "Home Country Driving License Required",
  "Emirates ID Missing",
  "Passport with Visa Entry Stamp Page Copy Required",
  "Visit Visa for Tourist Required",
  "Emirates ID Expired",
  "Illegible Documents",
  "International Driving License Not Authorised",
  "Less than 23 years",
  "Passport Copy Required",
  "Documents Missing",
  "Documents & Home Country Driving License Required",
  "Automatically Invalidated",
  "Unclear/Blurred Images Re-upload required",
  "International DL under 1 Year old",
  "UAE number not registered",
  "Emirates ID back side is missing",
  "Emirates ID Front side is missing",
  "Driving license backside is missing",
  "Driving License expired",
  "Driving license Frontside is missing",
  "Documents are not clear/unreadable",
  "Account already registered",
  "Passport copy missing",
  "International Driving Permit Required",
  "Identification Card Validation Failed",
  "Driving License Validation Failed",
];

export default rejectionReasons;
