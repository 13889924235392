import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useInvoiceData } from "./data/invoicedata"; // Adjust the import path if necessary

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  TextField,
  Snackbar,
  Alert,
  IconButton,
  Grid,
  Pagination,
  Stack,
  Button,
  MenuItem,
} from "@mui/material";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

const InvoicePage = () => {
  const [page, setPage] = useState(1); // Default to page 1
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [noResults, setNoResults] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const [customerId, setCustomerId] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [status, setStatus] = useState("");
  const [cancelledBy, setCancelledBy] = useState("");

  const [invoiceDateFrom, setInvoiceDateFrom] = useState(null);
  const [invoiceDateTo, setInvoiceDateTo] = useState(null);

  const [updatedAtFrom, setUpdatedAtFrom] = useState(null);
  const [updatedAtTo, setUpdatedAtTo] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const pageFromUrl = queryParams.get("page");
    if (pageFromUrl) {
      setPage(Number(pageFromUrl));
    }
  }, [location.search]);

  const {
    data: invoices,
    loading,
    error,
    totalPages,
    totalCount,
  } = useInvoiceData(page, debouncedSearchQuery);

  const handlePageChange = (event, value) => {
    setPage(value);
    navigate(`?page=${value}`);
  };

  useEffect(() => {
    if (triggerSearch) {
      setTriggerSearch(false);
    }
  }, [triggerSearch]);

  useEffect(() => {
    if (debouncedSearchQuery) {
      setNoResults(false);
    }
  }, [debouncedSearchQuery]);

  useEffect(() => {
    if (invoices && invoices.length > 0) {
      setNoResults(false);
    } else {
      setNoResults(true);
    }
  }, [invoices, debouncedSearchQuery]);

  useEffect(() => {
    if (error) {
      console.error("Error fetching invoice data:", error);
      setNoResults(true);
      setSnackbarMessage(error || "An error occurred while fetching data");
      setSnackbarOpen(true);
    }
  }, [error]);

  const handleClearSearch = () => {
    setCustomerId("");
    setInvoiceId("");
    setStatus("");
    setCancelledBy("");
    setInvoiceDateFrom(null);
    setInvoiceDateTo(null);
    setUpdatedAtFrom(null);
    setUpdatedAtTo(null);
    setDebouncedSearchQuery("");
    setPage(1); // Reset to the first page
    setNoResults(false); // Reset no results state
  };

  const getStatusStyles = (status) => {
    switch (status) {
      case "paid":
        return { backgroundColor: "#4CAF50", color: "#FFFFFF" }; // Green
      case "failed":
        return { backgroundColor: "#F44336", color: "#FFFFFF" }; // Red
      case "canceled":
        return { backgroundColor: "#FF9800", color: "#FFFFFF" }; // Orange
      case "waiting":
        return { backgroundColor: "#2196F3", color: "#FFFFFF" }; // Blue
      case "instalment":
        return { backgroundColor: "#7E57C2", color: "#FFFFFF" }; // Light Purple
      case "lost":
        return { backgroundColor: "#9E9E9E", color: "#FFFFFF" }; // Gray
      default:
        return {};
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const query = new URLSearchParams();

      if (customerId) {
        query.set("customerId", customerId);
      }

      if (invoiceId) {
        query.set("invoice_id", invoiceId);
      }

      if (status) {
        query.set("status", status);
      }

      if (cancelledBy) {
        query.set("cancelled_by", cancelledBy);
      }

      if (invoiceDateFrom) {
        query.set("invoice_date_from", invoiceDateFrom.toISOString());
      }

      if (invoiceDateTo) {
        query.set("invoice_date_to", invoiceDateTo.toISOString());
      }

      if (updatedAtFrom) {
        query.set("updated_at_from", updatedAtFrom.toISOString());
      }

      if (updatedAtTo) {
        query.set("updated_at_to", updatedAtTo.toISOString());
      }

      setDebouncedSearchQuery(query.toString());
      setPage(1);
      setTriggerSearch(true); // Trigger the search when the button is clicked
    }
  };

  const handleSearchClick = () => {
    const query = new URLSearchParams();

    if (customerId) {
      query.set("customerId", customerId);
    }

    if (invoiceId) {
      query.set("invoice_id", invoiceId);
    }

    if (status) {
      query.set("status", status);
    }

    if (cancelledBy) {
      query.set("cancelled_by", cancelledBy);
    }

    if (invoiceDateFrom) {
      query.set("invoice_date_from", invoiceDateFrom.toISOString());
    }

    if (invoiceDateTo) {
      query.set("invoice_date_to", invoiceDateTo.toISOString());
    }

    if (updatedAtFrom) {
      query.set("updated_at_from", updatedAtFrom.toISOString());
    }

    if (updatedAtTo) {
      query.set("updated_at_to", updatedAtTo.toISOString());
    }

    setDebouncedSearchQuery(query.toString());
    setPage(1);
    setTriggerSearch(true); // Trigger the search when the button is clicked
  };

  const [showSearchFields, setShowSearchFields] = useState(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (loading && !triggerSearch)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Box sx={{ p: 2 }}>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        gap={1}
        mb={2}
      >
        {/* Toggle Filters Icon */}
        <IconButton
          onClick={() => setShowSearchFields((prev) => !prev)}
          aria-label="Toggle search filters"
          sx={{
            color: "primary.main",
            transition: "transform 0.3s",
            "&:hover": {
              transform: "scale(1.1)", // Subtle hover effect
            },
          }}
        >
          {showSearchFields ? <CloseIcon /> : <FilterAltOutlinedIcon />}
        </IconButton>

        {/* Clear Button */}
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClearSearch}
          sx={{
            padding: "6px 12px",
            fontWeight: "bold",
            textTransform: "none",
            transition: "background-color 0.3s, box-shadow 0.3s",
          }}
        >
          CLEAR
        </Button>

        {/* Search Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearchClick}
          sx={{
            padding: "6px 12px",
            fontWeight: "bold",
            textTransform: "none", // For better readability
            transition: "background-color 0.3s, box-shadow 0.3s",
            "&:hover": {
              backgroundColor: "#0069d9",
              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
            },
          }}
          aria-label="Search Invoices"
        >
          SEARCH
        </Button>
      </Box>

      <Grid container spacing={1} marginBottom={2} alignItems="center">
        <Grid item xs={12} sm={4}>
          <TextField
            label="Customer ID"
            variant="outlined"
            size="small"
            fullWidth
            value={customerId}
            onChange={(e) => setCustomerId(e.target.value.trim())}
            onKeyDown={handleKeyDown}
            placeholder="Search by Customer ID"
            aria-label="Search by Customer ID"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Invoice ID"
            variant="outlined"
            size="small"
            fullWidth
            value={invoiceId}
            onChange={(e) => setInvoiceId(e.target.value.trim())}
            onKeyDown={handleKeyDown}
            placeholder="Search by Invoice ID"
            aria-label="Search by Invoice ID"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            select
            label="Status"
            variant="outlined"
            size="small"
            fullWidth
            value={status}
            onChange={(e) => setStatus(e.target.value.trim())}
            onKeyDown={handleKeyDown}
            placeholder="Search by Invoice Status"
            aria-label="Search by Invoice Status"
          >
            {/* Empty option to represent null */}
            <MenuItem value="">...</MenuItem>
            <MenuItem value="paid">paid</MenuItem>
            <MenuItem value="waiting">waiting</MenuItem>
            <MenuItem value="failed">failed</MenuItem>
            <MenuItem value="lost">lost</MenuItem>
            <MenuItem value="canceled">canceled</MenuItem>
            <MenuItem value="instalment">instalment</MenuItem>
          </TextField>
        </Grid>
      </Grid>

      {showSearchFields && (
        <Grid
          container
          spacing={1}
          marginTop={1}
          marginBottom={2}
          alignItems="center"
          style={{
            backgroundColor: "#f0f0f0",
            borderRadius: "8px",
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
            padding: "12px",
            transition: "all 0.3s ease",
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.boxShadow =
              "0 10px 20px rgba(0, 0, 0, 0.15)")
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.boxShadow = "0 6px 12px rgba(0, 0, 0, 0.1)")
          }
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={1} alignItems="center" marginBottom={1}>
              <Grid item xs={12} sm={3}>
                <DateTimePicker
                  label="[Invoice Date] From"
                  value={invoiceDateFrom}
                  onChange={(e) => setInvoiceDateFrom(e)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                  disableFuture
                  aria-label="Filter by Invoice Date From"
                />
              </Grid>

              {/* Invoice Date To */}
              <Grid item xs={12} sm={3}>
                <DateTimePicker
                  label="[Invoice Date] To"
                  value={invoiceDateTo}
                  onChange={(e) => setInvoiceDateTo(e)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                  disableFuture
                  aria-label="Filter by Invoice Date To"
                />
              </Grid>
            </Grid>

            {/* Combined Row: Updated Date and Cancelled By */}
            <Grid container spacing={1} alignItems="center" marginBottom={1}>
              {/* Updated Date From */}
              <Grid item xs={12} sm={3}>
                <DateTimePicker
                  label="[Updated Date] From"
                  value={updatedAtFrom}
                  onChange={(e) => setUpdatedAtFrom(e)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                  disableFuture
                  aria-label="Filter by Updated Date From"
                />
              </Grid>

              {/* Updated Date To */}
              <Grid item xs={12} sm={3}>
                <DateTimePicker
                  label="[Updated Date] To"
                  value={updatedAtTo}
                  onChange={(e) => setUpdatedAtTo(e)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                  disableFuture
                  aria-label="Filter by Updated Date To"
                />
              </Grid>
            </Grid>

            {/* Search Fields */}
            <Grid container spacing={1} alignItems="center" marginBottom={1}>
              <Grid item xs={12} sm={4} sx={{ padding: "2px" }}>
                <TextField
                  label="Cancelled By"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={cancelledBy}
                  onChange={(e) => setCancelledBy(e.target.value.trim())}
                  onKeyDown={handleKeyDown}
                  placeholder="Search by Cancelled By"
                  aria-label="Search by Cancelled By"
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Grid>
      )}

      {noResults && (
        <Box marginBottom={2} display="flex" justifyContent="center">
          <Typography variant="body2" color="error">
            No invoices found
          </Typography>
        </Box>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                Customer ID
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                Invoice ID
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                Invoice Date
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                Amount
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                Status
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.length > 0
              ? invoices.map((invoice) => (
                  <TableRow key={invoice.invoice_id}>
                    <TableCell>{invoice.customer_id}</TableCell>
                    <TableCell>{invoice.invoice_id}</TableCell>
                    <TableCell>{invoice.invoice_date}</TableCell>
                    <TableCell>{invoice.total_price}</TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "inline-block",
                          borderRadius: "4px",
                          padding: "4px 8px",
                          ...getStatusStyles(invoice.status),
                          textAlign: "center",
                        }}
                      >
                        {invoice.status}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Tooltip title="View Details" arrow>
                        <IconButton
                          component={Link}
                          to={`/invoice/${invoice.invoice_id}`}
                          state={{ invoiceData: invoice }}
                          style={{ color: "blue" }}
                          aria-label={`Go to details for Invoice ${invoice.invoice_id}`}
                        >
                          <ArrowForwardIosOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination Section */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: 3, mb: 3 }}
      >
        {/* Element aligned to flex-start */}
        <Box>
          <Typography variant="body3" color="textSecondary" fontWeight="bold">
            Total Records: {totalCount}
          </Typography>
        </Box>
        {/* Element aligned to flex-end */}
        <Box display="flex" alignItems="center">
          <Stack spacing={2}>
            <Pagination
              count={totalPages > 0 ? totalPages : 1}
              page={page}
              onChange={handlePageChange}
              variant="outlined"
              color="primary"
              boundaryCount={2}
              siblingCount={1}
            />
          </Stack>
        </Box>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default InvoicePage;
