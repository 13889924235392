// src/utils/auth.js
const baseUrl = "https://jf8k6mp2m8.eu-west-1.awsapprunner.com";

export const login = async (username, password) => {
  try {
    const formData = new URLSearchParams();
    formData.append("username", username);
    formData.append("password", password);

    const response = await fetch(`${baseUrl}/users/token`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: formData.toString(),
    });

    if (!response.ok) throw new Error("Failed to login");
    const responseData = await response.json();
    const token = responseData.access_token;

    // Store token and expiration if provided
    localStorage.setItem("token", token);
    if (responseData.expires_in) {
      const expirationTime = Date.now() + responseData.expires_in * 1000; // Convert seconds to milliseconds
      localStorage.setItem("tokenExpiration", expirationTime);
    }
    // console.log('Token stored:', token);
    // Log to confirm token is stored

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const apiFetch = async (endpoint, options = {}) => {
  const token = localStorage.getItem("token");
  // console.log('Using token for API:', token); // Log the token being used

  const defaultOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
    ...options,
  };

  try {
    const response = await fetch(`${baseUrl}${endpoint}`, defaultOptions);

    if (response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpiration"); // Clear expiration too
      window.location.href = "/";
      return;
    }

    if (!response.ok) throw new Error("Failed to fetch data");
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("tokenExpiration"); // Clear expiration too
};

export const isLoggedIn = () => {
  const token = localStorage.getItem("token");
  const tokenExpiration = localStorage.getItem("tokenExpiration");

  if (!token) return false;

  // Check if token has expired
  if (tokenExpiration && Date.now() >= tokenExpiration) {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpiration");
    return false;
  }

  return true; // Token exists and is valid
};
