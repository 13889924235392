import { useState, useEffect } from "react";

const BASE_URL = "https://jf8k6mp2m8.eu-west-1.awsapprunner.com";

export const useVehicleDetailsData = (vehicleID) => {
  const [data, setData] = useState(null); // For single object response
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1); // Default to 1 page
  const [totalCount, setTotalCount] = useState(0); // Default to 0 count

  useEffect(() => {
    const fetchData = async () => {
      if (!vehicleID) return;

      const token = localStorage.getItem("token");
      if (!token) {
        setError("No token found");
        return;
      }

      const endpoint = `${BASE_URL}/vehicles/${vehicleID}`;
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(endpoint, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          localStorage.removeItem("token");
          window.location.href = "/";
          return;
        }

        if (response.status === 403) {
          setError("You are not authorized to access this page");
          return;
        }

        if (!response.ok) {
          throw new Error(
            `Failed to fetch ${response.url}: ${response.statusText}`,
          );
        }

        const result = await response.json();

        // Store data, and fallback for totalPages and totalCount
        setData(result);
        setTotalPages(result.total_pages ?? 1); // Use total_pages if provided, else default to 1
        setTotalCount(result.total_count ?? 0); // Use total_count if provided, else default to 0
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setError(error.message || "An unknown error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [vehicleID]);

  return { data, loading, error, totalPages, totalCount };
};
