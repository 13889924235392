import { useState } from "react";

const BASE_URL = "https://jf8k6mp2m8.eu-west-1.awsapprunner.com";

export const useStateChange = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const StateChange = async (state, vehicleID) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    const token = localStorage.getItem("token");
    if (!token) {
      setLoading(false);
      setError("Authentication token not found. Please log in.");
      return;
    }

    try {
      const endpoint = `${BASE_URL}/states/change?state=${state}&vehicleId=${vehicleID}`;
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        setSuccess(true);
      } else {
        const errorData = await response.json();
        if (response.status === 400) {
          setError(errorData.message || "Bad request. Please check the input.");
        } else if (response.status === 403) {
          setError(
            errorData.message ||
              "You are not authorized to perform this action.",
          );
        } else {
          setError(errorData.message || "An error occurred.");
        }
      }
    } catch (err) {
      setError("A network error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return { StateChange, loading, error, success };
};
