import { useState, useEffect } from "react";

const BASE_URL = "https://jf8k6mp2m8.eu-west-1.awsapprunner.com";

export const useVehiclePoints = () => {
  const [rawData, setRawData] = useState(null);
  const [transformedData, setTransformedData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        setError("No authentication token found");
        setLoading(false);
        return;
      }

      const endpoint = `${BASE_URL}/home/vehicles`;

      try {
        const response = await fetch(endpoint, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (response.status === 401) {
          localStorage.removeItem("token");
          window.location.href = "/";
          return;
        }

        if (response.status === 403) {
          setError("You are not authorized to access this page");
          setLoading(false);
          return;
        }

        if (!response.ok) {
          throw new Error(`Error ${response.status}: ${response.statusText}`);
        }

        const result = await response.json();

        if (!result || typeof result !== "object") {
          throw new Error("Unexpected API response format");
        }

        // Store API data
        setRawData(result);
        setTransformedData(result);
      } catch (error) {
        setError(error.message || "An unknown error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { rawData, transformedData, loading, error };
};
