import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useReservationData } from "./data/reservationDetailsData";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  TextField,
  IconButton,
  Grid,
  Pagination,
  Stack,
  Button,
  MenuItem,
} from "@mui/material";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CloseIcon from "@mui/icons-material/Close";

const ReservationDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Extract page from URL query params or default to 1
  const searchParams = new URLSearchParams(location.search);
  const initialPage = parseInt(searchParams.get("page")) || 1;
  const [page, setPage] = useState(initialPage);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [noResults, setNoResults] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(false);

  const [customerId, setCustomerId] = useState("");
  const [reservationId, setReservationId] = useState("");
  const [locationId, setLocation] = useState("");
  const [reservationState, setReservationState] = useState("");
  const [isDev, setIsDev] = useState("");
  const [vehicleId, setVehicleId] = useState("");

  const [showSearchFields, setShowSearchFields] = useState(false);

  const {
    data: reservations,
    loading,
    error,
    totalPages,
    totalCount,
  } = useReservationData(page, debouncedSearchQuery);

  const handleClearSearch = () => {
    setCustomerId("");
    setReservationId("");
    setLocation("");
    setReservationState("");
    setIsDev("");
    setVehicleId("");
    setDebouncedSearchQuery("");
    setPage(1); // Reset to the first page
    setNoResults(false); // Reset no results flag
  };

  const getStatusStyles = (state) => {
    switch (state) {
      case "Reserved":
        return { backgroundColor: "#2980B9", color: "#FFFFFF" };
      case "Running":
        return { backgroundColor: "#27AE60", color: "#FFFFFF" };
      case "Cancelled":
        return { backgroundColor: "#E74C3C", color: "#FFFFFF" };
      case "Expired":
        return { backgroundColor: "#7F8C8D", color: "#FFFFFF" };
      case "Completed":
        return { backgroundColor: "#120A8F", color: "#ECF0F1" };
      default:
        return {};
    }
  };

  // Set noResults flag if no reservations found
  useEffect(() => {
    if (debouncedSearchQuery) {
      setNoResults(false);
    }
  }, [debouncedSearchQuery]);

  useEffect(() => {
    if (triggerSearch) {
      setTriggerSearch(false);
    }
  }, [triggerSearch]);

  useEffect(() => {
    if (reservations && reservations.length > 0) {
      setNoResults(false);
    } else {
      setNoResults(true);
    }
  }, [reservations, debouncedSearchQuery]);

  // Handle any errors during data fetching
  useEffect(() => {
    if (error) {
      console.error("Error fetching reservation data:", error);
      setNoResults(true);
    }
  }, [error]);

  // Sync page number from location (if user navigates from a specific reservation)
  useEffect(() => {
    if (location.pathname.includes("/reservation/")) {
      setPage(1); // Reset to page 1 when returning from a reservation detail
    }
  }, [location.pathname]);

  // Handle page changes from the pagination component
  const handlePageChange = (event, value) => {
    setPage(value);
    // Update the URL with the new page number
    navigate(`?page=${value}`);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const query = new URLSearchParams();
      if (customerId) query.append("customerId", customerId);
      if (reservationId) query.append("reservationId", reservationId);
      if (locationId) query.append("location", locationId);
      if (reservationState) query.append("reservationState", reservationState);
      if (isDev === "yes") {
        query.append("isDev", true);
      } else if (isDev === "no") {
        query.append("isDev", false);
      }
      if (vehicleId) query.append("vehicleId", vehicleId);

      setDebouncedSearchQuery(query.toString());
      setPage(1);
      setTriggerSearch(true);
    }
  };

  const handleSearchClick = () => {
    const query = new URLSearchParams();
    if (customerId) query.append("customerId", customerId);
    if (reservationId) query.append("reservationId", reservationId);
    if (locationId) query.append("location", locationId);
    if (reservationState) query.append("reservationState", reservationState);
    if (isDev === "yes") {
      query.append("isDev", true);
    } else if (isDev === "no") {
      query.append("isDev", false);
    }
    if (vehicleId) query.append("vehicleId", vehicleId);

    setDebouncedSearchQuery(query.toString());
    setPage(1);
    setTriggerSearch(true);
  };

  if (loading && !triggerSearch)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Box sx={{ p: 2 }}>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        gap={1}
        mb={2}
      >
        {/* Filter/Close Icon */}
        <IconButton
          onClick={() => setShowSearchFields((prev) => !prev)}
          sx={{
            color: "primary.main",
            transition: "transform 0.3s",
            "&:hover": {
              transform: "scale(1.1)", // Subtle scale effect on hover
            },
          }}
        >
          {showSearchFields ? <CloseIcon /> : <FilterAltOutlinedIcon />}
        </IconButton>

        {/* Clear Button */}
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClearSearch}
          sx={{
            padding: "6px 12px",
            fontWeight: "bold",
            textTransform: "none",
            transition: "background-color 0.3s, box-shadow 0.3s",
          }}
        >
          CLEAR
        </Button>

        {/* Search Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearchClick}
          sx={{
            padding: "6px 12px",
            fontWeight: "bold",
            textTransform: "none", // To make text more readable
            transition: "background-color 0.3s, box-shadow 0.3s",
            "&:hover": {
              backgroundColor: "#0069d9", // Slightly darker on hover
              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          SEARCH
        </Button>
      </Box>

      <Grid container spacing={1} marginBottom={2} alignItems="center">
        <Grid item xs={10} sm={4}>
          <TextField
            label="Vehicle ID"
            variant="outlined"
            size="small"
            value={vehicleId}
            onChange={(e) => setVehicleId(e.target.value.trim())}
            onKeyDown={handleKeyDown}
            placeholder="Search by Vehicle ID"
            fullWidth
          />
        </Grid>
        <Grid item xs={10} sm={4}>
          <TextField
            label="Reservation ID"
            variant="outlined"
            size="small"
            value={reservationId}
            onChange={(e) => setReservationId(e.target.value.trim())}
            onKeyDown={handleKeyDown}
            placeholder="Search by Reservation ID"
            fullWidth
          />
        </Grid>
        <Grid item xs={10} sm={4}>
          <TextField
            select
            label="Reservation State"
            variant="outlined"
            size="small"
            fullWidth
            value={reservationState} // Assuming `reservationState` is your state variable
            onChange={(e) => setReservationState(e.target.value)} // Update state on selection change
            onKeyDown={handleKeyDown} // If you need to handle any keydown events
            placeholder="Search by Reservation State" // Optional, but this can be omitted in dropdowns
          >
            <MenuItem value="">...</MenuItem>
            <MenuItem value="Reserved">Reserved</MenuItem>
            <MenuItem value="Running">Running</MenuItem>
            <MenuItem value="Cancelled">Cancelled</MenuItem>
            <MenuItem value="Expired">Expired</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
          </TextField>
        </Grid>
      </Grid>

      {/* Search Fields (conditionally rendered based on `showSearchFields` state) */}
      {showSearchFields && (
        <Grid
          container
          spacing={1}
          marginBottom={2}
          alignItems="center"
          style={{
            backgroundColor: "#f0f0f0",
            transition: "all 0.3s ease", // Smooth transition for hover effect
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Initial shadow for uplift effect
            borderRadius: "8px", // Optional: Rounded corners for a smoother look
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.2)")
          } // On hover: stronger shadow
          onMouseLeave={(e) =>
            (e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)")
          } // On hover out: return to initial shadow
        >
          <Grid item xs={10} sm={3} marginTop={1} marginBottom={2}>
            <TextField
              label="Customer ID"
              variant="outlined"
              size="small"
              value={customerId}
              onChange={(e) => setCustomerId(e.target.value.trim())}
              onKeyDown={handleKeyDown}
              placeholder="Search by Customer ID"
              fullWidth
            />
          </Grid>
          <Grid item xs={10} sm={3} marginTop={1} marginBottom={2}>
            <TextField
              select
              label="Location"
              variant="outlined"
              size="small"
              fullWidth
              value={locationId} // Assuming this state stores the selected location
              onChange={(e) => setLocation(e.target.value.trim())} // Handler to update the location state
              onKeyDown={handleKeyDown} // If you need to handle any keydown events
            >
              <MenuItem value="">...</MenuItem>
              <MenuItem value="Dubai">Dubai</MenuItem>
              <MenuItem value="Sharjah">Sharjah</MenuItem>
              <MenuItem value="Abu Dhabi">Abu Dhabi</MenuItem>
              <MenuItem value="Ajman">Ajman</MenuItem>
              <MenuItem value="Drivers">Drivers</MenuItem>
            </TextField>
          </Grid>
          {/* Second line starts here */}
          <Grid item xs={10} sm={3} marginTop={1} marginBottom={2}>
            <TextField
              select
              label="Is-Dev"
              variant="outlined"
              size="small"
              value={isDev}
              onChange={(e) => setIsDev(e.target.value)}
              onKeyDown={handleKeyDown}
              fullWidth
            >
              {/* Empty option to represent null */}
              <MenuItem value="">...</MenuItem>
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      )}

      {noResults && (
        <Box marginBottom={2} display="flex" justifyContent="center">
          <Typography variant="body2" color="error">
            {error
              ? "Error: Failed to fetch reservation data"
              : "No matching reservation found with this Customer ID"}
          </Typography>
        </Box>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {[
                "Reservation ID",
                "Plate",
                "Location",
                "Start-Time",
                "End-Time",
                "Distance",
                "Is-Dev",
                "State",
                "Action",
              ].map((header) => (
                <TableCell
                  key={header}
                  sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {reservations.length > 0
              ? reservations.map((res) => (
                  <TableRow key={res.reservationID}>
                    <TableCell>{res.reservationID}</TableCell>
                    <TableCell>{res.plate}</TableCell>
                    <TableCell>{res.location}</TableCell>
                    <TableCell>{res.starttime}</TableCell>
                    <TableCell>{res.endtime || "N/A"}</TableCell>
                    <TableCell>{res.distance || "N/A"}</TableCell>
                    <TableCell>{res.isdev ? "Yes" : "No"}</TableCell>
                    <TableCell
                      sx={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <Box
                        sx={{
                          display: "inline-block",
                          borderRadius: "4px",
                          padding: "4px 8px",
                          ...getStatusStyles(res.reservationState),
                          textAlign: "center",
                          width: "auto",
                        }}
                      >
                        {res.reservationState || "N/A"}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        component={Link}
                        to={`/reservation/${res.reservationID}`}
                        style={{ color: "blue" }}
                      >
                        <ArrowForwardIosOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              : !loading &&
                !noResults && (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <Typography variant="body2">Loading...</Typography>
                    </TableCell>
                  </TableRow>
                )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination Section */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: 3, mb: 3 }}
      >
        {/* Element aligned to flex-start */}
        <Box>
          <Typography variant="body3" color="textSecondary" fontWeight="bold">
            Total Records: {totalCount}
          </Typography>
        </Box>
        {/* Element aligned to flex-end */}
        <Box display="flex" alignItems="center">
          <Stack spacing={2}>
            <Pagination
              count={totalPages > 0 ? totalPages : 1}
              page={page}
              onChange={handlePageChange}
              variant="outlined"
              color="primary"
              boundaryCount={2}
              siblingCount={1}
            />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default ReservationDetails;
