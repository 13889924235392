import { useState, useEffect } from "react";

const BASE_URL = "https://jf8k6mp2m8.eu-west-1.awsapprunner.com";
const UPDATE_ENDPOINT = "/users/update";
const FETCH_ENDPOINT = "/users/me";

export const useProfileDataUpdate = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [userData, setUserData] = useState(null);

  const fetchUserData = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      setError("Unauthorized: No token found.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${BASE_URL}${FETCH_ENDPOINT}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        throw new Error("Unauthorized: Invalid token.");
      } else if (response.status === 403) {
        throw new Error(
          "Forbidden: You do not have permission to view this data.",
        );
      } else if (!response.ok) {
        throw new Error("An error occurred while fetching user data.");
      }

      const data = await response.json();
      setUserData(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const updateProfileData = async (profileData) => {
    const token = localStorage.getItem("token");

    if (!token) {
      setError("Unauthorized: No token found.");
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await fetch(`${BASE_URL}${UPDATE_ENDPOINT}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          email: profileData.email,
          full_name: profileData.full_name,
          password: profileData.password,
        }),
      });

      if (response.status === 401) {
        throw new Error("Unauthorized: Invalid token.");
      } else if (response.status === 403) {
        throw new Error(
          "Forbidden: You do not have permission to perform this action.",
        );
      } else if (!response.ok) {
        throw new Error("An error occurred while updating profile data.");
      }

      setSuccess(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return { loading, error, success, userData, updateProfileData };
};
