import { useState, useEffect, useCallback } from "react";

const BASE_URL = "https://jf8k6mp2m8.eu-west-1.awsapprunner.com";

const handleError = async (response) => {
  if (!response.ok) {
    const errorMessage = await response.text();
    switch (response.status) {
      case 401:
        return Promise.reject(
          new Error("Unauthorized access. Please log in to continue."),
        );
      case 403:
        return Promise.reject(
          new Error("You are not allowed to access this page."),
        );
      default:
        return Promise.reject(new Error(errorMessage || "An error occurred."));
    }
  }
  return response;
};

const getToken = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  return token;
};

export const useRoleData = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchAllUsers = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const token = getToken();
      const response = await fetch(`${BASE_URL}/users/all`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      await handleError(response);
      const result = await response.json();
      setUsers(result || []);
    } catch (error) {
      console.error("Error fetching users:", error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAllUsers();
  }, [fetchAllUsers]);

  const createUser = async (userData) => {
    setError(null);
    try {
      const token = getToken();
      const response = await fetch(`${BASE_URL}/users/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(userData),
      });
      await handleError(response);
      const newUser = await response.json();
      setUsers((prevUsers) => [...prevUsers, newUser]);
    } catch (error) {
      console.error("Error creating user:", error.message);
      setError(error.message);
    }
  };

  const updateUser = async (userId, userData) => {
    setError(null);
    try {
      const token = getToken();
      const response = await fetch(`${BASE_URL}/users/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ id: userId, ...userData }),
      });
      await handleError(response);
      const updatedUser = await response.json();
      setUsers((prevUsers) =>
        prevUsers.map((user) => (user.id === userId ? updatedUser : user)),
      );
    } catch (error) {
      console.error("Error updating user:", error.message);
      setError(error.message);
    }
  };

  const deleteUser = async (userId) => {
    setError(null);
    try {
      const token = getToken();
      const response = await fetch(`${BASE_URL}/users`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ username: userId }),
      });
      await handleError(response);
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
    } catch (error) {
      console.error("Error deleting user:", error.message);
      setError(error.message);
    }
    await fetchAllUsers();
  };

  return { users, loading, error, createUser, updateUser, deleteUser };
};
