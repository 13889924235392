import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  Grid,
  useTheme,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { login } from "../../../utils/auth"; // Adjust path as needed
import logo from "../../../assets/logo.png"; // Import the logo image

const Login = ({ setIsAuthenticated }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State for show/hide password
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false); // State for page load animation
  const navigate = useNavigate();
  const theme = useTheme(); // Access the theme

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const success = await login(username, password);

      if (success) {
        setIsAuthenticated(true);
        navigate("/home");
      } else {
        setError("Invalid username or password");
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    setLoaded(true); // Trigger the transition on load
  }, []);

  return (
    <Container
      maxWidth="md"
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: loaded ? 1 : 0,
        transform: loaded ? "translateY(0)" : "translateY(20px)",
        transition: "opacity 0.8s ease, transform 0.8s ease", // Smooth transition
      }}
    >
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        {/* Logo Section */}
        <Grid item xs={12} sm={6} md={5} display="flex" justifyContent="center">
          <img
            src={logo}
            alt="Logo"
            style={{
              width: "80%",
              maxWidth: 250,
              height: "auto",
            }}
          />
        </Grid>

        {/* Login Form Section */}
        <Grid item xs={12} sm={6} md={6}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            p={4}
            borderRadius={3}
            boxShadow={4}
            bgcolor={theme.palette.background.default}
          >
            {/* Login Form */}
            <form onSubmit={handleLogin} style={{ width: "100%" }}>
              <TextField
                label="Username"
                fullWidth
                margin="normal"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                variant="outlined"
                InputLabelProps={{
                  style: { color: theme.palette.text.secondary },
                }}
                InputProps={{
                  style: {
                    color: theme.palette.text.primary,
                    borderRadius: theme.shape.borderRadius,
                  },
                }}
                sx={{
                  marginBottom: theme.spacing(2),
                  "& .MuiOutlinedInput-root:hover": {
                    borderColor: theme.palette.primary.main,
                  },
                }}
              />
              <TextField
                label="Password"
                type={showPassword ? "text" : "password"} // Toggle password visibility
                fullWidth
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                variant="outlined"
                InputLabelProps={{
                  style: { color: theme.palette.text.secondary },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword((prev) => !prev)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    color: theme.palette.text.primary,
                    borderRadius: theme.shape.borderRadius,
                  },
                }}
                sx={{
                  marginBottom: theme.spacing(2),
                  "& .MuiOutlinedInput-root:hover": {
                    borderColor: theme.palette.primary.main,
                  },
                }}
              />
              {error && (
                <Typography
                  color="error"
                  align="center"
                  sx={{
                    marginBottom: theme.spacing(2),
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                  }}
                >
                  {error}
                </Typography>
              )}
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  marginTop: theme.spacing(1),
                  padding: theme.spacing(1.5),
                  borderRadius: theme.shape.borderRadius,
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  textTransform: "none",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                  },
                }}
              >
                Login
              </Button>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;
