import { useState, useEffect } from "react";

const BASE_URL = "https://jf8k6mp2m8.eu-west-1.awsapprunner.com";

export const useCustomerData = (page = 1, query) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0); // New state for total customer count

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("No token found");
        setLoading(false);
        return;
      }

      const endpoint = `${BASE_URL}/customers?page=${page}&${query}`;

      try {
        const response = await fetch(endpoint, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          localStorage.removeItem("token");
          window.location.href = "/";
          return;
        }

        if (!response.ok) {
          throw new Error(
            `Failed to fetch ${response.url}: ${response.statusText}`,
          );
        }

        const result = await response.json();

        const count = result.total_count || 0; // Adjust the key based on your API response
        setTotalCount(count);

        const dataArray = Array.isArray(result) ? result : [result];

        const transformedData = dataArray.map((item) => ({
          records: item.records || [],
          id: item.customer_id,
          firstname: item.first_name,
          lastname: item.last_name,
          place: item.title,
          state: item.verification_state,
          rejected: item.rejected_count,
          name: item.name,
          email: item.email,
          phone: item.mobile_phone,
          birthdate: item.birth_date,
          language: item.language,
          location: item.location,
          verificationState: item.verificationState,
          blocked: item.blocked,
          blockreason: item.block_reason,
          funnelpersonalinfo: item.funnel_personal_info,
          funnelpayment: item.funnel_payment,
          funnellicenseimage: item.funnel_license_image,
          emailverified: item.email_verified,
          phoneverified: item.phone_verified,
          registrationdate: item.registration_date,
          referralcode: item.referral_code,
          driverslicenseid: item.drivers_license_id,
          driverslicenseissuedate: item.drivers_license_issue_date,
          driverslicenseexpirationdate: item.drivers_license_expiration_date,
          personalid: item.personal_id,
          personalidissue: item.personal_id_issue,
          personalidexpiration: item.personal_id_expiration,
          nationality: item.nationality,
          customertype: item.customer_type,
          deleted: item.deleted,
          deletedat: item.deleted_at,
          isDev: item.is_dev,
          plan: item.plan,
          credits: item.credits,
          failedAmount: item.failed_amount,
          pendingInvoices: item.pending_invoices,
        }));

        setData(transformedData);
        setTotalPages(result.total_pages ?? 1);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setError(error.message || "An unknown error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [page, query]);

  return { data, loading, error, totalPages, totalCount }; // Return totalCount as part of the result
};
