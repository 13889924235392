const scopeoptions = [
  "viewCustomers",
  "updateCustomers",
  "viewReservations",
  "updateReservations",
  "viewInvoices",
  "triggerInvoice",
  "viewVehicles",
  "archiveCredits",
  "addCredits",
  "viewHistory",
  "user",
  "reservationEnd",
  "updatePricingModels",
  "updateHardware",
  "blockCustomers",
  "undeleteCustomers",
  "editFunnels",
  "cancelFee",
  "viewHotspots",
  "getHardwareHistory",
  "viewStateChangeHistory",
  "changeVehicleState",
  "viewParkingHistory",
  "changeInvoiceState",
  "viewPaymentMethods",
  "editCustomer_isDev",
  "editCustomer_changePlan",
  "editCustomer_changeLocation",
  "home",
  "activateCustomers",
  "viewDamageReports",
  "closeDamageReports",
];

export default scopeoptions;
