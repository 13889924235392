import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  IconButton,
  InputAdornment,
  Snackbar,
  Chip,
  Alert,
  SnackbarContent,
} from "@mui/material";
import {
  Visibility,
  VisibilityOff,
  Add,
  Remove,
  Close,
} from "@mui/icons-material";
import { useProfileDataUpdate } from "./data/profiledata";

const ProfilePage = () => {
  const { loading, error, success, userData, updateProfileData } =
    useProfileDataUpdate();
  const theme = useTheme();

  const [formData, setFormData] = useState({
    full_name: "",
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [showAllScopes, setShowAllScopes] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (userData) {
      setFormData({
        full_name: userData.full_name || "",
        password: "",
        confirmPassword: "",
      });
    }
  }, [userData]);

  useEffect(() => {
    if (success) {
      setSnackbarOpen(true);
    }
  }, [success]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setAlertMessage("Passwords do not match");
      return;
    }
    updateProfileData({
      email: userData.email,
      full_name: formData.full_name,
      password: formData.password,
    });
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        padding: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Typography
        variant="h4"
        fontWeight="bold"
        align="center"
        gutterBottom
        sx={{ marginBottom: theme.spacing(2) }}
      >
        Update Profile
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Full Name"
          fullWidth
          margin="normal"
          name="full_name"
          value={formData.full_name}
          onChange={handleChange}
          required
          variant="outlined"
          sx={{ marginBottom: theme.spacing(2) }}
        />
        <TextField
          label="Email"
          disabled
          fullWidth
          margin="normal"
          value={userData?.email || ""}
          variant="outlined"
          sx={{ marginBottom: theme.spacing(2) }}
        />
        <TextField
          label="Title"
          disabled
          fullWidth
          margin="normal"
          value={userData?.title || ""}
          variant="outlined"
          sx={{ marginBottom: theme.spacing(2) }}
        />
        <TextField
          label="Disabled"
          disabled
          fullWidth
          margin="normal"
          value={userData?.disabled ? "Yes" : "No"}
          variant="outlined"
          sx={{ marginBottom: theme.spacing(2) }}
        />
        <Box mb={2} mt={2}>
          <Typography variant="h6" gutterBottom>
            Scopes
          </Typography>
          <Box
            display="flex"
            flexWrap="wrap"
            gap={2}
            alignItems="center"
            sx={{
              maxHeight: showAllScopes ? "none" : theme.spacing(8),
              overflow: "hidden",
              position: "relative",
            }}
          >
            {(showAllScopes
              ? userData?.scopes
              : userData?.scopes?.slice(0, 6)
            )?.map((scope) => (
              <Chip key={scope} label={scope} variant="outlined" />
            ))}
            {userData?.scopes?.length > 6 && (
              <Chip
                onClick={() => setShowAllScopes((prev) => !prev)}
                label={showAllScopes ? "Show Less" : "Show More"}
                icon={showAllScopes ? <Remove /> : <Add />}
                variant="outlined"
                clickable
              />
            )}
          </Box>
        </Box>
        <TextField
          label="Password"
          type={showPassword ? "text" : "password"}
          fullWidth
          margin="normal"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword((prev) => !prev)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ marginBottom: theme.spacing(2) }}
        />
        <TextField
          label="Confirm Password"
          type={showPassword ? "text" : "password"}
          fullWidth
          margin="normal"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          required
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword((prev) => !prev)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ marginBottom: theme.spacing(2) }}
        />
        {alertMessage && (
          <Alert severity="error" sx={{ marginBottom: theme.spacing(2) }}>
            {alertMessage}
          </Alert>
        )}
        {error && (
          <Typography
            color="error"
            align="center"
            sx={{
              marginBottom: theme.spacing(2),
              fontSize: "0.9rem",
              fontWeight: "bold",
            }}
          >
            {error}
          </Typography>
        )}
        <Snackbar
          open={snackbarOpen}
          onClose={handleSnackbarClose}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <SnackbarContent
            message="Profile updated successfully!"
            sx={{
              backgroundColor: "#4caf50", // Green background color
              color: "white", // Text color
            }}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackbarClose}
              >
                <Close fontSize="small" />
              </IconButton>
            }
          />
        </Snackbar>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{
            marginTop: theme.spacing(2),
            padding: theme.spacing(1.5),
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            textTransform: "none",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
          disabled={loading}
        >
          {loading ? "Updating..." : "Update Profile"}
        </Button>
      </form>
    </Container>
  );
};

export default ProfilePage;
